import { clsNames, get, useUser } from 'unno-comutils'
import React, { useEffect, useState } from 'react'
import { Markdown } from './common'
import Dropdown from 'unno-comutils/Dropdown'
import { Icon, Wait } from 'unno-comutils/ui'

export const loadDocument = (name: any, user: any) => get('support/document/' + name, { lang: user.lang || 'th' }).then((d: any) => d.document || null)

export function DocumentWidget (props: { name: string, className?: string }) {
    const user = useUser()

    const [document, setDocument] = useState<any>(null)

    // ----- ACTION

    // ----- MEMO

    useEffect(() => {
        loadDocument(props.name, user).then((doc) => setDocument(doc))
    }, [props.name, user])

    if (!document) return <></>

    return <div className={clsNames('document-widget', props.className)}>
        <div className="document-widget-header">{document.title}</div>
        <div className="document-widget-body">
            <Markdown>{document.content}</Markdown>
        </div>
    </div>
}

export function DocumentIcon (props: { name: string, className?: string, right?: boolean }) {
    const user = useUser()

    const [document, setDocument] = useState<any>(null)

    function onToggle () {
        if (!document)
            loadDocument(props.name, user).then((doc) => setDocument(doc))
    }

    return <Dropdown className="document-hint" right={!!props.right} onToggle={onToggle} button={<Icon button name={'info-circle'} className="document-hint-icon"/>}>
        {document?.content ? <Markdown>{document.content}</Markdown> : <Wait/>}
    </Dropdown>
}
