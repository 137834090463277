export const resources = {
    "th": {
        "translation": {
            "TEST_TEXT": "ตัวอย่างคำสั่ง",
            "API_NAME_DEVICE_ALL": "ขอรายการรถทั้งหมดใน Account",
            "API_NAME_DEVICE_GET": "ขอสถานะรถปัจจุบันจาก ID",
            "API_NAME_DEVICE_LOG": "ดูประวัติการขับขี่",
            "API_NAME_POI_ALL": "ขอรายการ POI ทั้งหมดใน Account",
            "API_NAME_POI_GET": "ขอ POI จาก ID",
            "API_NAME_REPORT_LENGTH": "รายงานระยะทางการวิ่ง",
            "API_NAME_REPORT_RS232": "รายงานการรูดบัตร",
            "API_NAME_REPORT_WORKLOAD": "รายงานชั่วโมงทำงาน",
            "API_NAME_VERSION": "เลขเวอร์ชั่นของ API",
            "API_PARAM_ADDRESS": "แสดงที่อยู่ (ใส่ 1 เพื่อแสดงที่อยู่ , ประสิทธิภาพในการออกรายงานจะลดลง)",
            "API_PARAM_DEVICE": "หมายเลขอุปกรณ์",
            "API_PARAM_END": "ช่่วงเวลาสิ้นสุด",
            "API_PARAM_LIMIT": "จำนวนรายการที่ต้องการ",
            "API_PARAM_ORDER": "การเรียงลำดับ",
            "API_PARAM_START": "ช่วงเวลาเริ่มต้น",
            "API_RESP_DEVICES": "รายการรถ",
            "API_RESP_DEVICES_ID": "หมายเลข ID รถ",
            "API_RESP_OK": "สถานะการทำงานของ API",
            "API_RESP_VERSION": "เลขเวอร์ชั่นของ API",
            "APP_FINGERPRINT_SCAN": "สแกนลายนิ้วมือของคุณ เพื่อตรวจสอบสิทธิ์",
            "APP_PIN": "ป้อนรหัส PIN",
            "APP_PIN_CONFIRM": "ยืนยันรหัส PIN",
            "APP_PIN_ERROR": "รหัส Pin ไม่ถูกต้อง",
            "APP_PIN_ERROR_NOTMATCH": "รหัส PIN ไม่ตรงกัน",
            "APP_PIN_ERROR_NOTOLD": "รหัส PIN ใหม่ต้องไม่ซ้ำรหัส PIN เก่า",
            "APP_PIN_NEW": "ป้อนรหัส PIN ใหม่",
            "APP_PIN_OLD": "ป้อนรหัส PIN เก่า",
            "APP_SETTING_APIMOON": "เซิฟเวอร์สำรอง",
            "APP_SETTING_BETA": "ใช้โหมดเบต้า",
            "APP_SETTING_DARK": "โหมดกลางคืน",
            "APP_SETTING_FINGERPRINT": "สแกนลายนิ้วมือ",
            "APP_SETTING_PIN": "ตั้งค่ารหัส PIN",
            "APP_SETTING_PIN_CHANGE": "เปลี่ยนรหัส PIN",
            "APP_SETTING_PIN_DELETE": "ลบ PIN",
            "APP_SETTING_PIN_ON": "เปิด PIN LOCK หน้าจอ",
            "BUTTON_ADD": "เพิ่มใหม่",
            "BUTTON_GEN_AREA": "สร้างพื้นที่",
            "BUTTON_ADD_EMP_DAY": "เพิ่มวันลา",
            "BUTTON_CANCEL": "ยกเลิก",
            "BUTTON_CHECK": "ตรวจสอบข้อมูล",
            "BUTTON_CLEAR": "ล้างข้อมูล",
            "BUTTON_COMMAND_SEND": "ยืนยันการส่งคำสั่ง",
            "BUTTON_CONFIRM": "ยืนยัน",
            "BUTTON_CONFIRM_PRIVACY": "ยอมรับ",
            "BUTTON_DELETE": "ลบทิ้ง",
            "BUTTON_DOWNLOAD_CERT": "หนังสือรับรอง",
            "BUTTON_EDIT": "แก้ไข",
            "BUTTON_HIDE": "ปิดการมองเห็น",
            "BUTTON_LOGIN": "เข้าสู่ระบบ",
            "BUTTON_LOGOUT": "ออกจากระบบ",
            "BUTTON_MONEY_RECEIVE": "รับเงิน",
            "BUTTON_MONEY_UNRECEIVE": "ยังไม่รับเงิน",
            "BUTTON_NOTIFY": "แจ้งเตือน",
            "BUTTON_NOTIFY_SETTING": "ตั้งค่าการแจ้งเตือน",
            "BUTTON_OK": "ตกลง",
            "BUTTON_PORT": "นำเข้า/ส่งออก",
            "BUTTON_PRINT": "พิมพ์",
            "BUTTON_RESET": "รีเซ็ต",
            "BUTTON_RUN": "ดำเนินการ",
            "BUTTON_SAVE": "บันทึกข้อมูล",
            "BUTTON_SAVE_SORT": "บันทึกการจัดเรียง",
            "BUTTON_SEARCH": "ค้นหา",
            "BUTTON_SKIP": "ข้าม",
            "BUTTON_TEMPLATE_LOAD": "เรียกจากแม่แบบ",
            "BUTTON_TEMPLATE_SAVE": "บันทึกลงแม่แบบ",
            "BUTTON_UNHIDE": "เปิดการมองเห็น",
            "BUTTON_UPDATE_APP": "อัปเดต",
            "BUTTON_UPLOAD": "อัพโหลด",
            "CONFIRM_DELETE": "ยืนยันการลบข้อมูลนี้",
            "CONFIRM_DELETE_SELECT": "ยืนยันการลบข้อมูลที่เลือก",
            "CONFIRM_IMPORT": "ยืนยันการนำเข้าข้อมูล",
            "CONFIRM_LOGOUT": "Logout ?",
            "CONFIRM_MONEY_RECEIVE": "ยืนยันการรับเงิน !?",
            "CONFIRM_MONEY_UNRECEIVE": "ยกเลิกการรับเงิน !?",
            "CONFIRM_RESET": "ยืนยันการรีเช็ต !?",
            "DALERT_COMMAND_SEND_ERROR": "ไม่สามารถส่งข้อมูลได้ กรุณาลองใหม่",
            "DALERT_DELETE_FAIL": "ไม่สามารถลบข้อมูลได้",
            "DALERT_EDIT_NOTFOUND": "ไม่พบข้อมูลที่ต้องการแก้ไข",
            "DALERT_HISTORY_EMPTY": "ไม่พบประวัติย้อนหลัง :",
            "DALERT_HISTORY_NOMOVE": "รถไม่มีการเคลื่อนที่",
            "DALERT_MUST_DEVICE": "ต้องเลือกรถ",
            "DALERT_REPORT_EMPTY": "ไม่พบข้อมูลรายงาน",
            "DALERT_SAVE_FAIL": "ไม่สามารถบันทึกข้อมูลได้",
            "DALERT_USERNAME_DUP": "ชื่อผู้ใช้งานนี้มีในระบบแล้ว",
            "DATE_DAY_ALL": "ทุกวัน",
            "DATE_DAY_0": "อาทิตย์",
            "DATE_DAY_1": "จันทร์",
            "DATE_DAY_2": "อังคาร",
            "DATE_DAY_3": "พุธ",
            "DATE_DAY_4": "พฤหัสบดี",
            "DATE_DAY_5": "ศุกร์",
            "DATE_DAY_6": "เสาร์",
            "DATE_DAY_SHORT_0": "อา",
            "DATE_DAY_SHORT_1": "จ",
            "DATE_DAY_SHORT_2": "อ",
            "DATE_DAY_SHORT_3": "พ",
            "DATE_DAY_SHORT_4": "พฤ",
            "DATE_DAY_SHORT_5": "ศ",
            "DATE_DAY_SHORT_6": "ส",
            "DATE_MONTH_1": "มกราคม",
            "DATE_MONTH_2": "กุมภาพันธ์",
            "DATE_MONTH_3": "มีนาคม",
            "DATE_MONTH_4": "เมษายน",
            "DATE_MONTH_5": "พฤษภาคม",
            "DATE_MONTH_6": "มิถุนายน",
            "DATE_MONTH_7": "กรกฎาคม",
            "DATE_MONTH_8": "สิงหาคม",
            "DATE_MONTH_9": "กันยายน",
            "DATE_MONTH_10": "ตุลาคม",
            "DATE_MONTH_11": "พฤศจิกายน",
            "DATE_MONTH_12": "ธันวาคม",
            "DATE_MONTH_LAST": "เดือนที่แล้ว",
            "DATE_MONTH_SHORT_1": "ม.ค.",
            "DATE_MONTH_SHORT_2": "ก.พ.",
            "DATE_MONTH_SHORT_3": "มี.ค.",
            "DATE_MONTH_SHORT_4": "เม.ย.",
            "DATE_MONTH_SHORT_5": "พ.ค.",
            "DATE_MONTH_SHORT_6": "มิ.ย.",
            "DATE_MONTH_SHORT_7": "ก.ค.",
            "DATE_MONTH_SHORT_8": "ส.ค.",
            "DATE_MONTH_SHORT_9": "ก.ย.",
            "DATE_MONTH_SHORT_10": "ต.ค.",
            "DATE_MONTH_SHORT_11": "พ.ย.",
            "DATE_MONTH_SHORT_12": "ธ.ค.",
            "DATE_MONTH_THIS": "เดือนนี้",
            "DATE_S": "{{date,S}}",
            "DATE_ST": "{{date,St}}",
            "DATE_TODAY": "วันนี้",
            "DATE_TRACK": "{{date,d MMM HH:mm}}",
            "DATE_YESTERDAY": "เมื่อวาน",
            "DCONFIRM_CHANGE_LANGUAGE": "คุณต้องการเปลี่ยนภาษา",
            "DCONFIRM_LOGOUT": "คุณต้องการออกจากระบบ",
            "DCONFIRM_SEND_LOG": "ยืนยันการส่งข้อมูล",
            "TEXT_ALL_PLAY": "เล่นภาพกล้องทั้งหมด",
            "TEXT_ALL_CLOSE": "ปิดทั้งหมด",
            "TEXT_ALL_PAUSE": "หยุุดเล่นภาพทั้งหมด",
            "DEVICE_COMMAND": "คำสั่ง",
            "DEVICE_COMMAND_CRONJOB": "ตั้งเวลาทำงาน",
            "DEVICE_COMMAND_RESPOND": "ผลลัพท์",
            "DEVICE_COMMAND_RESPTIME": "เวลาการตอบรับ",
            "DEVICE_COMMAND_SEND": "ส่งคำสั่ง",
            "DEVICE_COMMAND_SENDING": "กำลังจะส่งคำสั่ง",
            "DEVICE_COMMAND_SENDTIME": "เวลาการส่ง",
            "DEVICE_DVR_CHANNEL": "กล้องตัวที่ {{channel}}",
            "DEVICE_MAXVOLUME": "ปริมาตรที่บรรทุกได้สูงสุด (ลบ.ม.)",
            "DEVICE_MAXWEIGHT": "น้ำหนักที่บรรทุกได้สูงสุด",
            "DEVICE_NOTIFY_AD": "เมื่อ {{ad}} {{to}} {{value}}",
            "DEVICE_NOTIFY_AREA_LOCATION": "เขตพื้นที่ที่ระบุ",
            "DEVICE_NOTIFY_DLT": "แจ้งเตือนกรมขนส่ง",
            "DEVICE_NOTIFY_NOCARD_DURATION": "ติดเครื่องไม่รูดบัตรนาน {{duration}} นาที",
            "DEVICE_NOTIFY_PARK_DURATION": "จอดติดเครื่องนาน {{duration}} นาที",
            "DEVICE_NOTIFY_POIALL": "POI ทั้งหมด",
            "DEVICE_NOTIFY_POI_IN": "เมื่อเข้า {{poi}}",
            "DEVICE_NOTIFY_POI_OUT": "เมื่อออกจาก {{poi}}",
            "DEVICE_NOTIFY_POISTOP_IN": "จอดใน POI เกิน {{duration}} นาที",
            "DEVICE_NOTIFY_RS232_NO": "ติดเครื่อง แต่ไม่รูดบัตร นานเกิน {{duration}} นาที",
            "DEVICE_NOTIFY_SPEEDLIMIT": "เมื่อความเร็วเกินกำหนด",
            "DEVICE_NOTIFY_STATE": "เมื่อ {{state}} เปลี่ยนเป็น {{to}}",
            "DEVICE_TEXT_FUELUSE": "อัตราการใช้น้ำมัน",
            "DEVICE_TEXT_FUELVOLUME": "ปริมาณถังน้ำมัน",
            "DEVICE_TEXT_MODEL": "รุ่นรถ",
            "DEVICE_TEXT_NOTIFY": "แจ้งเตือน",
            "DEVICE_TEXT_SPEEDLIMIT": "จำกัดความเร็ว",
            "DIALOG_ADD": "เพิ่มข้อมูล",
            "DIALOG_LANGUAGE": "เปลี่ยนภาษา",
            "EVENT_1": "Engine & Input 1 Active",
            "EVENT_10": "Engine & Input 2 Inactive",
            "EVENT_109": "Taxi Meter Event",
            "EVENT_11": "Engine & Input 3 Inactive",
            "EVENT_1101": "เข้า POI",
            "EVENT_1102": "ออกจาก POI",
            "EVENT_12": "Engine & Input 4 Inactive",
            "EVENT_1201": "จอดเกิน 10 นาที",
            "EVENT_1202": "ติดเครื่องแต่ไม่รูดบัตรเกิน 10 นาที",
            "EVENT_129": "เบรคกระทันหัน",
            "EVENT_13": "Engine & Input 5 Inactive",
            "EVENT_130": "ออกตัวกระชาก",
            "EVENT_133": "เครื่องพักทำงาน",
            "EVENT_134": "เครื่องกลับมาทำงาน",
            "EVENT_135": "ขับนาน",
            "EVENT_136": "Enough Rest after Fatigue Driving",
            "EVENT_138": "Speed Recovery",
            "EVENT_17": "แบตภายในเครื่องอ่อน",
            "EVENT_18": "แบตเตอรี่รถอ่อน",
            "EVENT_19": "ความเร็วเกิน",
            "EVENT_2": "Engine & Input 2 Active",
            "EVENT_20": "เข้าพื้นที่กำหนด",
            "EVENT_21": "ออกพื้นที่กำหนด",
            "EVENT_22": "ได้ไฟจากแบตเตอรี่รถ",
            "EVENT_23": "ไม่ได้ไฟจากแบตเตอรี่รถ",
            "EVENT_24": "ไม่มีสัญญาณGPS",
            "EVENT_25": "ได้รับสัญญาณGPS",
            "EVENT_26": "เข้าโหมดประหยัดพลังงาน",
            "EVENT_27": "ออกโหมดประหยัดพลังงาน",
            "EVENT_28": "สายGPSถูกตัด",
            "EVENT_29": "เครื่องreboot",
            "EVENT_3": "Engine & Input 3 Active",
            "EVENT_30": "กระแทก",
            "EVENT_31": "Heartbeat Report",
            "EVENT_32": "เปลี่ยนทิศการขับ",
            "EVENT_33": "รายงานตามระยะทาง",
            "EVENT_34": "Current Location Report",
            "EVENT_35": "รายงานตามระยะเวลา",
            "EVENT_36": "แจ้งเตือนเลื่อนรถ",
            "EVENT_37": "RFID",
            "EVENT_39": "รูป",
            "EVENT_4": "Engine & Input 4 Active",
            "EVENT_40": "ปุ่มปิดเครื่อง",
            "EVENT_41": "หยุดวิ่ง",
            "EVENT_42": "เริ่มวิ่ง",
            "EVENT_44": "กวนสัญญาณ GPS",
            "EVENT_5": "Engine & Input 5 Active",
            "EVENT_50": "อุณหภูมิสูง",
            "EVENT_51": "อุณหภูมิต่ำ",
            "EVENT_52": "น้ำมันเต็ม",
            "EVENT_53": "น้ำมันต่ำ",
            "EVENT_54": "ขโมยน้ำมัน",
            "EVENT_56": "Armed (T36x)",
            "EVENT_57": "Disarmed (T36x)",
            "EVENT_58": "Vehicle Theft (T36x)",
            "EVENT_63": "No GSM Jamming",
            "EVENT_65": "Press Input 1 (SOS) to Call",
            "EVENT_66": "Press input 2 to Call",
            "EVENT_67": "Press input 3 to Call",
            "EVENT_68": "Press input 4 to Call",
            "EVENT_69": "Press input 5 to Call",
            "EVENT_70": "Reject Incoming Call",
            "EVENT_71": "Report Location by Call",
            "EVENT_72": "Auto Answer Incoming Call",
            "EVENT_73": "Listen-in (voice monitoring)",
            "EVENT_78": "กระแทก",
            "EVENT_82": "เติมน้ำมัน",
            "EVENT_83": "Ult-Sensor Drop",
            "EVENT_86": "รูดบัตรออก",
            "EVENT_9": "Engine & Input 1 Inactive",
            "EVENT_90": "เลี้ยวซ้ายกระทันหัน",
            "EVENT_90_B": "หักเลี้ยวกระทันหัน",
            "EVENT_91": "เลี้ยวขวากระทันหัน",
            "EVENT_HIK_643": "รายงานข้อมูล GPS",
            "EVENT_HIK_644": "เตือนมีสิ่งกีดขวางอยู่หน้ารถ",
            "EVENT_HIK_645": "เตือนขับจี้คันข้างหน้า",
            "EVENT_HIK_646": "เตือนขับเบี่ยงออกนอกเลน",
            "EVENT_HIK_647": "เตือนคนเดินตัดหน้า",
            "EVENT_HIK_648": "เตือนขับเกินความเร็ว",
            "EVENT_HIK_649": "เตือนจุดบอดกระจกข้าง",
            "EVENT_HIK_650": "สูบบุหรี่",
            "EVENT_HIK_651": "คุยโทรศัพท์",
            "EVENT_HIK_652": "ขับขี่ขณะเมื่อยล้า",
            "EVENT_HIK_653": "ไม่มองทางข้างหน้า",
            "EVENT_HIK_654": "ไม่โค้งงอ",
            "EVENT_HIK_655": "บังกล้องวีดีโอ",
            "EVENT_HIK_656": "กดปุ่มฉุกเฉิน",
            "EVENT_HIK_657": "หักเลี้ยวกระทันหัน",
            "EVENT_HIK_658": "เบรคกระทันหัน",
            "EVENT_HIK_659": "เบรคกระทันหัน",
            "EVENT_HIK_660": "รถคว่ำ",
            "EVENT_HIK_661": "เกินความเร็ว",
            "EVENT_HIK_662": "เกิดการชน",
            "EVENT_HIK_663": "เข้าพื้นที่กำหนด",
            "EVENT_HIK_664": "รถไถล",
            "EVENT_HIK_665": "ออกพื้นที่กำหนด",
            "FAB_BOUND": "ขอบเขตทั้งหมด",
            "FAB_GO_LOCATION": "ตำแหน่งปัจจุบัน",
            "FAB_MAP_SAT": "แผนที่ดาวเทียม",
            "FAB_MAP_TRAFFIC": "การจราจร",
            "FAB_POI_SHOW": "แสดง POI",
            "FAB_REFRESH": "รีเฟรซ",
            "FORM_CHECK_ALL": "เลือกทั้งหมด",
            "FORM_CHECK_NONE": "ไม่เลือก",
            "FORM_SELECT": "-- เลือก --",
            "FORM_SELECT_ALL": "-- ทั้งหมด --",
            "FORM_SELECT_NONE": "-- ไม่มี --",
            "GROUPBY_DAY": "ตามวัน",
            "GROUPBY_MONTH": "ตามเดือน",
            "GROUPBY_WEEK": "ตามสัปดาห์",
            "INFO_CLOSEAPP": "กด Back อีกครั้งเพื่อออกจากโปรแกรม",
            "INFO_COMMAND_RESPONDED": "เซิร์ฟเวอร์ตอบกลับแล้ว",
            "INFO_COMMAND_RESPOND_TEXT": "สถานะการตอบกลับ",
            "INFO_COMMAND_SENDED": "ส่งข้อมูลแล้ว",
            "INFO_COMMAND_SENDING": "กำลังส่งข้อมูล",
            "INFO_COMMAND_STATUS": "สถานะการส่ง",
            "INFO_COMMAND_WAIT": "กำลังตอบกลับ...",
            "INFO_DATE_REQ": "กรุณาเลือกวันที่ก่อน",
            "INFO_DROPED": "ลบข้อมูลเรียบร้อยแล้ว",
            "INFO_DROP_FAIL": "ไม่สามารถลบข้อมูลได้",
            "INFO_DROP_NOTFOUND": "ไม่พบข้อมูลที่ต้องการลบ",
            "INFO_DVR_STATUS_FAIL": "กล้องออฟไลน์อยู่",
            "INFO_EXPIRE_DETAIL": "รถทะเบียนตามรายการนี้ ใกล้ครบ หรือครบกำหนดชำระเงินแล้ว กรุณาติดต่อทางเราเพื่อทำการชำระเงิน หากเลยกำหนด จะมีผลต่อความต่อเนื่องในการดูระบบติดตาม และขออภัยหากคุณชำระแล้วค่ะ",
            "INFO_EXPIRE_NOTIFY": "แจ้งเตือนเครื่องครบกำหนด",
            "INFO_LOGOUT": "ออกจากระบบแล้ว",
            "INFO_MONEY_JOB": "รายได้จากงาน",
            "INFO_MONEY_RECEIVE": "รับเงินแล้ว",
            "INFO_MONEY_UNRECEIVE": "ยกเลิกการรับเงินแล้ว",
            "INFO_NOTFOUND": "ไม่พบข้อมูลที่เลือก",
            "INFO_NOTIFY_DUPLICATE": "มีแจ้งเตือนนี้แล้ว",
            "INFO_REPORT_WAIT": "กรุณารอ กำลังสร้างรายงาน",
            "INFO_SAVED": "บันทึกข้อมูลแล้ว",
            "INFO_SAVE_DATA_INCOMPLETE": "กรุณากรอกข้อมูลให้ครบถ้วน",
            "INFO_SAVE_FAIL": "ไม่สามารถบันทึกข้อมูลได้",
            "INFO_SAVE_NOTFOUND": "ไม่พบข้อมูลที่ต้องการบันทึก",
            "INPUT_POI_NAME": "ตำแหน่ง poi ของคุณ",
            "LABEL_PIN_CREATE": "ตั้งรหัส PIN Application",
            "LOGIN_SUCCESS": "Login Success",
            "LOGIN_TITLE": "เข้าระบบ",
            "LOGIN_VALIDATE_FAIL": "ข้อมูลการเข้าระบบไม่ถูกต้อง",
            "MA_MENU_HISTORY": "ประวัติ",
            "MA_MENU_LIST": "รายการแจ้งเตือน",
            "MA_MENU_SERVICE": "บันทึกการเข้าศูนย์บริการ",
            "MA_MENU_SUMMARY": "สรุปซ่อมบำรุง",
            "MA_MENU_TEMPLATE": "แม่แบบ",
            "MA_MODE_DURATION": "ระยะเวลาที่ใช้",
            "MA_MODE_LENGTH": "ระยะทางการวิ่ง",
            "MA_MODE_WORKTIME": "ชั่วโมงการทำงาน",
            "MA_NOTIFY": "ถึงกำหนดซ่อมบำรุง {{name}} ของรถ {{device}}",
            "MA_TEXT_CURRENT": "ค่าปัจจุบัน",
            "MA_TEXT_CURRENT_UNIT": "ค่าปัจจุบัน {{value}} {{unit}}",
            "MA_TEXT_DATE_IN": "วันที่ส่งรถเข้า",
            "MA_TEXT_DATE_OUT": "วันที่รับรถกลับ",
            "MA_TEXT_GARAGE": "ศูนย์บริการ/อู่",
            "MA_TEXT_INTERVAL": "แจ้งทุกๆ",
            "MA_TEXT_INTERVAL_UNIT": "แจ้งทุกๆ {{value}} {{unit}}",
            "MA_TEXT_LIST": "รายการซ่อม",
            "MA_TEXT_MODE": "วัดตาม",
            "MA_TEXT_REMAIN": "อีก {{value}} {{unit}}",
            "MENU_CONTROLROOM": "Control Room",
            "MENU_DEVICE": "รายการรถ",
            "MENU_DISTANCE": "วัดระยะทาง",
            "MENU_HELP": "ช่วยเหลือ",
            "MENU_HISTORY": "ดูย้อนหลัง",
            "MENU_LOGOUT": "ออกจากระบบ",
            "MENU_MA": "ซ่อมบำรุง",
            "MENU_MAP": "แผนที่",
            "MENU_MOBILE": "Mobile",
            "MENU_MOBILE_DVR": "กล้อง DVR",
            "MENU_MOBILE_INFO": "ข้อมูลรถ",
            "MENU_MOBILE_MAP": "แผนที่",
            "MENU_MOBILE_NOTIFY": "แจ้งเตือน",
            "MENU_MODULE": "ระบบใช้งาน",
            "MENU_MODULE_CHECKPOINT": "Checkpoint",
            "MENU_MODULE_MA": "ระบบซ่อมบำรุง",
            "MENU_MODULE_REVENUE": "รายได้จากการขนส่ง",
            "MENU_MODULE_TMS": "จัดการขนส่ง",
            "MENU_MODULE_TMSROUTE": "TMS Route",
            "MENU_POI": "จุดปักหมุด (POI)",
            "MENU_PRIVACY": "นโยบายความเป็นส่วนตัว",
            "MENU_REPORT": "รายงาน",
            "MENU_RESELLER": "ตัวแทนจำหน่าย",
            "MENU_SETTING": "ตั้งค่า",
            "MENU_TOOL": "เครื่องมือ",
            "MENU_TOOL_POI": "วัดระยะทาง POI",
            "MENU_TRACKING": "เฝ้าสังเกต",
            "MENU_TRACKING_TAXI": "เฝ้า Taxi",
            "NOTE_FUEL_FULL": "รายการเติมน้ำมันจะนำไปออกรายงานได้ถูกต้อง ในกรณีที่การเติมน้ำมันทุกครั้งต้องเติมเต็มถัง และรู้เวลาที่เติมเท่านั้น",
            "NOTE_FUEL_VOLUME": "รายการน้ำมันจะนำไปออกรายงานได้ถูกต้อง ในกรณีที่ถังน้ำมันมีขนาดสี่เหลี่ยมเท่านั้น",
            "NOTE_FUEL_VOLUME_REPORT": "กรณีเติมน้ำมันทุกครั้งต้องดับเครื่องยนต์",
            "NOTE_PORTCLEAR": "ข้อมูลจะหายทั้งหมด กรุณาทำการสำรองข้อมูลก่อนกด",
            "NOTIFY_AD": "{{device}} สถานะ {{ad}} {{to}} {{value}} ({{current}})",
            "NOTIFY_AREA_IN": "{{device}} เข้าสู่ {{area}} {{address}}",
            "NOTIFY_AREA_OUT": "{{device}} ออกจาก {{area}} ({{duration}}) {{address}}",
            "NOTIFY_DLT_NOCARD": "{{device}} พนักงานขับรถไม่ได้รูดบัตรหลังสตาร์ทเครื่อง เกิน 10 นาที",
            "NOTIFY_DLT_OVERTIME": "{{device}} ขับรถนานเกิน 4 ชั่วโมง",
            "NOTIFY_DLT_STOP": "รถทะเบียน {{device}} หยุดการส่งข้อมูลไปยังกรมการขนส่งทางบกเกิน 7 วัน กรุณาตรวจสอบอุปกรณ์จีพีเอสให้ทำงานปกติ ก่อนนำรถไปใช้งาน หรือ ติดต่อ 02-111-7999 เพื่อขอรับการช่วยเหลือ",
            "NOTIFY_DRIVER_CARD": "{{device}} ติดเครื่องแต่ไม่รูดบัตร นานเกิน {{minute}} นาที",
            "NOTIFY_EVENT": "{{device}} {{event}} , {{poi}} {{address}}",
            "NOTIFY_LABEL_EMAIL": "แจ้งเตือนทาง Email",
            "NOTIFY_LABEL_LINE": "แจ้งเตือนทาง Line",
            "NOTIFY_LABEL_PUSH": "แจ้งเตือนทาง App",
            "NOTIFY_LABEL_SMS": "แจ้งเตือนทาง SMS",
            "NOTIFY_PARK_LIMIT": "{{device}} จอดติดเครื่อง นานเกิน {{minute}} นาที , {{poi}} {{address}}",
            "NOTIFY_POI_IN": "{{device}} เข้าสู่ {{poi}} {{address}}",
            "NOTIFY_POI_OUT": "{{device}} ออกจาก {{poi}} {{address}}",
            "NOTIFY_POISTOP": "{{device}} จอดนอก POI เกิน {{time}} นาที {{address}}",
            "NOTIFY_SETTING": "Notification Setting",
            "NOTIFY_SPEED_LIMIT": "{{device}} ขับรถเร็วเกินกำหนด ({{speed}} > {{speed_limit}}) , {{poi}} {{address}}",
            "NOTIFY_STATE": "{{device}} สถานะ {{state}} เป็น {{to}} , {{poi}} {{address}}",
            "NOTIFICATION_ENGINE_OFF": "ดับเครื่อง",
            "NOTIFICATION_ENGINE_PARK": "จอดติดเครื่อง",
            "NOTIFICATION_ENGINE_RUN": "วิ่ง",
            "OPTION_APPROVE": "อนุมัติ",
            "OPTION_DECREASE": "ลดลง",
            "OPTION_DENY": "ไม่อนุมัติ",
            "OPTION_ENABLE_CLUSTER": "แสดง Cluster marker",
            "OPTION_FILTER": "กรองตาม",
            "OPTION_GT": "มากกว่า",
            "OPTION_IN": "เข้า",
            "OPTION_INOUT": "เข้าและออก",
            "OPTION_LT": "น้อยกว่า",
            "OPTION_MAN": "ชาย",
            "OPTION_OPSEVENT": "สถานะตรงข้าม",
            "OPTION_OUT": "ออก",
            "OPTION_OVER_HOUR": "นานเกิน {{hour}} ชั่วโมง",
            "OPTION_OVER_MINUTE": "นานเกิน {{minute}} นาที",
            "OPTION_SHOW_CHECK": "แสดงตัวเลือก",
            "OPTION_SHOW_DEVICE": "แสดงจำนวนรถ",
            "OPTION_SHOW_LABEL": "แสดงป้ายชื่อ",
            "OPTION_SHOW_ROWNUM": "แสดงเลขลำดับ",
            "OPTION_SHOW_SECOND": "แสดงวินาที",
            "OPTION_SHOW_STATUS": "แสดงสถานะ",
            "OPTION_TOTAL": "ทั้งหมด",
            "OPTION_WAIT": "รอ",
            "OPTION_WOMAN": "หญิง",
            "OPTION_NARROW": "แคบ",
            "OPTION_NORMAL": "ปกติ",
            "OPTION_WIDE": "กว้าง",
            "REPORT": "รายงาน",
            "REPORT_ATTIME": "ข้อมูลรายงาน ณ เวลา {{time}}",
            "REPORT_DISPLAY_PLACE": "แสดง Place",
            "REPORT_DISPLAY_TABLE": "แสดงตารางข้อมูล",
            "REPORT_DRIVER_SCORE_DAY": "เป็นคะแนนต่อวัน",
            "REPORT_DRIVER_SCORE_SETTING": "กำหนดคะแนน",
            "REPORT_DUMP_OFF": "ดัมพ์ไม่ทำงาน",
            "REPORT_DUMP_ON": "ดัมพ์ทำงาน",
            "REPORT_FILE_AREA": "เข้า-ออก พื้นที่",
            "REPORT_FILE_AREA_LENGTH": "ระยะ เข้า-ออก พื้นที่",
            "REPORT_FILE_CHART_CYCLE": "กราฟรอบการหมุน",
            "REPORT_FILE_CHART_FUELUSE": "กราฟน้ำมัน",
            "REPORT_FILE_CHART_GASUSE": "กราฟก๊าซ",
            "REPORT_FILE_CHART_POWER": "กราฟแรงดันไฟฟ้า",
            "REPORT_FILE_CHART_SPEED": "กราฟความเร็ว",
            "REPORT_FILE_CHART_STATUS": "สถานะการใช้งาน",
            "REPORT_FILE_CHART_TEMP": "กราฟอุณหภูมิ",
            "REPORT_FILE_DRIVER_SCORE": "คะแนนผู้ขับขี่",
            "REPORT_FILE_DVR": "กล้อง DVR",
            "REPORT_FILE_DVR_EVENT": "เหตุการณ์กล้อง ADAS DBA",
            "REPORT_FILE_DVR_STATUS": "สถานะกล้อง DVR",
            "REPORT_FILE_EVENT": "การแจ้งเตือนเหตุการณ์",
            "REPORT_FILE_FUEL": "เทียบการใช้น้ำมันกับระยะทาง",
            "REPORT_FILE_FUELAVG": "เฉลี่ยการใช้น้ำมันกับระยะทาง",
            "REPORT_FILE_GROUP": "สรุปการใช้งานตามกลุ่ม",
            "REPORT_FILE_LENGTH": "ระยะทางการวิ่ง",
            "REPORT_FILE_LENGTH_SUMMARY": "สรุประยะการใช้งาน",
            "REPORT_FILE_MA": "ประวัติการซ่อมบำรุง",
            "REPORT_FILE_MA_SERVICE": "ประวัติการเข้าศูนย์ซ่อมบำรุง",
            "REPORT_FILE_PARKING": "การจอดรถ",
            "REPORT_FILE_PARKING_FREQ": "การจอดรถซ้ำที่จอด",
            "REPORT_FILE_PHOTO": "รูปภาพจากตัวอุปกรณ์",
            "REPORT_FILE_POI": "เข้า-ออก POI",
            "REPORT_FILE_POIOUT": "ออกจาก POI",
            "REPORT_FILE_REVENUE": "รายได้สุทธิจากการขนส่ง",
            "REPORT_FILE_ROUTE": "เส้นทางการวิ่ง",
            "REPORT_FILE_RS232": "การรูดบัตร",
            "REPORT_FILE_SPEED_GROUP": "ช่วงความถี่ความเร็ว",
            "REPORT_FILE_STATUS": "สถานะการใช้งาน",
            "REPORT_FILE_SUMMARY": "สรุปการใช้งาน",
            "REPORT_FILE_SUMMARY_STATUS": "สรุปสถานะการใช้งาน",
            "REPORT_FILE_WORKLOAD": "ชั่วโมงทำงาน",
            "REPORT_FILE_WORKLOAD_MONTH": "ชั่วโมงทำงานรายเดือน",
            "REPORT_FILE_DLT_LOGSUM": "พิกัดผิดปกติของกรมขนส่ง",
            "REPORT_GROUP_CHART": "กราฟ",
            "REPORT_GROUP_DATA": "รายงาน",
            "REPORT_GROUP_PHOTO": "รูปภาพ",
            "REPORT_OPT_CHART": "กราฟสรุป",
            "REPORT_OPT_CHART_CHK": "แสดงกราฟสรุป",
            "REPORT_OPT_COUNTFREQ": "ครั้งที่ซ้ำ",
            "REPORT_OPT_DISPLAY": "แสดงผล",
            "REPORT_OPT_DLT4H": "เกินกว่า 4 ชั่วโมง",
            "REPORT_OPT_DUMP": "ตรวจสอบการทำงานของดัมพ์",
            "REPORT_OPT_FUEL": "อัตราการสิ้นเปลืองน้ำมันจาก sensor วัดระดับน้ำมัน",
            "REPORT_OPT_FUELDETECT": "ค่าน้ำมันเคลื่อนไหวผิดปกติ",
            "REPORT_OPT_LOCATION": "เขตพื้นที่ที่ระบุ",
            "REPORT_OPT_MONTH": "สรุปเดือน",
            "REPORT_OPT_NOCARD": "ไม่ได้รูดบัตร",
            "REPORT_OPT_OPSDELAY": "หน่วงเวลาความเร็วเกิน {{value}} นาที",
            "REPORT_OPT_OPSDELAY_NONE": "ไม่หน่วงเวลาความเร็วเกิน",
            "REPORT_OPT_OVERSPEED": "เฉพาะความเร็วเกิน",
            "REPORT_OPT_PARKTIME": "เวลาจอด",
            "REPORT_OPT_STATUS": "สถานะรถ",
            "REPORT_OPT_TMS_STATE": "สถานะงาน",
            "REPORT_OPT_USESPEED": "ระบุความเร็วเกินกำหนด",
            "REPORT_SUMMARY_COUNT": "ครั้งในการส่งข้อมูล",
            "REPORT_SUMMARY_DURATION": "รวมระยะเวลาที่ใช้",
            "REPORT_SUMMARY_LENGTH": "รวมระยะทาง",
            "REPORT_SUMMARY_MAXSPEED": "ความเร็วสูงสุด",
            "REPORT_SUMMARY_MAXTIME": "ระยะเวลาที่นานที่สุด",
            "REPORT_SUMMARY_NOGPS": "ไม่มีสัญญาณ GPS",
            "REPORT_SUMMARY_PARK": "จอดขณะติดเครื่องยนต์",
            "REPORT_SUMMARY_PARK_MAXTIME": "ระยะเวลาจอดนานที่สุด",
            "REPORT_SUMMARY_ROUTE": "การเดินทาง",
            "REPORT_SUMMARY_SPEEDLIMIT": "ความเร็วเกินกำหนด",
            "REPORT_SUMMARY_STOP": "จอดขณะดับเครื่องยนต์",
            "REPORT_TABLE_FUELAVG_ENGINE": "ชั่วโมงทำงาน (ลิตร/ชม.)",
            "REPORT_TABLE_FUELAVG_KM": "ระยะทาง (กม./ลิตร)",
            "REPORT_TITLE_DATERANGE": "ช่วงวันที่ {{date}}",
            "REPORT_TITLE_DEVICE": "ทะเบียน {{device}}",
            "REPORT_TITLE_DEVICE_ALL": "ทะเบียนทั้งหมด",
            "REPORT_TITLE_DEVICE_GROUP": "กลุ่ม {{group}}",
            "REPORT_VIEW_DEVICE": "แสดงคอลัมน์เครื่อง",
            "REPORT_VIEW_HEADER": "แสดงส่วนหัว",
            "REPORT_VIEW_TOTAL": "แสดงผลรวม",
            "STATE_ABSENCE": "ลา",
            "STATE_BAD": "แย่",
            "STATE_DONE": "ดำเนินการแล้ว",
            "STATE_GOOD": "ดี",
            "STATE_MODERATE": "ปานกลาง",
            "STATE_PENDING": "รอดำเนินการ",
            "STATE_STRIKE": "หยุุด",
            "STATE_WAIT": "รอ",
            "STATE_WORK": "งาน",
            "TEXT_ACCESSTIME": "ใช้งานล่าสุด",
            "TEXT_ADD_POINT": "เพิ่มจุดหมาย",
            "TEXT_ADDRESS": "ที่อยู่",
            "TEXT_ALEART_MONEY_INPUT": "กรุณาระบุยอดเงินที่จะรับ",
            "TEXT_ALERT_SELECT_DEVICE": "กรุณาเลือกรถ",
            "TEXT_ALERT_SELECT_DRIVER": "กรุณาคนขับรถ",
            "TEXT_ALERT_SELECT_NOTIFY": "กรุณาเลือกแจ้งเตือน",
            "TEXT_ALERT_TIME_ERROR": "เวลาไม่ถูกต้อง",
            "TEXT_AMOUNT": "จำนวน",
            "TEXT_AMOUNT_LITER": "จำนวนลิตร",
            "TEXT_APPLY": "นำไปใช้",
            "TEXT_AREA": "พื้นที่",
            "TEXT_ATTACHMENT": "ไฟล์แนบ",
            "TEXT_ATTACHMENT_IMAGE": "ไฟล์รูปภาพ",
            "TEXT_AVG": "ค่าเฉลี่ย",
            "TEXT_BIRTHDAY": "วันเกิด",
            "TEXT_CLEAR": "ล้างข้อมูล",
            "TEXT_CLEAR_OLD": "ลบข้อมูลเดิมก่อน",
            "TEXT_CLEAR_SIGNATURE": "ลบลายเซ็น",
            "TEXT_COMPANY": "บริษัท",
            "TEXT_COPY": "คัดลอก",
            "TEXT_COPY_FROM": "คัดลอกจาก",
            "TEXT_COPY_TO": "คัดลอกไป",
            "TEXT_COST": "ค่าใช้จ่าย",
            "TEXT_COUNT_DUPLICATE": "ซ้ำ {{count}} รายการ",
            "TEXT_COUNT_FOUND": "พบแล้ว {{count}} รายการ",
            "TEXT_COUNT_NOIMPORT": "นำเข้าไม่ได้ {{count}} รายการ",
            "TEXT_COUNT_SELECT": "เลือกแล้ว {{count}} รายการ",
            "TEXT_COUNT_TOTAL": "ทั้งหมด {{count}} รายการ",
            "TEXT_CREATETIME": "สร้างเมื่อ",
            "TEXT_CREDIT": "Credit",
            "TEXT_CUSTOMER": "ลูกค้า",
            "TEXT_DATA": "ข้อมูล",
            "TEXT_DATE": "วันที่",
            "TEXT_DATE_END": "ถึงวันที่",
            "TEXT_DATE_FROM": "ระหว่างวันที่",
            "TEXT_DATE_IN": "วันที่เข้า",
            "TEXT_DATE_INSTALL": "วันที่ติดตั้ง",
            "TEXT_DATE_OUT": "วันที่ออก",
            "TEXT_DATERANGE": "ช่วงวันที่",
            "TEXT_DATE_START": "วันที่เริ่ม",
            "TEXT_DATE_TO": "ถึง",
            "TEXT_DEFAULT": "ค่าปริยาย",
            "TEXT_DELAY": "หน่วงเวลา",
            "TEXT_DESC_DISTANCE": "เรียงจากไกลก่อน",
            "TEXT_DETAIL": "รายละเอียด",
            "TEXT_DEVICE": "ทะเบียน",
            "TEXT_DEVICE_24H_DURATION": "ใช้งาน 24 ชม.",
            "TEXT_DEVICE_COUNT": "เครื่องทั้งหมด",
            "TEXT_DEVICE_FROM": "จากทะเบียน",
            "TEXT_DEVICE_GROUP": "กลุ่มรถ",
            "TEXT_DEVICE_PARK_DURATION": "ระยะเวลาจอด",
            "TEXT_DEVICE_RUN_DURATION": "ระยะเวลาขับขี่",
            "TEXT_DISABLE": "ปิดการใช้งาน",
            "TEXT_DISTANCE": "ระยะ (กม.)",
            "TEXT_DISTANCE_S": "ระยะ",
            "TEXT_DLT": "กรมขนส่งทางบก",
            "TEXT_DOWNLOAD": "Download",
            "TEXT_DRIVER": "คนขับ",
            "TEXT_DRIVER_2": "คนขับ 2",
            "TEXT_DRIVER_CARD": "บัตร",
            "TEXT_DRIVER_SCORE": "คะแนนผู้ขับขี่",
            "TEXT_DURATION": "ระยะเวลา",
            "TEXT_EMAIL": "อีเมล์",
            "TEXT_EMPLOYEE": "พนักงาน",
            "TEXT_ENABLE": "เปิดใช้งาน",
            "TEXT_ENGINE_OFF": "ดับเครื่อง",
            "TEXT_ENGINE_ON": "ติดเครื่อง",
            "TEXT_ENGINE_TIME": "ระยะเวลาติดเครื่อง",
            "TEXT_EVENT": "เหตุการณ์",
            "TEXT_EXCEPT": "ยกเว้น",
            "TEXT_EXPIRE": "ครบกำหนด",
            "TEXT_EXPIRE_NEAR": "ใกล้ครบกำหนด",
            "TEXT_EXPORT": "ส่งออกข้อมูล",
            "TEXT_FIXPOINT": "ระบุพิกัดเอง",
            "TEXT_FLASHVIEW_CODE": "รหัสเข้าดู",
            "TEXT_FROM": "จาก",
            "TEXT_FROM_TIME": "ตั้งแต่ {{date,St}}",
            "TEXT_FUELADD": "ปริมาณน้ำมันที่เติม (ลิตร)",
            "TEXT_FUELAVG_ENGINE": "ชั่วโมงทำงาน (ลิตร/ชม.)",
            "TEXT_FUELAVG_KM": "ระยะทาง (กม./ลิตร)",
            "TEXT_FUELMONEY": "ค่าน้ำมันที่ใช้/บาท",
            "TEXT_FUELPRICE": "ราคาน้ำมัน",
            "TEXT_FUEL_REMAIN": "ปริมาณน้ำมันคงเหลือ",
            "TEXT_FUELUSE": "ปริมาณการใช้น้ำมัน/ลิตร",
            "TEXT_FUELUSE_C": "อัตราการสิ้นเปลือง (กม./ลิตร)",
            "TEXT_FUELUSE_S": "ใช้น้ำมัน (ลิตร)",
            "TEXT_FUEL_VOLUME": "ปริมาณน้ำมัน",
            "TEXT_FULLNAME": "ชื่อ - นามสกุล",
            "TEXT_GENDER": "เพศ",
            "TEXT_GPS": "GPS",
            "TEXT_GROUP": "กลุ่ม",
            "TEXT_GSM": "GSM",
            "TEXT_HEIGHT": "สูง",
            "TEXT_HOWLONG": "นาน",
            "TEXT_IMPORT": "นำเข้าข้อมูล",
            "TEXT_INDEX": "No.",
            "TEXT_IP": "IP",
            "TEXT_LABEL": "ป้ายชื่อ",
            "TEXT_LATITUDE": "ละติจูด",
            "TEXT_LENGTH": "ยาว",
            "TEXT_LINE": "ไลน์",
            "TEXT_LIST": "รายการ",
            "TEXT_LOCATION": "สถานที่",
            "TEXT_LOCATION_POINT": "พิกัด",
            "TEXT_LOCATION_SHOW": "แสดงพื้นที่",
            "TEXT_LOCATION_START": "สถานที่ต้นทาง",
            "TEXT_LOGO": "โลโก้",
            "TEXT_LONGITUDE": "ลองติจูด",
            "TEXT_MAX_SPEED": "ความเร็ว สูงสุดที่ใช้",
            "TEXT_MAX_SPEED_TIME": "เวลาที่ขับเร็วสุงสุด",
            "TEXT_MILEAGE": "เลขไมล์",
            "TEXT_MOBILE": "อุปกรณ์",
            "TEXT_MONEY": "ยอดเงิน",
            "TEXT_MSISDN": "Sim No.",
            "TEXT_NAME": "ชื่อ",
            "TEXT_NAMELAST": "นามสกุล",
            "TEXT_NATION": "ประเทศ",
            "TEXT_NOCARD_COUNT": "ไม่ได้รูดบัตร {{count}} ครั้ง",
            "TEXT_NOGPS": "ไม่ได้รับ gps นาน (ชม.)",
            "TEXT_NOGROUP": "ไม่ได้จัดกลุ่ม",
            "TEXT_NONE": "ไม่มี",
            "TEXT_NONE_YET": "ยังไม่มีข้อมูล",
            "TEXT_NOSHOW": "ไม่ต้องแสดงอีก",
            "TEXT_NOSHOW_DAY": "ไม่ต้องแสดงอีกวันนี้",
            "TEXT_NOTE": "หมายเหตุ",
            "TEXT_NOTIFY": "เตือนทาง",
            "TEXT_NOTIFY_AND_SPEEDLIMIT": "และ ความเร็วมากกว่า",
            "TEXT_NOTIFY_CHANNEL": "ช่องทางการแจ้งเตือน",
            "TEXT_NOTIFY_CHANNEL_MAIN": "ช่องทางหลัก",
            "TEXT_NOTIFY_LAST": "เตือนล่าสุด",
            "TEXT_NOUPDATE": "เครื่องหยุดอัพเดท (ชม.)",
            "TEXT_NUMBER": "เลขที่",
            "TEXT_ONTIME": "เมื่อเวลา",
            "TEXT_ORDINAL": "Ordinal",
            "TEXT_PASSWORD": "รหัสผ่าน",
            "TEXT_PASSWORD_CONFIRM": "รหัสผ่านเดิม",
            "TEXT_PAYMENT": "วิธีเก็บเงิน",
            "TEXT_PERMIT_DATE": "วันที่อนุญาต",
            "TEXT_PLACE": "สถานที่",
            "TEXT_PLACE_NEAR": "สถานที่ใกล้เคียง",
            "TEXT_PLAYBACK_COMPARE": "เปรียบเทียบข้อมูลย้อนหลัง",
            "TEXT_POI": "POI",
            "TEXT_POI_BASE": "POI / กลุ่มรถ",
            "TEXT_POI_GROUP_READ": "กำหนดกลุ่ม POI ที่เห็น",
            "TEXT_POI_NOBASE": "ไม่แสดง POI/กลุ่มรถ ของตัวเอง",
            "TEXT_POI_NOENTRY": "จำนวนครั้งเข้าสถานที่ห้ามเข้า",
            "TEXT_POI_NOIN": "ไม่แสดงรถที่จอดใน POI",
            "TEXT_POINT_END": "ปลายทาง",
            "TEXT_POINT_START": "จุดเริ่มต้น",
            "TEXT_POI_STATION": "จำนวนครั้งเข้าจอดสถานี",
            "TEXT_PORT": "นำเข้า/ส่งออก",
            "TEXT_PRICE": "ราคา",
            "TEXT_PROBLEM_NOTIFY": "มีแจ้งเตือนปัญหา",
            "TEXT_RADIUS": "รัศมี",
            "TEXT_RAWDATA": "ค่าจริง",
            "TEXT_REMAIN": "คงเหลือ",
            "TEXT_RESET": "ค่า Reset",
            "TEXT_RESULT": "ผลการทำงาน",
            "TEXT_ROUTE": "เส้นทาง",
            "TEXT_ROWNUM": "ลำดับ",
            "TEXT_SEARCH": "ค้นหา",
            "TEXT_SENDED": "ส่งแล้ว",
            "TEXT_SHOW_PARKING": "แสดงจุดแวะจอด",
            "TEXT_SIGNATURE": "ลายเซ็น",
            "TEXT_SIGNATURE_CUSTOMER": "ลายเซ็นต์ลูกค้า",
            "TEXT_SIGNATURE_USER": "ลายเซ็นต์พนักงาน",
            "TEXT_SMS": "SMS",
            "TEXT_SORT": "จัดเรียง",
            "TEXT_SPEED": "ความเร็ว",
            "TEXT_SPEED_S": "เร็ว",
            "TEXT_STATE": "สถานะปัจุบัน",
            "TEXT_STATUS_ENGINE": "สถานะเครื่องยนต์",
            "TEXT_STATUS_RUN": "สถานะการวิ่ง",
            "TEXT_STATUS": "สถานะ",
            "TEXT_STEP": "Step",
            "TEXT_TELEPHONE": "เบอร์โทรศัพท์",
            "TEXT_TIME": "เวลา",
            "TEXT_TIME_AT": "ข้อมูล ณ {{time}}",
            "TEXT_TIME_END": "เวลาสิ้นสุด",
            "TEXT_TIME_IN": "เวลาเข้า",
            "TEXT_TIME_OUT": "เวลาออก",
            "TEXT_TIME_START": "เวลาเริ่ม",
            "TEXT_TIME_STAY": "เวลาที่อยู่ (นาที)",
            "TEXT_TITLE": "เรื่อง",
            "TEXT_TO": "ถึง",
            "TEXT_TOTAL": "รวม",
            "TEXT_TOTAL_ALL": "รวมทั้งหมด",
            "TEXT_TOTAL_DURATION": "เวลาที่ใช้งานทั้งหมด (ชม.)",
            "TEXT_TOTAL_PARK": "เวลาที่จอดติดเครื่อง",
            "TEXT_TOTAL_PARK10": "จำนวนครั้ง จอดเกิน 10นาที",
            "TEXT_TOTAL_PARK_MAX": "จอดติดเครื่องนานที่สุด",
            "TEXT_TOTAL_PARK_MAX_DATE": "เวลาที่จอดติดเครื่องนานที่สุด",
            "TEXT_TOTAL_SPEED": "จำนวนครั้ง วิ่งเกินความเร็ว",
            "TEXT_TYPE": "ประเภท",
            "TEXT_UPDATETIME": "อัพเดทล่าสุด",
            "TEXT_UPLOAD": "Upload",
            "TEXT_USE_DAY": "ใช้ต่อวัน",
            "TEXT_USER": "ผู้ใช้งาน",
            "TEXT_USERAGENT": "Agent",
            "TEXT_USERNAME": "ชื่อผู้ใช้งาน",
            "TEXT_WAYPOINT": "จุดแวะ",
            "TEXT_WHEN": "เมื่อ",
            "TEXT_WIDTH": "กว้าง",
            "TEXT_WORK": "งาน",
            "TMS_INFO_DUP_DEVICE": "มีการใช้รถในช่วงเวลานี้อยู่",
            "TMS_INFO_DUP_EMPLOYEE": "คนขับรถนี้มีงานแล้ว ในช่วงเวลานี้",
            "TMS_INFO_DUP_EMPLOYEE2": "มีช่วงเวลางานตรงกับวันลาของพนักงาน",
            "TMS_MENU_CALENDAR": "ปฏิทิน",
            "TMS_MENU_LIST": "รายการงาน",
            "TMS_MENU_SEARCH": "ค้นหางาน",
            "TMS_MONEY_BILL": "วางบิล",
            "TMS_MONEY_CASH": "เงินสด",
            "TMS_MONEY_NO": "ไม่เก็บ",
            "TMS_MONEY_PENDING": "ยังไม่เก็บ",
            "TMS_MONEY_TRANSFER": "โอน",
            "ONLY_AREA_IN": "เฉพาะในพื้นที่",
            "ONLY_AREA_OUT": "เฉพาะนอกพื้นที่",
            "ONLY_POI_IN": "เฉพาะใน POI",
            "ONLY_POI_OUT": "เฉพาะนอก POI",
            "TRACKING_DEVICE_CARD": "ใบขับขี่",
            "TRACKING_DEVICE_SUSPEND_BODY": "รถทะเบียนตามรายการนี้ ใกล้ครบ หรือครบกำหนดชำระเงินแล้ว กรุณาติดต่อทางเราเพื่อทำการชำระเงิน หากเลยกำหนด จะมีผลต่อความต่อเนื่องในการดูระบบติดตาม และขออภัยหากคุณชำระแล้วค่ะ",
            "TRACKING_DEVICE_SUSPEND_HEAD": "แจ้งเตือนเครื่องครบกำหนด",
            "TRACKING_MENU_ALERT": "แจ้งเตือน",
            "TRACKING_MENU_AREA": "พื้นที่",
            "TRACKING_MENU_COMMAND": "ส่งคำสั่ง",
            "TRACKING_MENU_DEVICE": "สถานะ",
            "TRACKING_MENU_NEAR": "ใกล้",
            "TRACKING_MENU_NOW": "ปัจจุบัน",
            "TRACKING_MENU_PLAYBACK": "ย้อนหลัง",
            "TRACKING_MENU_POI": "POI",
            "TRACKING_PLAYBACK_VAD_NO": "ไม่พบข้อมูลการวิ่ง",
            "TRACKING_POI_INFO_ICON": "ไอค่อน",
            "TRACKING_STATUS_FAST": "เร็ว",
            "TRACKING_STATUS_OFF": "ดับ",
            "TRACKING_STATUS_PARK": "จอด",
            "TRACKING_STATUS_RUN": "วิ่ง",
            "TRACKING_STATUS_STOP": "หยุด",
            "TRACKING_STATUS_TOWER": "เสา",
            "UNIT_BATH": "บาท",
            "UNIT_BPK": "บาท/กม.",
            "UNIT_BPL": "บาท/ลิตร",
            "UNIT_COUNT": "ครั้ง",
            "UNIT_DAY": "วัน",
            "UNIT_HOUR": "ชม.",
            "UNIT_KM": "กม.",
            "UNIT_KMPH": "กม./ชม.",
            "UNIT_KMPL": "กม./ลิตร",
            "UNIT_LITER": "ลิตร",
            "UNIT_LPH": "ลิตร/ชม.",
            "UNIT_METRE": "เมตร",
            "UNIT_MINUTE": "นาที",
            "UNIT_MONTH": "เดือน",
            "UNIT_SECOND": "วินาที",
            "UNITTEXT_NUMBER": "รายการที่ {{number}}",
            "USER_API_ADD": "สร้าง Key ใหม่",
            "USER_API_EMPTY": "ยังไม่ได้สร้าง API Key",
            "USER_DEVICE_FIELD_CHART": "กราฟน้ำมัน",
            "USER_DEVICE_FIELD_CHART_HELP": "คลิ้กที่กราฟแล้วลากเพื่อเลือกช่วงที่น้ำมันต่ำสุด และสูงสุด",
            "USER_DEVICE_FIELD_MAX": "ช่วงเวลาสูงสุด",
            "USER_DEVICE_FIELD_MIN": "ช่วงเวลาต่ำสุด",
            "USER_DEVICE_FIELD_REVERSE": "สลับค่า ต่ำสุด-สูงสุด ของข้อมูล",
            "USER_DEVICE_FIELD_TELEPHONE": "เบอร์คนขับ",
            "USER_DEVICE_FIELD_TRACKER": "รุ่น Tracker",
            "USER_DEVICE_FORM_EDIT_HEAD": "แก้ไขข้อมูลอุปกรณ์",
            "USER_DEVICE_FORM_MARKER_HEAD": "ตั้งค่า Marker",
            "USER_DEVICE_FORM_OIL_HEAD": "ตั้งค่าน้ำมัน",
            "USER_DEVICE_TABLE_ALERT_WHEN": "เมื่อเปลี่ยนเป็น",
            "USER_DRIVER_VAD_EMPTY": "กรุณาใส่ชื่อพนักงานขับรถ",
            "USER_FORM_ADD_HEAD": "เพิ่มข้อมูล",
            "USER_FORM_SUMMARY_SEND": "ส่งสรุปรายงานประจำวัน",
            "USER_INFO_CHECKPOINT": "เครดิต CheckPoint",
            "USER_INFO_SMS": "เครดิต SMS",
            "USER_MENU_API": "จัดการข้อมูล API",
            "USER_MENU_AREA": "Area",
            "USER_MENU_CHECKPOINT": "Checkpoint",
            "USER_MENU_COMMAND": "ส่งคำสั่ง",
            "USER_MENU_COMPANY": "ข้อมูลบริษัท",
            "USER_MENU_CUSTOMER": "ข้อมูลลูกค้า",
            "USER_MENU_DASHBOARD": "หน้าหลัก",
            "USER_MENU_DEVICE": "ตั้งค่าตัวเครื่อง",
            "USER_MENU_EMPLOYEE": "พนักงาน",
            "USER_MENU_EMPLOYEE_DAY": "วันลา",
            "USER_MENU_FLASHVIEW": "ดูชั่วคราว",
            "USER_MENU_FUEL": "เติมน้ำมัน",
            "USER_MENU_LOG": "ประวัติการใช้งาน",
            "USER_MENU_LOG_CHECKPOINT": "ประวัติการใช้เครดิต CheckPoint",
            "USER_MENU_LOG_SIGN": "ประวัติการเข้าระบบ",
            "USER_MENU_LOG_SMS": "ประวัติการส่ง SMS",
            "USER_MENU_MOBILE": "Mobile",
            "USER_MENU_NOTIFICATION": "การแจ้งเตือน",
            "USER_MENU_POI": "POI",
            "USER_MENU_PROFILE": "ข้อมูลผู้ใช้งาน",
            "USER_MENU_REPORT": "รายงาน",
            "USER_MENU_SUBUSER": "Sub-user",
            "USER_NOTIFY_AD": "ค่าจากเซนเซอร์",
            "USER_NOTIFY_AREA": "พื้้นที่",
            "USER_NOTIFY_DRIVER_CARD": "การรูดบัตร",
            "USER_NOTIFY_EVENT": "เหตุการณ์",
            "USER_NOTIFY_PARK_LIMIT": "การจอด",
            "USER_NOTIFY_POI": "POI",
            "USER_NOTIFY_SPEED_LIMIT": "เกินความเร็ว",
            "USER_NOTIFY_STATE": "สถานะเครื่อง",
            "USER_POI_FIELD_EMAIL": "แจ้งเตือนทางอีเมล์",
            "USER_POI_FIELD_IN": "แจ้งเมื่อเข้า",
            "USER_POI_FIELD_OUT": "แจ้งเมื่อออกจาก",
            "USER_POI_FIELD_SM": "Icon ขนาดเล็ก",
            "USER_POI_LOCATION": "สถานที่ตั้ง",
            "USER_PROFILE_FAIL_PASS_CONFIRM": "การยืนยันรหัสผ่านเดิม ไม่ถูกต้อง",
            "USER_PROFILE_FAIL_PASS_LENGTH": "รหัสผ่านใหม่สั้นเกินไป",
            "USER_PROFILE_FAIL_PASS_RE": "การยืนยันรหัสผ่าน ไม่ถูกต้อง",
            "USER_PROFILE_FAIL_USER_DUP": "ชื่อผูู้้ใช้งานซ้ำแล้ว",
            "USER_PROFILE_FORM_PASSWORD_HEAD": "เปลี่ยนรหัสผ่าน",
            "USER_SETTING_TAGS_NOTE": "ใช้ Enter เพื่อเพิ่มป้ายใหม่",
            "USER_SETTING_TMSPAY": "ช่องทางการจ่ายเงิน",
            "USER_TEXT_DOCUMENT_GOV": "เอกสารราชการ",
            "USER_TEXT_EMAIL_NOTE": "หากต้องการใส่หลายอีเมล์ให้คั่นด้วย , (ลูกน้ำ)",
            "USER_TEXT_IDCARD": "เลขบัตรประชาชน",
            "USER_TEXT_LICENCE_GOV": "ออกที่สาขา",
            "USER_TEXT_LICENCE_NUMBER": "เลขที่ใบขับขี่",
            "USER_TEXT_LICENCE_TYPE": "ประเภทใบขับขี่",
            "USER_TEXT_PASSWORD_NOTE": "ถ้าไม่ต้องการเปลี่ยนรหัสผ่าน ไม่ต้องกรอก",
            "USER_TEXT_PREFIX": "คำนำหน้า",
            "USER_TEXT_REGISTRAR": "นายทะเบียนประจำ",
            "USER_TEXT_REPASSWORD": "ยืนยันรหัสผ่าน"
        }
    },
    "en": {
        "translation": {
            "TEST_TEXT": "Command example",
            "API_NAME_DEVICE_ALL": "Request a list of all cars in the account",
            "API_NAME_DEVICE_GET": "Request current vehicle status from ID",
            "API_NAME_DEVICE_LOG": "View driving history",
            "API_NAME_POI_ALL": "Request a list of all POIs in your account",
            "API_NAME_POI_GET": "Request POI from ID",
            "API_NAME_REPORT_LENGTH": "Running distance report",
            "API_NAME_REPORT_RS232": "card swiping report",
            "API_NAME_REPORT_WORKLOAD": "Report working hours",
            "API_NAME_VERSION": "API version number",
            "API_PARAM_ADDRESS": "Show address (enter 1 to show address, report output efficiency will be reduced)",
            "API_PARAM_DEVICE": "Device number",
            "API_PARAM_END": "end time",
            "API_PARAM_LIMIT": "Number of items required",
            "API_PARAM_ORDER": "Sorting",
            "API_PARAM_START": "start time",
            "API_RESP_DEVICES": "car list",
            "API_RESP_DEVICES_ID": "Vehicle ID number",
            "API_RESP_OK": "API working status",
            "API_RESP_VERSION": "API version number",
            "APP_FINGERPRINT_SCAN": "Scan your fingerprint to check permissions",
            "APP_PIN": "Enter PIN code",
            "APP_PIN_CONFIRM": "Confirm PIN code",
            "APP_PIN_ERROR": "The Pin code is incorrect.",
            "APP_PIN_ERROR_NOTMATCH": "PIN codes do not match",
            "APP_PIN_ERROR_NOTOLD": "The new PIN must not be the same as the old PIN.",
            "APP_PIN_NEW": "Enter a new PIN code.",
            "APP_PIN_OLD": "Enter the old PIN code.",
            "APP_SETTING_APIMOON": "Backup server",
            "APP_SETTING_BETA": "use Beta",
            "APP_SETTING_DARK": "Night mode",
            "APP_SETTING_FINGERPRINT": "fingerprint scanner",
            "APP_SETTING_PIN": "Set up a PIN code",
            "APP_SETTING_PIN_CHANGE": "Change PIN code",
            "APP_SETTING_PIN_DELETE": "Delete PIN",
            "APP_SETTING_PIN_ON": "Turn on PIN LOCK screen.",
            "BUTTON_ADD": "Add New",
            "BUTTON_GEN_AREA": "Create area",
            "BUTTON_ADD_EMP_DAY": "Add leave",
            "BUTTON_CANCEL": "Cancel",
            "BUTTON_CHECK": "Check information",
            "BUTTON_CLEAR": "Clear",
            "BUTTON_COMMAND_SEND": "Confirm sending of order",
            "BUTTON_CONFIRM": "Confirm",
            "BUTTON_CONFIRM_PRIVACY": "Accept",
            "BUTTON_DELETE": "Delete",
            "BUTTON_DOWNLOAD_CERT": "Certificate",
            "BUTTON_EDIT": "Edit",
            "BUTTON_HIDE": "Hide",
            "BUTTON_LOGIN": "Login",
            "BUTTON_LOGOUT": "Logout",
            "BUTTON_MONEY_RECEIVE": "received money",
            "BUTTON_MONEY_UNRECEIVE": "unreceived money",
            "BUTTON_NOTIFY": "Notification",
            "BUTTON_NOTIFY_SETTING": "Setup notifications",
            "BUTTON_OK": "OK",
            "BUTTON_PORT": "import/export",
            "BUTTON_PRINT": "Print",
            "BUTTON_RESET": "Reset",
            "BUTTON_RUN": "carry out",
            "BUTTON_SAVE": "Save Data",
            "BUTTON_SAVE_SORT": "Sorting record",
            "BUTTON_SEARCH": "Search",
            "BUTTON_SKIP": "Skip",
            "BUTTON_TEMPLATE_LOAD": "Call from template",
            "BUTTON_TEMPLATE_SAVE": "Save to template",
            "BUTTON_UNHIDE": "Unhide",
            "BUTTON_UPDATE_APP": "Update",
            "BUTTON_UPLOAD": "Upload",
            "CONFIRM_DELETE": "Confirm to Delete",
            "CONFIRM_DELETE_SELECT": "Confirm deletion of selected data.",
            "CONFIRM_IMPORT": "ยืนยันการนำเข้าข้อมูล",
            "CONFIRM_LOGOUT": "Logout ?",
            "CONFIRM_MONEY_RECEIVE": "Confirm receipt of money!?",
            "CONFIRM_MONEY_UNRECEIVE": "Cancel receiving money!?",
            "CONFIRM_RESET": "Confirm to Reset  !?",
            "DALERT_COMMAND_SEND_ERROR": "Could not send data, please try again.",
            "DALERT_DELETE_FAIL": "Unable to delete data",
            "DALERT_EDIT_NOTFOUND": "update information not found",
            "DALERT_HISTORY_EMPTY": "No retrospective history was found :",
            "DALERT_HISTORY_NOMOVE": "The car does not move",
            "DALERT_MUST_DEVICE": "Must choose a car",
            "DALERT_REPORT_EMPTY": "Report data not found",
            "DALERT_SAVE_FAIL": "Can't record data",
            "DALERT_USERNAME_DUP": "This username is already in the system.",
            "DATE_DAY_ALL": "All Day",
            "DATE_DAY_0": "Sunday",
            "DATE_DAY_1": "Monday",
            "DATE_DAY_2": "Tuesday",
            "DATE_DAY_3": "Wednesday",
            "DATE_DAY_4": "Thursday",
            "DATE_DAY_5": "Friday",
            "DATE_DAY_6": "Saturday",
            "DATE_DAY_SHORT_0": "S",
            "DATE_DAY_SHORT_1": "M",
            "DATE_DAY_SHORT_2": "Tu",
            "DATE_DAY_SHORT_3": "W",
            "DATE_DAY_SHORT_4": "Th",
            "DATE_DAY_SHORT_5": "F",
            "DATE_DAY_SHORT_6": "Sat",
            "DATE_MONTH_1": "January",
            "DATE_MONTH_2": "February",
            "DATE_MONTH_3": "March",
            "DATE_MONTH_4": "April",
            "DATE_MONTH_5": "May",
            "DATE_MONTH_6": "June",
            "DATE_MONTH_7": "July",
            "DATE_MONTH_8": "August",
            "DATE_MONTH_9": "September",
            "DATE_MONTH_10": "October",
            "DATE_MONTH_11": "November",
            "DATE_MONTH_12": "December",
            "DATE_MONTH_LAST": "last month",
            "DATE_MONTH_SHORT_1": "Jan",
            "DATE_MONTH_SHORT_2": "Feb",
            "DATE_MONTH_SHORT_3": "Mar",
            "DATE_MONTH_SHORT_4": "Apr",
            "DATE_MONTH_SHORT_5": "May",
            "DATE_MONTH_SHORT_6": "Jun",
            "DATE_MONTH_SHORT_7": "Jul",
            "DATE_MONTH_SHORT_8": "Aug",
            "DATE_MONTH_SHORT_9": "Sep",
            "DATE_MONTH_SHORT_10": "Oct",
            "DATE_MONTH_SHORT_11": "Nov",
            "DATE_MONTH_SHORT_12": "Dec",
            "DATE_MONTH_THIS": "This month",
            "DATE_S": "{{date,S}}",
            "DATE_ST": "{{date,St}}",
            "DATE_TODAY": "Today",
            "DATE_TRACK": "{{date,d MMM HH:mm}}",
            "DATE_YESTERDAY": "Yesterday",
            "DCONFIRM_CHANGE_LANGUAGE": "You want to change the language",
            "DCONFIRM_LOGOUT": "You want to log out",
            "DCONFIRM_SEND_LOG": "Confirm data submission",
            "TEXT_ALL_PLAY": "Play all camera images",
            "TEXT_ALL_CLOSE": "All closed",
            "TEXT_ALL_PAUSE": "Stop playing all images.",
            "DEVICE_COMMAND": "Command",
            "DEVICE_COMMAND_CRONJOB": "Set work time",
            "DEVICE_COMMAND_RESPOND": "Result",
            "DEVICE_COMMAND_RESPTIME": "Respond Time",
            "DEVICE_COMMAND_SEND": "Send Command",
            "DEVICE_COMMAND_SENDING": "กำลังจะส่งคำสั่ง",
            "DEVICE_COMMAND_SENDTIME": "Send Time",
            "DEVICE_DVR_CHANNEL": "กล้องตัวที่ {{channel}}",
            "DEVICE_MAXVOLUME": "Maximum loading volume (cubic meter)",
            "DEVICE_MAXWEIGHT": "Maximum weight that can be carried",
            "DEVICE_NOTIFY_AD": "On {{ad}} {{to}} {{value}}",
            "DEVICE_NOTIFY_AREA_LOCATION": "specified area",
            "DEVICE_NOTIFY_DLT": "แจ้งเตือนกรมขนส่ง",
            "DEVICE_NOTIFY_NOCARD_DURATION": "Run the device without swiping the card for {{duration}} minutes.",
            "DEVICE_NOTIFY_PARK_DURATION": "Park the engine for {{duration}} minutes.",
            "DEVICE_NOTIFY_POIALL": "All Poi",
            "DEVICE_NOTIFY_POI_IN": "เมื่อเข้า {{poi}}",
            "DEVICE_NOTIFY_POI_OUT": "เมื่อออกจาก {{poi}}",
            "DEVICE_NOTIFY_POISTOP_IN": "Parked in POI for more than {{duration}} minutes",
            "DEVICE_NOTIFY_RS232_NO": "ติดเครื่อง แต่ไม่รูดบัตร นานเกิน {{duration}} นาที",
            "DEVICE_NOTIFY_SPEEDLIMIT": "When the speed exceeds the limit",
            "DEVICE_NOTIFY_STATE": "On {{state}} change to {{to}}",
            "DEVICE_TEXT_FUELUSE": "Fuel use",
            "DEVICE_TEXT_FUELVOLUME": "Fuel tank volume",
            "DEVICE_TEXT_MODEL": "Model",
            "DEVICE_TEXT_NOTIFY": "Notification",
            "DEVICE_TEXT_SPEEDLIMIT": "Speed limited",
            "DIALOG_ADD": "Add",
            "DIALOG_LANGUAGE": "Change language",
            "EVENT_1": "Engine & Input 1 Active",
            "EVENT_10": "Engine & Input 2 Inactive",
            "EVENT_109": "Taxi Meter Event",
            "EVENT_11": "Engine & Input 3 Inactive",
            "EVENT_1101": "Into POI",
            "EVENT_1102": "Leave POI",
            "EVENT_12": "Engine & Input 4 Inactive",
            "EVENT_1201": "Parked for more than 10 minutes",
            "EVENT_1202": "Turn on the device but don't swipe the card for more than 10 minutes.",
            "EVENT_129": "Harsh Braking",
            "EVENT_13": "Engine & Input 5 Inactive",
            "EVENT_130": "Harsh Acceleration",
            "EVENT_133": "Idle Overtime",
            "EVENT_134": "Idle Recovery",
            "EVENT_135": "Fatigue Driving",
            "EVENT_136": "Enough Rest after Fatigue Driving",
            "EVENT_138": "Speed Recovery",
            "EVENT_17": "Low Battery",
            "EVENT_18": "Low External Power",
            "EVENT_19": "Speeding",
            "EVENT_2": "Engine & Input 2 Active",
            "EVENT_20": "Enter Geo-fence",
            "EVENT_21": "Exit Geo-fence",
            "EVENT_22": "External Power On",
            "EVENT_23": "External Power Off",
            "EVENT_24": "No GPS Signal",
            "EVENT_25": "Get GPS Signal",
            "EVENT_26": "Enter Sleep",
            "EVENT_27": "Exit Sleep",
            "EVENT_28": "GPS Antennal Cut",
            "EVENT_29": "Device Reboot",
            "EVENT_3": "Engine & Input 3 Active",
            "EVENT_30": "Impact",
            "EVENT_31": "Heartbeat Report",
            "EVENT_32": "Heading Change Report",
            "EVENT_33": "Distance Interval Report",
            "EVENT_34": "Current Location Report",
            "EVENT_35": "Time Interval Report",
            "EVENT_36": "Tow Alarm",
            "EVENT_37": "RFID",
            "EVENT_39": "Photo",
            "EVENT_4": "Engine & Input 4 Active",
            "EVENT_40": "Power off button",
            "EVENT_41": "Stop Moving",
            "EVENT_42": "Start Moving",
            "EVENT_44": "GSM Jamming",
            "EVENT_5": "Engine & Input 5 Active",
            "EVENT_50": "Temperature High",
            "EVENT_51": "Temperature Low",
            "EVENT_52": "Full Fuel",
            "EVENT_53": "Low Fuel",
            "EVENT_54": "Fuel Theft",
            "EVENT_56": "Armed (T36x)",
            "EVENT_57": "Disarmed (T36x)",
            "EVENT_58": "Vehicle Theft (T36x)",
            "EVENT_63": "No GSM Jamming",
            "EVENT_65": "Press Input 1 (SOS) to Call",
            "EVENT_66": "Press input 2 to Call",
            "EVENT_67": "Press input 3 to Call",
            "EVENT_68": "Press input 4 to Call",
            "EVENT_69": "Press input 5 to Call",
            "EVENT_70": "Reject Incoming Call",
            "EVENT_71": "Report Location by Call",
            "EVENT_72": "Auto Answer Incoming Call",
            "EVENT_73": "Listen-in (voice monitoring)",
            "EVENT_78": "Impact",
            "EVENT_82": "Full Fuelling",
            "EVENT_83": "Ult-Sensor Drop",
            "EVENT_86": "Swipe Card to Leave",
            "EVENT_9": "Engine & Input 1 Inactive",
            "EVENT_90": "Sharp Turn to Left",
            "EVENT_90_B": "Sharp Turn",
            "EVENT_91": "Sharp Turn to Right",
            "EVENT_HIK_643": "GPS Data Report",
            "EVENT_HIK_644": "Front Vehicle Collision Warning",
            "EVENT_HIK_645": "Short Vehicle Distance Warning",
            "EVENT_HIK_646": "Lane Deviation Warning",
            "EVENT_HIK_647": "Pedestrian Collision Warning",
            "EVENT_HIK_648": "Overspeed Warning",
            "EVENT_HIK_649": "Blind Spot Detection Warning",
            "EVENT_HIK_650": "Smoking",
            "EVENT_HIK_651": "On the Phone",
            "EVENT_HIK_652": "Fatigue Driving",
            "EVENT_HIK_653": "Distracted Driving",
            "EVENT_HIK_654": "Not Buckled Up",
            "EVENT_HIK_655": "Video Tampering",
            "EVENT_HIK_656": "Panic Button Alarm",
            "EVENT_HIK_657": "Sharp Turn",
            "EVENT_HIK_658": "Sudden Brake",
            "EVENT_HIK_659": "Sudden Brake",
            "EVENT_HIK_660": "Rollover",
            "EVENT_HIK_661": "Overspeed",
            "EVENT_HIK_662": "Collision",
            "EVENT_HIK_663": "Enter Fence",
            "EVENT_HIK_664": "Deviation",
            "EVENT_HIK_665": "Exit Fence",
            "FAB_BOUND": "Bound",
            "FAB_GO_LOCATION": "Current position",
            "FAB_MAP_SAT": "Satellite Map",
            "FAB_MAP_TRAFFIC": "Traffic",
            "FAB_POI_SHOW": "Show POI",
            "FAB_REFRESH": "Refresh",
            "FORM_CHECK_ALL": "Selected All",
            "FORM_CHECK_NONE": "Select none",
            "FORM_SELECT": "-- Choose --",
            "FORM_SELECT_ALL": "-- All --",
            "FORM_SELECT_NONE": "-- None --",
            "GROUPBY_DAY": "by day",
            "GROUPBY_MONTH": "by month",
            "GROUPBY_WEEK": "by week",
            "INFO_CLOSEAPP": "Press back again to close the application.",
            "INFO_COMMAND_RESPONDED": "The server responded",
            "INFO_COMMAND_RESPOND_TEXT": "Response status",
            "INFO_COMMAND_SENDED": "Data sent",
            "INFO_COMMAND_SENDING": "Sending data",
            "INFO_COMMAND_STATUS": "Sending status",
            "INFO_COMMAND_WAIT": "Replying...",
            "INFO_DATE_REQ": "Please select a date first.",
            "INFO_DROPED": "delete completed",
            "INFO_DROP_FAIL": "Can't deleted data",
            "INFO_DROP_NOTFOUND": "delete information not found",
            "INFO_DVR_STATUS_FAIL": "DVR Offline",
            "INFO_EXPIRE_DETAIL": "The following devices are going to expire or already meet expire date. Please contact us to extend our service otherwise, the system will automatically delete the devices.",
            "INFO_EXPIRE_NOTIFY": "Device expiring alert",
            "INFO_LOGOUT": "Log out",
            "INFO_MONEY_JOB": "Income from work",
            "INFO_MONEY_RECEIVE": "Money received",
            "INFO_MONEY_UNRECEIVE": "The money has been canceled.",
            "INFO_NOTFOUND": "Data not found",
            "INFO_NOTIFY_DUPLICATE": "This notification has been received.",
            "INFO_REPORT_WAIT": "Please wait. Generating report.",
            "INFO_SAVED": "Data saved",
            "INFO_SAVE_DATA_INCOMPLETE": "Please complete the information",
            "INFO_SAVE_FAIL": "Can't Saved",
            "INFO_SAVE_NOTFOUND": "save information not found",
            "INPUT_POI_NAME": "Your poi location",
            "LABEL_PIN_CREATE": "Set Application PIN code",
            "LOGIN_SUCCESS": "Login Success",
            "LOGIN_TITLE": "Login",
            "LOGIN_VALIDATE_FAIL": "Username or Password Incorrected",
            "MA_MENU_HISTORY": "ประวัติ",
            "MA_MENU_LIST": "alert list",
            "MA_MENU_SERVICE": "Service",
            "MA_MENU_SUMMARY": "Maintenance report",
            "MA_MENU_TEMPLATE": "Template",
            "MA_MODE_DURATION": "Dated duration",
            "MA_MODE_LENGTH": "Driving destance",
            "MA_MODE_WORKTIME": "Working hours",
            "MA_NOTIFY": "To determine the maintenance {{name}} of the car {{device}}",
            "MA_TEXT_CURRENT": "Current",
            "MA_TEXT_CURRENT_UNIT": "Current {{value}} {{unit}}",
            "MA_TEXT_DATE_IN": "Date of sending the car in",
            "MA_TEXT_DATE_OUT": "Date of receiving the car back",
            "MA_TEXT_GARAGE": "Garage",
            "MA_TEXT_INTERVAL": "Alert every",
            "MA_TEXT_INTERVAL_UNIT": "Alert every {{value}} {{unit}}",
            "MA_TEXT_LIST": "Repair list",
            "MA_TEXT_MODE": "Meter by",
            "MA_TEXT_REMAIN": "Alert when {{value}} {{unit}}",
            "MENU_CONTROLROOM": "Control Room",
            "MENU_DEVICE": "Devices",
            "MENU_DISTANCE": "Measure",
            "MENU_HELP": "Help",
            "MENU_HISTORY": "Playback",
            "MENU_LOGOUT": "Logout",
            "MENU_MA": "Maintenance",
            "MENU_MAP": "Map",
            "MENU_MOBILE": "Mobile",
            "MENU_MOBILE_DVR": "กล้อง DVR",
            "MENU_MOBILE_INFO": "Info",
            "MENU_MOBILE_MAP": "Map",
            "MENU_MOBILE_NOTIFY": "notify",
            "MENU_MODULE": "Systems",
            "MENU_MODULE_CHECKPOINT": "Checkpoint",
            "MENU_MODULE_MA": "Maintenance",
            "MENU_MODULE_REVENUE": "Transportation income",
            "MENU_MODULE_TMS": "Fleet System",
            "MENU_MODULE_TMSROUTE": "TMS Route",
            "MENU_POI": "Poi",
            "MENU_PRIVACY": "Privacy Policy",
            "MENU_REPORT": "Report",
            "MENU_RESELLER": "Reseller",
            "MENU_SETTING": "Setting",
            "MENU_TOOL": "tool",
            "MENU_TOOL_POI": "Measure POI distance",
            "MENU_TRACKING": "Track",
            "MENU_TRACKING_TAXI": "Track Taxi",
            "NOTE_FUEL_FULL": "Refueling transactions will be correctly reported. In the case where every time you refuel, the tank must be filled. and only know the time of filling",
            "NOTE_FUEL_VOLUME": "The oil list will be correctly reported. In the case where the fuel tank is only square in size",
            "NOTE_FUEL_VOLUME_REPORT": "Every time you add gas, you must turn off the engine.",
            "NOTE_PORTCLEAR": "All data will be lost. Please make a backup before clicking.",
            "NOTIFY_AD": "{{device}} {{ad}} {{to}} {{value}} ({{current}})",
            "NOTIFY_AREA_IN": "{{device}} enters {{area}} {{address}}",
            "NOTIFY_AREA_OUT": "{{device}} leaves {{area}} ({{duration}}) {{address}}",
            "NOTIFY_DLT_NOCARD": "{{device}} The driver does not swipe the back of the engine for more than 10 minutes.",
            "NOTIFY_DLT_OVERTIME": "{{device}} Drive for more than 4 hours.",
            "NOTIFY_DLT_STOP": "รถทะเบียน {{device}} หยุดการส่งข้อมูลไปยังกรมการขนส่งทางบกเกิน 7 วัน กรุณาตรวจสอบอุปกรณ์จีพีเอสให้ทำงานปกติ ก่อนนำรถไปใช้งาน หรือ ติดต่อ 02-111-7999 เพื่อขอรับการช่วยเหลือ",
            "NOTIFY_DRIVER_CARD": "{{device}} Stick to the machine but not swipe the card for a long time {{minute}} minutes",
            "NOTIFY_EVENT": "{{device}} {{event}} , {{poi}} {{address}}",
            "NOTIFY_LABEL_EMAIL": "Send notification via Email",
            "NOTIFY_LABEL_LINE": "Send notification via Line",
            "NOTIFY_LABEL_PUSH": "Send notification via App",
            "NOTIFY_LABEL_SMS": "Send notification via SMS",
            "NOTIFY_PARK_LIMIT": "{{device}} parked for more than {{minute}} minutes , {{poi}} {{address}}",
            "NOTIFY_POI_IN": "{{device}} enters {{poi}} {{address}}",
            "NOTIFY_POI_OUT": "{{device}} leaves {{poi}} {{address}}",
            "NOTIFY_POISTOP": "{{device}} Park outside POI exceeds {{time}} minutes {{address}}",
            "NOTIFY_SETTING": "Notification Setting",
            "NOTIFY_SPEED_LIMIT": "{{device}} Drive too fast ({{speed}} > {{speed_limit}}) , {{poi}} {{address}}",
            "NOTIFY_STATE": "{{device}} Change {{state}} to {{to}} , {{poi}} {{address}}",
            "NOTIFICATION_ENGINE_OFF": "Turn off the engine",
            "NOTIFICATION_ENGINE_PARK": "Park the engine",
            "NOTIFICATION_ENGINE_RUN": "run",
            "OPTION_APPROVE": "approve",
            "OPTION_DECREASE": "Decreased less than",
            "OPTION_DENY": "disapproved",
            "OPTION_ENABLE_CLUSTER": "Show Cluster marker",
            "OPTION_FILTER": "Filter by",
            "OPTION_GT": "Greater than",
            "OPTION_IN": "In",
            "OPTION_INOUT": "In and Out",
            "OPTION_LT": "Less than",
            "OPTION_MAN": "Male",
            "OPTION_OPSEVENT": "opposite status",
            "OPTION_OUT": "Out",
            "OPTION_OVER_HOUR": "over {{hour}} hour",
            "OPTION_OVER_MINUTE": "over {{minute}} min",
            "OPTION_SHOW_CHECK": "Show Checkbox",
            "OPTION_SHOW_DEVICE": "Show Device In",
            "OPTION_SHOW_LABEL": "Show Label",
            "OPTION_SHOW_ROWNUM": "Show serial number",
            "OPTION_SHOW_SECOND": "Show Second",
            "OPTION_SHOW_STATUS": "Show Status",
            "OPTION_TOTAL": "all",
            "OPTION_WAIT": "wait",
            "OPTION_WOMAN": "Female",
            "OPTION_NARROW": "narrow",
            "OPTION_NORMAL": "normal",
            "OPTION_WIDE": "wide",
            "REPORT": "Reports",
            "REPORT_ATTIME": "Report information at {{time}}",
            "REPORT_DISPLAY_PLACE": "Show Place",
            "REPORT_DISPLAY_TABLE": "Show datatable",
            "REPORT_DRIVER_SCORE_DAY": "It's points per day.",
            "REPORT_DRIVER_SCORE_SETTING": "set score",
            "REPORT_DUMP_OFF": "Dump stop",
            "REPORT_DUMP_ON": "Work dump",
            "REPORT_FILE_AREA": "Enter and exit the area",
            "REPORT_FILE_AREA_LENGTH": "Area In-Out Report",
            "REPORT_FILE_CHART_CYCLE": "Cycle graph",
            "REPORT_FILE_CHART_FUELUSE": "Fuel Graph",
            "REPORT_FILE_CHART_GASUSE": "Gas Graph",
            "REPORT_FILE_CHART_POWER": "Power Supply Graph",
            "REPORT_FILE_CHART_SPEED": "Speed Graph",
            "REPORT_FILE_CHART_STATUS": "Usage status",
            "REPORT_FILE_CHART_TEMP": "Temperature Graph",
            "REPORT_FILE_DRIVER_SCORE": "Driver Rating",
            "REPORT_FILE_DVR": "DVR",
            "REPORT_FILE_DVR_EVENT": "ADAS DBA Event",
            "REPORT_FILE_DVR_STATUS": "DVR Status",
            "REPORT_FILE_EVENT": "Event Report",
            "REPORT_FILE_FUEL": "Compare fuel use with distance.",
            "REPORT_FILE_FUELAVG": "Average fuel consumption and distance",
            "REPORT_FILE_GROUP": "Group Report",
            "REPORT_FILE_LENGTH": "Distance Report",
            "REPORT_FILE_LENGTH_SUMMARY": "Summary of usage period",
            "REPORT_FILE_MA": "Maintenance history",
            "REPORT_FILE_MA_SERVICE": "History of entering the maintenance center",
            "REPORT_FILE_PARKING": "Parking Report",
            "REPORT_FILE_PARKING_FREQ": "Parking Report",
            "REPORT_FILE_PHOTO": "Device Picture",
            "REPORT_FILE_POI": "POI In-Out",
            "REPORT_FILE_POIOUT": "POI Out",
            "REPORT_FILE_REVENUE": "Net income from transportation",
            "REPORT_FILE_ROUTE": "History Report",
            "REPORT_FILE_RS232": "card swipe",
            "REPORT_FILE_SPEED_GROUP": "Speed frequency range",
            "REPORT_FILE_STATUS": "Status Report",
            "REPORT_FILE_SUMMARY": "Summary Report",
            "REPORT_FILE_SUMMARY_STATUS": "Status Report",
            "REPORT_FILE_WORKLOAD": "Workload Report",
            "REPORT_FILE_WORKLOAD_MONTH": "Workload Month Report",
            "REPORT_FILE_DLT_LOGSUM": "Unusual coordinates of the Department of Transport",
            "REPORT_GROUP_CHART": "Chart",
            "REPORT_GROUP_DATA": "report",
            "REPORT_GROUP_PHOTO": "View",
            "REPORT_OPT_CHART": "Summary graph",
            "REPORT_OPT_CHART_CHK": "Show summary graph",
            "REPORT_OPT_COUNTFREQ": "repeated times",
            "REPORT_OPT_DISPLAY": "Show results",
            "REPORT_OPT_DLT4H": "Over 4 Hours",
            "REPORT_OPT_DUMP": "Verify the operation of the dump.",
            "REPORT_OPT_FUEL": "Fuel consumption rate from the oil level sensor.",
            "REPORT_OPT_FUELDETECT": "Oil prices move abnormally",
            "REPORT_OPT_LOCATION": "specified area",
            "REPORT_OPT_MONTH": "Month summary",
            "REPORT_OPT_NOCARD": "no card swipe",
            "REPORT_OPT_OPSDELAY": "speed delay for {{value}} min",
            "REPORT_OPT_OPSDELAY_NONE": "no time delay",
            "REPORT_OPT_OVERSPEED": "only overspeed",
            "REPORT_OPT_PARKTIME": "Parking Time",
            "REPORT_OPT_STATUS": "car status",
            "REPORT_OPT_TMS_STATE": "Work status",
            "REPORT_OPT_USESPEED": "Specify the speed limit",
            "REPORT_SUMMARY_COUNT": "Data sending times",
            "REPORT_SUMMARY_DURATION": "Total usage times",
            "REPORT_SUMMARY_LENGTH": "Total Distance",
            "REPORT_SUMMARY_MAXSPEED": "Max Speed",
            "REPORT_SUMMARY_MAXTIME": "Longest Duration",
            "REPORT_SUMMARY_NOGPS": "No GPS Signal",
            "REPORT_SUMMARY_PARK": "Engine ON Parking",
            "REPORT_SUMMARY_PARK_MAXTIME": "Max parking duration",
            "REPORT_SUMMARY_ROUTE": "Traveling",
            "REPORT_SUMMARY_SPEEDLIMIT": "Above speed limited",
            "REPORT_SUMMARY_STOP": "Engine Off Parking",
            "REPORT_TABLE_FUELAVG_ENGINE": "Working hours (liters/hour)",
            "REPORT_TABLE_FUELAVG_KM": "Distance (km/liter)",
            "REPORT_TITLE_DATERANGE": "ช่วงวันที่ {{date}}",
            "REPORT_TITLE_DEVICE": "ทะเบียน {{device}}",
            "REPORT_TITLE_DEVICE_ALL": "ทะเบียนทั้งหมด",
            "REPORT_TITLE_DEVICE_GROUP": "กลุ่ม {{group}}",
            "REPORT_VIEW_DEVICE": "Show Device",
            "REPORT_VIEW_HEADER": "Show Head",
            "REPORT_VIEW_TOTAL": "Show Total",
            "STATE_ABSENCE": "ลา",
            "STATE_BAD": "Bad",
            "STATE_DONE": "ดำเนินการแล้ว",
            "STATE_GOOD": "Good",
            "STATE_MODERATE": "Moderate",
            "STATE_PENDING": "รอดำเนินการ",
            "STATE_STRIKE": "หยุุด",
            "STATE_WAIT": "รอ",
            "STATE_WORK": "งาน",
            "TEXT_ACCESSTIME": "Last used",
            "TEXT_ADD_POINT": "Destination",
            "TEXT_ADDRESS": "Address",
            "TEXT_ALEART_MONEY_INPUT": "Please specify the amount to receive.",
            "TEXT_ALERT_SELECT_DEVICE": "Please select a car",
            "TEXT_ALERT_SELECT_DRIVER": "Please the driver",
            "TEXT_ALERT_SELECT_NOTIFY": "Please select notification.",
            "TEXT_ALERT_TIME_ERROR": "The time is wrong",
            "TEXT_AMOUNT": "Amount",
            "TEXT_AMOUNT_LITER": "Amount",
            "TEXT_APPLY": "Apply",
            "TEXT_AREA": "Area",
            "TEXT_ATTACHMENT": "Attachment",
            "TEXT_ATTACHMENT_IMAGE": "Attachment Image",
            "TEXT_AVG": "Avg",
            "TEXT_BIRTHDAY": "Birthday",
            "TEXT_CLEAR": "Clear",
            "TEXT_CLEAR_OLD": "Delete the original data first.",
            "TEXT_CLEAR_SIGNATURE": "Delete signature",
            "TEXT_COMPANY": "Company",
            "TEXT_COPY": "Copy",
            "TEXT_COPY_FROM": "From",
            "TEXT_COPY_TO": "To",
            "TEXT_COST": "Cost",
            "TEXT_COUNT_DUPLICATE": "ซ้ำ {{count}} รายการ",
            "TEXT_COUNT_FOUND": "พบแล้ว {{count}} รายการ",
            "TEXT_COUNT_NOIMPORT": "นำเข้าไม่ได้ {{count}} รายการ",
            "TEXT_COUNT_SELECT": "เลือกแล้ว {{count}} รายการ",
            "TEXT_COUNT_TOTAL": "Total {{count}} Each(s)",
            "TEXT_CREATETIME": "Create Time",
            "TEXT_CREDIT": "Credit",
            "TEXT_CUSTOMER": "Customer",
            "TEXT_DATA": "Data",
            "TEXT_DATE": "Date",
            "TEXT_DATE_END": "Date end",
            "TEXT_DATE_FROM": "Date from",
            "TEXT_DATE_IN": "Entry date",
            "TEXT_DATE_INSTALL": "installed date",
            "TEXT_DATE_OUT": "Issue date",
            "TEXT_DATERANGE": "date duration",
            "TEXT_DATE_START": "Date start",
            "TEXT_DATE_TO": "to",
            "TEXT_DEFAULT": "Default",
            "TEXT_DELAY": "Delay",
            "TEXT_DESC_DISTANCE": "Arrange from farthest first.",
            "TEXT_DETAIL": "Details",
            "TEXT_DEVICE": "Device",
            "TEXT_DEVICE_24H_DURATION": "Use 24 hours.",
            "TEXT_DEVICE_COUNT": "Device",
            "TEXT_DEVICE_FROM": "From Device",
            "TEXT_DEVICE_GROUP": "Group",
            "TEXT_DEVICE_PARK_DURATION": "Parking time",
            "TEXT_DEVICE_RUN_DURATION": "driving period",
            "TEXT_DISABLE": "Disable",
            "TEXT_DISTANCE": "Distance (Km.)",
            "TEXT_DISTANCE_S": "Dist",
            "TEXT_DLT": "DLT",
            "TEXT_DOWNLOAD": "Download",
            "TEXT_DRIVER": "Driver",
            "TEXT_DRIVER_2": "Driver 2",
            "TEXT_DRIVER_CARD": "Card",
            "TEXT_DRIVER_SCORE": "Driver Rating",
            "TEXT_DURATION": "Duration",
            "TEXT_EMAIL": "E-mail",
            "TEXT_EMPLOYEE": "employee",
            "TEXT_ENABLE": "Activate",
            "TEXT_ENGINE_OFF": "ENGINE OFF",
            "TEXT_ENGINE_ON": "ENGINE ON",
            "TEXT_ENGINE_TIME": "Engine Time",
            "TEXT_EVENT": "Event",
            "TEXT_EXCEPT": "Except",
            "TEXT_EXPIRE": "Expire",
            "TEXT_EXPIRE_NEAR": "Expire",
            "TEXT_EXPORT": "export data",
            "TEXT_FIXPOINT": "Specify coordinates",
            "TEXT_FLASHVIEW_CODE": "View code",
            "TEXT_FROM": "From",
            "TEXT_FROM_TIME": "from {{date,St}}",
            "TEXT_FUELADD": "Amount of oil added (liters)",
            "TEXT_FUELAVG_ENGINE": "Working hours (liters/hour)",
            "TEXT_FUELAVG_KM": "Distance (km/liter)",
            "TEXT_FUELMONEY": "ค่าน้ำมันที่ใช้/บาท",
            "TEXT_FUELPRICE": "ราคาน้ำมัน",
            "TEXT_FUEL_REMAIN": "Remaining amount of oil",
            "TEXT_FUELUSE": "fuel usage/Liter",
            "TEXT_FUELUSE_C": "Consumption rate (km/liter)",
            "TEXT_FUELUSE_S": "fuel (Liter)",
            "TEXT_FUEL_VOLUME": "Oil quantity",
            "TEXT_FULLNAME": "name - surname",
            "TEXT_GENDER": "Gender",
            "TEXT_GPS": "GPS",
            "TEXT_GROUP": "group",
            "TEXT_GSM": "GSM",
            "TEXT_HEIGHT": "Height",
            "TEXT_HOWLONG": "long",
            "TEXT_IMPORT": "import data",
            "TEXT_INDEX": "No.",
            "TEXT_IP": "IP",
            "TEXT_LABEL": "Label",
            "TEXT_LATITUDE": "Latitude",
            "TEXT_LENGTH": "Length",
            "TEXT_LINE": "Line",
            "TEXT_LIST": "List",
            "TEXT_LOCATION": "Location",
            "TEXT_LOCATION_POINT": "Location",
            "TEXT_LOCATION_SHOW": "Show all Location",
            "TEXT_LOCATION_START": "Start station",
            "TEXT_LOGO": "Logo",
            "TEXT_LONGITUDE": "Longitude",
            "TEXT_MAX_SPEED": "maximum speed",
            "TEXT_MAX_SPEED_TIME": "Maximum speed time",
            "TEXT_MILEAGE": "Mileage",
            "TEXT_MOBILE": "อุปกรณ์",
            "TEXT_MONEY": "Amount",
            "TEXT_MSISDN": "Sim No.",
            "TEXT_NAME": "Name",
            "TEXT_NAMELAST": "Surname",
            "TEXT_NATION": "Country",
            "TEXT_NOCARD_COUNT": "ไม่ได้รูดบัตร {{count}} ครั้ง",
            "TEXT_NOGPS": "no gps (hour)",
            "TEXT_NOGROUP": "without group",
            "TEXT_NONE": "None",
            "TEXT_NONE_YET": "No data",
            "TEXT_NOSHOW": "Don't show it again.",
            "TEXT_NOSHOW_DAY": "No need to show again today",
            "TEXT_NOTE": "Note:",
            "TEXT_NOTIFY": "Tell by",
            "TEXT_NOTIFY_AND_SPEEDLIMIT": "and more speed",
            "TEXT_NOTIFY_CHANNEL": "Notification channels",
            "TEXT_NOTIFY_CHANNEL_MAIN": "Main channels",
            "TEXT_NOTIFY_LAST": "Latest alert",
            "TEXT_NOUPDATE": "device stop (hour)",
            "TEXT_NUMBER": "No.",
            "TEXT_ONTIME": "When",
            "TEXT_ORDINAL": "Ordinal",
            "TEXT_PASSWORD": "Password",
            "TEXT_PASSWORD_CONFIRM": "recent password",
            "TEXT_PAYMENT": "How to save money",
            "TEXT_PERMIT_DATE": "Allowed date",
            "TEXT_PLACE": "Place",
            "TEXT_PLACE_NEAR": "Near",
            "TEXT_PLAYBACK_COMPARE": "Compare previous data",
            "TEXT_POI": "POI",
            "TEXT_POI_BASE": "POI / กลุ่มรถ",
            "TEXT_POI_GROUP_READ": "Define groups of visible POIs",
            "TEXT_POI_NOBASE": "Not showing own POI/car group",
            "TEXT_POI_NOENTRY": "number that visit no entry station",
            "TEXT_POI_NOIN": "Doesn't show cars parked in POI",
            "TEXT_POINT_END": "Destination",
            "TEXT_POINT_START": "Started",
            "TEXT_POI_STATION": "number that visit station",
            "TEXT_PORT": "import/export",
            "TEXT_PRICE": "Price",
            "TEXT_PROBLEM_NOTIFY": "Problem notification",
            "TEXT_RADIUS": "Radius",
            "TEXT_RAWDATA": "Actual value",
            "TEXT_REMAIN": "remaining",
            "TEXT_RESET": "Reset Value",
            "TEXT_RESULT": "Work result",
            "TEXT_ROUTE": "route",
            "TEXT_ROWNUM": "No.",
            "TEXT_SEARCH": "Search",
            "TEXT_SENDED": "Sent",
            "TEXT_SHOW_PARKING": "Show stops",
            "TEXT_SIGNATURE": "Signature",
            "TEXT_SIGNATURE_CUSTOMER": "Customer signature",
            "TEXT_SIGNATURE_USER": "Employee signature",
            "TEXT_SMS": "SMS",
            "TEXT_SORT": "Sort",
            "TEXT_SPEED": "Speed",
            "TEXT_SPEED_S": "Spd",
            "TEXT_STATE": "Current status",
            "TEXT_STATUS_ENGINE": "engine status",
            "TEXT_STATUS_RUN": "running status",
            "TEXT_STATUS": "Status",
            "TEXT_STEP": "Step",
            "TEXT_TELEPHONE": "Telephone",
            "TEXT_TIME": "Time",
            "TEXT_TIME_AT": "Information at {{time}}",
            "TEXT_TIME_END": "Exit time",
            "TEXT_TIME_IN": "Entry time",
            "TEXT_TIME_OUT": "Exit time",
            "TEXT_TIME_START": "Enter Time",
            "TEXT_TIME_STAY": "Time",
            "TEXT_TITLE": "Title",
            "TEXT_TO": "To",
            "TEXT_TOTAL": "Total",
            "TEXT_TOTAL_ALL": "Total All",
            "TEXT_TOTAL_DURATION": "total usage time",
            "TEXT_TOTAL_PARK": "air parking time",
            "TEXT_TOTAL_PARK10": "air parking over 10 min.",
            "TEXT_TOTAL_PARK_MAX": "longest starting time",
            "TEXT_TOTAL_PARK_MAX_DATE": "Longest idle time",
            "TEXT_TOTAL_SPEED": "over speed time",
            "TEXT_TYPE": "Type",
            "TEXT_UPDATETIME": "Last Update",
            "TEXT_UPLOAD": "Upload",
            "TEXT_USE_DAY": "Use per Day",
            "TEXT_USER": "User",
            "TEXT_USERAGENT": "Agent",
            "TEXT_USERNAME": "username",
            "TEXT_WAYPOINT": "Waypoint",
            "TEXT_WHEN": "When",
            "TEXT_WIDTH": "Width",
            "TEXT_WORK": "Work",
            "TMS_INFO_DUP_DEVICE": "Cars are still being used during this time.",
            "TMS_INFO_DUP_EMPLOYEE": "This driver already has a job. During this time",
            "TMS_INFO_DUP_EMPLOYEE2": "There is a work period that coincides with the employee's leave day.",
            "TMS_MENU_CALENDAR": "Calendar",
            "TMS_MENU_LIST": "Schedule",
            "TMS_MENU_SEARCH": "Search",
            "TMS_MONEY_BILL": "Bill",
            "TMS_MONEY_CASH": "Cash",
            "TMS_MONEY_NO": "No.",
            "TMS_MONEY_PENDING": "Pending",
            "TMS_MONEY_TRANSFER": "Transfer",
            "ONLY_AREA_IN": "Only in the area",
            "ONLY_AREA_OUT": "Only outside the area",
            "ONLY_POI_IN": "Only in POI",
            "ONLY_POI_OUT": "Only outside POI",
            "TRACKING_DEVICE_CARD": "Driver Card",
            "TRACKING_DEVICE_SUSPEND_BODY": "The following devices are going to expire or already meet expire date. Please contact us to extend our service otherwise, the system will automatically delete the devices.",
            "TRACKING_DEVICE_SUSPEND_HEAD": "Device expiring alert",
            "TRACKING_MENU_ALERT": "Alert",
            "TRACKING_MENU_AREA": "Area",
            "TRACKING_MENU_COMMAND": "Command",
            "TRACKING_MENU_DEVICE": "Device Info",
            "TRACKING_MENU_NEAR": "near",
            "TRACKING_MENU_NOW": "Real Time",
            "TRACKING_MENU_PLAYBACK": "Playback",
            "TRACKING_MENU_POI": "POI",
            "TRACKING_PLAYBACK_VAD_NO": "Driving record not found",
            "TRACKING_POI_INFO_ICON": "Icon",
            "TRACKING_STATUS_FAST": "Fast",
            "TRACKING_STATUS_OFF": "Off",
            "TRACKING_STATUS_PARK": "Park",
            "TRACKING_STATUS_RUN": "Run",
            "TRACKING_STATUS_STOP": "Stop",
            "TRACKING_STATUS_TOWER": "Antenna",
            "UNIT_BATH": "Baht",
            "UNIT_BPK": "Baht/KM",
            "UNIT_BPL": "Baht/Lt",
            "UNIT_COUNT": "Times",
            "UNIT_DAY": "Day",
            "UNIT_HOUR": "Hour",
            "UNIT_KM": "KM",
            "UNIT_KMPH": "Km/Hr",
            "UNIT_KMPL": "Km/Lt",
            "UNIT_LITER": "liter",
            "UNIT_LPH": "Lt/Hr",
            "UNIT_METRE": "M",
            "UNIT_MINUTE": "minute",
            "UNIT_MONTH": "month",
            "UNIT_SECOND": "Sec.",
            "UNITTEXT_NUMBER": "รายการที่ {{number}}",
            "USER_API_ADD": "Create a new Key",
            "USER_API_EMPTY": "An API Key has not been created yet.",
            "USER_DEVICE_FIELD_CHART": "Fuel graph",
            "USER_DEVICE_FIELD_CHART_HELP": "highlight to cover minimum and maximum of graph",
            "USER_DEVICE_FIELD_MAX": "maximum time period",
            "USER_DEVICE_FIELD_MIN": "minimum time period",
            "USER_DEVICE_FIELD_REVERSE": "alternate minimum and maximum",
            "USER_DEVICE_FIELD_TELEPHONE": "Mobile",
            "USER_DEVICE_FIELD_TRACKER": "Tracker model",
            "USER_DEVICE_FORM_EDIT_HEAD": "Change device details",
            "USER_DEVICE_FORM_MARKER_HEAD": "Marker setting",
            "USER_DEVICE_FORM_OIL_HEAD": "Set fuel graph",
            "USER_DEVICE_TABLE_ALERT_WHEN": "When",
            "USER_DRIVER_VAD_EMPTY": "Please insert driver name",
            "USER_FORM_ADD_HEAD": "Add",
            "USER_FORM_SUMMARY_SEND": "Send report daily",
            "USER_INFO_CHECKPOINT": "เครดิต CheckPoint",
            "USER_INFO_SMS": "SMS remain",
            "USER_MENU_API": "Manage API",
            "USER_MENU_AREA": "Area",
            "USER_MENU_CHECKPOINT": "Checkpoint",
            "USER_MENU_COMMAND": "Send Command",
            "USER_MENU_COMPANY": "Company",
            "USER_MENU_CUSTOMER": "Customer",
            "USER_MENU_DASHBOARD": "Dashboard",
            "USER_MENU_DEVICE": "Devices",
            "USER_MENU_EMPLOYEE": "Employee",
            "USER_MENU_EMPLOYEE_DAY": "Leave Day",
            "USER_MENU_FLASHVIEW": "temporary account",
            "USER_MENU_FUEL": "Fuel",
            "USER_MENU_LOG": "Usage history",
            "USER_MENU_LOG_CHECKPOINT": "ประวัติการใช้เครดิต CheckPoint",
            "USER_MENU_LOG_SIGN": "login history",
            "USER_MENU_LOG_SMS": "SMS Log",
            "USER_MENU_MOBILE": "Mobile",
            "USER_MENU_NOTIFICATION": "Notification",
            "USER_MENU_POI": "POI",
            "USER_MENU_PROFILE": "User Data",
            "USER_MENU_REPORT": "Report",
            "USER_MENU_SUBUSER": "Sub-user",
            "USER_NOTIFY_AD": "Value from sensor",
            "USER_NOTIFY_AREA": "area",
            "USER_NOTIFY_DRIVER_CARD": "card swiping",
            "USER_NOTIFY_EVENT": "event",
            "USER_NOTIFY_PARK_LIMIT": "Parking",
            "USER_NOTIFY_POI": "POI",
            "USER_NOTIFY_SPEED_LIMIT": "over speed",
            "USER_NOTIFY_STATE": "Machine status",
            "USER_POI_FIELD_EMAIL": "email alert",
            "USER_POI_FIELD_IN": "entering alert",
            "USER_POI_FIELD_OUT": "exiting alert",
            "USER_POI_FIELD_SM": "small icon",
            "USER_POI_LOCATION": "location",
            "USER_PROFILE_FAIL_PASS_CONFIRM": "Recent password incorrect",
            "USER_PROFILE_FAIL_PASS_LENGTH": "The new password is too short.",
            "USER_PROFILE_FAIL_PASS_RE": "Re-type Password incorrect",
            "USER_PROFILE_FAIL_USER_DUP": "Username is duplicated.",
            "USER_PROFILE_FORM_PASSWORD_HEAD": "Changing Password",
            "USER_SETTING_TAGS_NOTE": "Use Enter to add a new label.",
            "USER_SETTING_TMSPAY": "Payment channels",
            "USER_TEXT_DOCUMENT_GOV": "Official documents",
            "USER_TEXT_EMAIL_NOTE": "insert more than 1 email, please separated by ,",
            "USER_TEXT_IDCARD": "ID number",
            "USER_TEXT_LICENCE_GOV": "Issue at",
            "USER_TEXT_LICENCE_NUMBER": "Driver license number",
            "USER_TEXT_LICENCE_TYPE": "License type",
            "USER_TEXT_PASSWORD_NOTE": "If you don't want to change your password, don't enter it.",
            "USER_TEXT_PREFIX": "Title",
            "USER_TEXT_REGISTRAR": "Registrar",
            "USER_TEXT_REPASSWORD": "Confirm password"
        }
    },
    "ja": {
        "translation": {
            "TEST_TEXT": "コマンド例",
            "API_NAME_DEVICE_ALL": "アカウント内のすべての車のリストをリクエストする",
            "API_NAME_DEVICE_GET": "IDから現在の車両ステータスを取得する",
            "API_NAME_DEVICE_LOG": "運転履歴を見る",
            "API_NAME_POI_ALL": "アカウント内のすべての POI のリストをリクエストする",
            "API_NAME_POI_GET": "IDからPOIをリクエスト",
            "API_NAME_REPORT_LENGTH": "走行距離レポート",
            "API_NAME_REPORT_RS232": "カードスワイプレポート",
            "API_NAME_REPORT_WORKLOAD": "労働時間を報告する",
            "API_NAME_VERSION": "APIのバージョン番号",
            "API_PARAM_ADDRESS": "アドレスを表示（アドレスを表示するには1を入力します。レポートの出力効率が低下します）",
            "API_PARAM_DEVICE": "デバイス番号",
            "API_PARAM_END": "終了時間",
            "API_PARAM_LIMIT": "必要なアイテムの数",
            "API_PARAM_ORDER": "仕分け",
            "API_PARAM_START": "開始時間",
            "API_RESP_DEVICES": "車のリスト",
            "API_RESP_DEVICES_ID": "車両ID番号",
            "API_RESP_OK": "APIの動作状況",
            "API_RESP_VERSION": "APIのバージョン番号",
            "APP_FINGERPRINT_SCAN": "指紋をスキャンして権限を確認します",
            "APP_PIN": "PINコードを入力してください",
            "APP_PIN_CONFIRM": "PINコードを確認する",
            "APP_PIN_ERROR": "PINコードが間違っています。",
            "APP_PIN_ERROR_NOTMATCH": "PINコードが一致しません",
            "APP_PIN_ERROR_NOTOLD": "新しい PIN は古い PIN と同じであってはなりません。",
            "APP_PIN_NEW": "新しいPINコードを入力してください。",
            "APP_PIN_OLD": "古いPINコードを入力してください。",
            "APP_SETTING_APIMOON": "バックアップサーバー",
            "APP_SETTING_BETA": "use Beta",
            "APP_SETTING_DARK": "ナイトモード",
            "APP_SETTING_FINGERPRINT": "指紋スキャナー",
            "APP_SETTING_PIN": "PINコードを設定する",
            "APP_SETTING_PIN_CHANGE": "PINコードを変更する",
            "APP_SETTING_PIN_DELETE": "PINの削除",
            "APP_SETTING_PIN_ON": "PINロック画面をONにします。",
            "BUTTON_ADD": "追加",
            "BUTTON_GEN_AREA": "エリアの作成",
            "BUTTON_ADD_EMP_DAY": "休暇を追加",
            "BUTTON_CANCEL": "キャンセル",
            "BUTTON_CHECK": "情報を確認する",
            "BUTTON_CLEAR": "Area",
            "BUTTON_COMMAND_SEND": "注文の送信を確認する",
            "BUTTON_CONFIRM": "確認",
            "BUTTON_CONFIRM_PRIVACY": "受け入れる",
            "BUTTON_DELETE": "削除",
            "BUTTON_DOWNLOAD_CERT": "証明書",
            "BUTTON_EDIT": "編集",
            "BUTTON_HIDE": "隠れる",
            "BUTTON_LOGIN": "ログイン",
            "BUTTON_LOGOUT": "ログアウト",
            "BUTTON_MONEY_RECEIVE": "お金を得る",
            "BUTTON_MONEY_UNRECEIVE": "お金を受け取っていない",
            "BUTTON_NOTIFY": "通知",
            "BUTTON_NOTIFY_SETTING": "通知の設定",
            "BUTTON_OK": "はい",
            "BUTTON_PORT": "インポート/エクスポート",
            "BUTTON_PRINT": "印刷",
            "BUTTON_RESET": "再起動",
            "BUTTON_RUN": "実行する",
            "BUTTON_SAVE": "データ保存",
            "BUTTON_SAVE_SORT": "ソート記録",
            "BUTTON_SEARCH": "検索",
            "BUTTON_SKIP": "スキップ",
            "BUTTON_TEMPLATE_LOAD": "テンプレートから呼び出す",
            "BUTTON_TEMPLATE_SAVE": "テンプレートに保存",
            "BUTTON_UNHIDE": "見せる",
            "BUTTON_UPDATE_APP": "アップデート",
            "BUTTON_UPLOAD": "アップロード",
            "CONFIRM_DELETE": "削除しますか？",
            "CONFIRM_DELETE_SELECT": "選択したデータの削除を確認します。",
            "CONFIRM_IMPORT": "データインポートを確認する",
            "CONFIRM_LOGOUT": "Logout ?",
            "CONFIRM_MONEY_RECEIVE": "入金確認!?",
            "CONFIRM_MONEY_UNRECEIVE": "入金をキャンセル!?",
            "CONFIRM_RESET": "リセットを確認!?",
            "DALERT_COMMAND_SEND_ERROR": "データを送信できませんでした。もう一度やり直してください。",
            "DALERT_DELETE_FAIL": "データを削除できません",
            "DALERT_EDIT_NOTFOUND": "更新するデータが見つかりませんでした",
            "DALERT_HISTORY_EMPTY": "遡及的な歴史は見つかりませんでした。 :",
            "DALERT_HISTORY_NOMOVE": "車が動かない",
            "DALERT_MUST_DEVICE": "車を選ばなければなりません",
            "DALERT_REPORT_EMPTY": "レポートデータが見つかりません",
            "DALERT_SAVE_FAIL": "データ保存不可です",
            "DALERT_USERNAME_DUP": "このユーザー名はすでにシステム内にあります。",
            "DATE_DAY_ALL": "一日中",
            "DATE_DAY_0": "日",
            "DATE_DAY_1": "月",
            "DATE_DAY_2": "火",
            "DATE_DAY_3": "水",
            "DATE_DAY_4": "木",
            "DATE_DAY_5": "金",
            "DATE_DAY_6": "土",
            "DATE_DAY_SHORT_0": "日",
            "DATE_DAY_SHORT_1": "月",
            "DATE_DAY_SHORT_2": "火",
            "DATE_DAY_SHORT_3": "水",
            "DATE_DAY_SHORT_4": "木",
            "DATE_DAY_SHORT_5": "金",
            "DATE_DAY_SHORT_6": "土",
            "DATE_MONTH_1": "１月",
            "DATE_MONTH_2": "２月",
            "DATE_MONTH_3": "３月",
            "DATE_MONTH_4": "４月",
            "DATE_MONTH_5": "５月",
            "DATE_MONTH_6": "６月",
            "DATE_MONTH_7": "７月",
            "DATE_MONTH_8": "８月",
            "DATE_MONTH_9": "９月",
            "DATE_MONTH_10": "１０月",
            "DATE_MONTH_11": "１１月",
            "DATE_MONTH_12": "１２月",
            "DATE_MONTH_LAST": "先月",
            "DATE_MONTH_SHORT_1": "１月",
            "DATE_MONTH_SHORT_2": "２月",
            "DATE_MONTH_SHORT_3": "３月",
            "DATE_MONTH_SHORT_4": "４月",
            "DATE_MONTH_SHORT_5": "５月",
            "DATE_MONTH_SHORT_6": "６月",
            "DATE_MONTH_SHORT_7": "７月",
            "DATE_MONTH_SHORT_8": "８月",
            "DATE_MONTH_SHORT_9": "９月",
            "DATE_MONTH_SHORT_10": "１０月",
            "DATE_MONTH_SHORT_11": "１１月",
            "DATE_MONTH_SHORT_12": "１２月",
            "DATE_MONTH_THIS": "今月",
            "DATE_S": "{{date,S}}",
            "DATE_ST": "{{date,St}}",
            "DATE_TODAY": "今日",
            "DATE_TRACK": "{{date,d MMM HH:mm}}",
            "DATE_YESTERDAY": "昨日",
            "DCONFIRM_CHANGE_LANGUAGE": "言語を変更したい",
            "DCONFIRM_LOGOUT": "ログアウトしたい",
            "DCONFIRM_SEND_LOG": "データ提出の確認",
            "TEXT_ALL_PLAY": "すべてのカメラ画像を再生する",
            "TEXT_ALL_CLOSE": "すべて閉まっている",
            "TEXT_ALL_PAUSE": "すべての画像の再生を停止します。",
            "DEVICE_COMMAND": "Command",
            "DEVICE_COMMAND_CRONJOB": "作業時間を設定する",
            "DEVICE_COMMAND_RESPOND": "結果",
            "DEVICE_COMMAND_RESPTIME": "応答時間",
            "DEVICE_COMMAND_SEND": "コマンド送信",
            "DEVICE_COMMAND_SENDING": "กำลังจะส่งคำสั่ง",
            "DEVICE_COMMAND_SENDTIME": "送信時間",
            "DEVICE_DVR_CHANNEL": "กล้องตัวที่ {{channel}}",
            "DEVICE_MAXVOLUME": "最大積載量（立方メートル）",
            "DEVICE_MAXWEIGHT": "運べる最大重量",
            "DEVICE_NOTIFY_AD": "On {{ad}} {{to}} {{value}}",
            "DEVICE_NOTIFY_AREA_LOCATION": "指定されたエリア",
            "DEVICE_NOTIFY_DLT": "แจ้งเตือนกรมขนส่ง",
            "DEVICE_NOTIFY_NOCARD_DURATION": "{{duration}} 分間、カードをスワイプせずにデバイスを実行します。",
            "DEVICE_NOTIFY_PARK_DURATION": "エンジンを {{duration}} 分間停止します。",
            "DEVICE_NOTIFY_POIALL": "All Poi",
            "DEVICE_NOTIFY_POI_IN": "เมื่อเข้า {{poi}}",
            "DEVICE_NOTIFY_POI_OUT": "เมื่อออกจาก {{poi}}",
            "DEVICE_NOTIFY_POISTOP_IN": "{{duration}} 分 以上の POI に駐車",
            "DEVICE_NOTIFY_RS232_NO": "ติดเครื่อง แต่ไม่รูดบัตร นานเกิน {{duration}} นาที",
            "DEVICE_NOTIFY_SPEEDLIMIT": "速度が制限を超えたとき",
            "DEVICE_NOTIFY_STATE": "On {{state}} change to {{to}}",
            "DEVICE_TEXT_FUELUSE": "燃料使用レート",
            "DEVICE_TEXT_FUELVOLUME": "燃料タンク容積",
            "DEVICE_TEXT_MODEL": "車種 ",
            "DEVICE_TEXT_NOTIFY": "お知らせ",
            "DEVICE_TEXT_SPEEDLIMIT": "制限速度",
            "DIALOG_ADD": "情報を追加する",
            "DIALOG_LANGUAGE": "言語を変更する",
            "EVENT_1": "Engine & Input 1 Active",
            "EVENT_10": "Engine & Input 2 Inactive",
            "EVENT_109": "Taxi Meter Event",
            "EVENT_11": "Engine & Input 3 Inactive",
            "EVENT_1101": "#VALUE!",
            "EVENT_1102": "Leave POI",
            "EVENT_12": "Engine & Input 4 Inactive",
            "EVENT_1201": "10分以上駐車した場合",
            "EVENT_1202": "デバイスの電源を入れますが、カードを 10 分以上スワイプしないでください。",
            "EVENT_129": "Harsh Braking",
            "EVENT_13": "Engine & Input 5 Inactive",
            "EVENT_130": "Harsh Acceleration",
            "EVENT_133": "Idle Overtime",
            "EVENT_134": "Idle Recovery",
            "EVENT_135": "Fatigue Driving",
            "EVENT_136": "Enough Rest after Fatigue Driving",
            "EVENT_138": "Speed Recovery",
            "EVENT_17": "バッテリー低下",
            "EVENT_18": "外部電顕の低下",
            "EVENT_19": "速度超過",
            "EVENT_2": "Engine & Input 2 Active",
            "EVENT_20": "ジオフェンスに入る",
            "EVENT_21": "ジオフェンスを出る",
            "EVENT_22": "外部パワーオン",
            "EVENT_23": "外部パワーオフ",
            "EVENT_24": "GPS信号なし",
            "EVENT_25": "GPS信号を取得する",
            "EVENT_26": "スリープモード",
            "EVENT_27": "スリープモードオフ",
            "EVENT_28": "GPS触角カット",
            "EVENT_29": "デバイスの再起動",
            "EVENT_3": "Engine & Input 3 Active",
            "EVENT_30": "インパクト",
            "EVENT_31": "心臓の鼓動レポート",
            "EVENT_32": "方向変化レポート",
            "EVENT_33": "距離間隔レポート",
            "EVENT_34": "現在地レポート",
            "EVENT_35": "時間間隔レポート",
            "EVENT_36": "レッカー移動アラーム",
            "EVENT_37": "RFID",
            "EVENT_39": "写真",
            "EVENT_4": "Engine & Input 4 Active",
            "EVENT_40": "電源オフボタン",
            "EVENT_41": "移動停止",
            "EVENT_42": "Start Moving",
            "EVENT_44": "GSM Jamming",
            "EVENT_5": "Engine & Input 5 Active",
            "EVENT_50": "Temperature High",
            "EVENT_51": "Temperature Low",
            "EVENT_52": "Full Fuel",
            "EVENT_53": "Low Fuel",
            "EVENT_54": "Fuel Theft",
            "EVENT_56": "Armed (T36x)",
            "EVENT_57": "Disarmed (T36x)",
            "EVENT_58": "Vehicle Theft (T36x)",
            "EVENT_63": "No GSM Jamming",
            "EVENT_65": "Press Input 1 (SOS) to Call",
            "EVENT_66": "Press input 2 to Call",
            "EVENT_67": "Press input 3 to Call",
            "EVENT_68": "Press input 4 to Call",
            "EVENT_69": "Press input 5 to Call",
            "EVENT_70": "Reject Incoming Call",
            "EVENT_71": "Report Location by Call",
            "EVENT_72": "Auto Answer Incoming Call",
            "EVENT_73": "Listen-in (voice monitoring)",
            "EVENT_78": "Impact",
            "EVENT_82": "Full Fuelling",
            "EVENT_83": "Ult-Sensor Drop",
            "EVENT_86": "Swipe Card to Leave",
            "EVENT_9": "Engine & Input 1 Inactive",
            "EVENT_90": "Sharp Turn to Left",
            "EVENT_90_B": "Sharp Turn",
            "EVENT_91": "Sharp Turn to Right",
            "EVENT_HIK_643": "GPS Data Report",
            "EVENT_HIK_644": "Front Vehicle Collision Warning",
            "EVENT_HIK_645": "Short Vehicle Distance Warning",
            "EVENT_HIK_646": "Lane Deviation Warning",
            "EVENT_HIK_647": "Pedestrian Collision Warning",
            "EVENT_HIK_648": "Overspeed Warning",
            "EVENT_HIK_649": "Blind Spot Detection Warning",
            "EVENT_HIK_650": "Smoking",
            "EVENT_HIK_651": "On the Phone",
            "EVENT_HIK_652": "Fatigue Driving",
            "EVENT_HIK_653": "Distracted Driving",
            "EVENT_HIK_654": "Not Buckled Up",
            "EVENT_HIK_655": "Video Tampering",
            "EVENT_HIK_656": "Panic Button Alarm",
            "EVENT_HIK_657": "Sharp Turn",
            "EVENT_HIK_658": "Sudden Brake",
            "EVENT_HIK_659": "Sudden Brake",
            "EVENT_HIK_660": "Rollover",
            "EVENT_HIK_661": "Overspeed",
            "EVENT_HIK_662": "Collision",
            "EVENT_HIK_663": "Enter Fence",
            "EVENT_HIK_664": "Deviation",
            "EVENT_HIK_665": "Exit Fence",
            "FAB_BOUND": "全範囲",
            "FAB_GO_LOCATION": "現在位置",
            "FAB_MAP_SAT": "衛星地図",
            "FAB_MAP_TRAFFIC": "トラフィック",
            "FAB_POI_SHOW": "POI を表示",
            "FAB_REFRESH": "リフレッシュ",
            "FORM_CHECK_ALL": "すべて選択",
            "FORM_CHECK_NONE": "何も選択しない",
            "FORM_SELECT": "ー 選ぶ －",
            "FORM_SELECT_ALL": "ー すべて ー",
            "FORM_SELECT_NONE": "ー なし －",
            "GROUPBY_DAY": "日によって",
            "GROUPBY_MONTH": "月ごとに",
            "GROUPBY_WEEK": "週ごとに",
            "INFO_CLOSEAPP": "もう一度BACKボタンを押してアプリケーションを閉じます。",
            "INFO_COMMAND_RESPONDED": "サーバーが応答しました",
            "INFO_COMMAND_RESPOND_TEXT": "応答状況",
            "INFO_COMMAND_SENDED": "送信完了",
            "INFO_COMMAND_SENDING": "データ送信中",
            "INFO_COMMAND_STATUS": "送信ステータス",
            "INFO_COMMAND_WAIT": "返信...",
            "INFO_DATE_REQ": "最初に日付を選択してください。",
            "INFO_DROPED": "削除完了です",
            "INFO_DROP_FAIL": "データ削除不可です",
            "INFO_DROP_NOTFOUND": "削除するデータが見つかりませんでした",
            "INFO_DVR_STATUS_FAIL": "DVR オフライン",
            "INFO_EXPIRE_DETAIL": "デバイスの有効期限が近日切れてしまいます,更新するため,ザービスセンターにご連絡ください",
            "INFO_EXPIRE_NOTIFY": "有効期限が近日切れてしまいます",
            "INFO_LOGOUT": "ログアウト",
            "INFO_MONEY_JOB": "仕事からの収入",
            "INFO_MONEY_RECEIVE": "受け取ったお金",
            "INFO_MONEY_UNRECEIVE": "お金はキャンセルされました。",
            "INFO_NOTFOUND": "データが見つかりませんでした",
            "INFO_NOTIFY_DUPLICATE": "この通知は受信されました。",
            "INFO_REPORT_WAIT": "レポートを生成中です。お待ちください。",
            "INFO_SAVED": "保存されたデータ",
            "INFO_SAVE_DATA_INCOMPLETE": "情報を入力してください。",
            "INFO_SAVE_FAIL": "データ保存不可です",
            "INFO_SAVE_NOTFOUND": "保存情報が見つかりません",
            "INPUT_POI_NAME": "あなたのPOIの場所",
            "LABEL_PIN_CREATE": "アプリケーションPINコードを設定する",
            "LOGIN_SUCCESS": "Login Success",
            "LOGIN_TITLE": "ログイン",
            "LOGIN_VALIDATE_FAIL": "ユーザー名,また　パスワードが不正です",
            "MA_MENU_HISTORY": "ประวัติ",
            "MA_MENU_LIST": "通知一覧",
            "MA_MENU_SERVICE": "Service",
            "MA_MENU_SUMMARY": "メンテナンスデータ",
            "MA_MENU_TEMPLATE": "テンプレート",
            "MA_MODE_DURATION": "走行期間",
            "MA_MODE_LENGTH": "走行距離",
            "MA_MODE_WORKTIME": "労働時間",
            "MA_NOTIFY": "{{device}} のメンテナンス {{name}} を決定する",
            "MA_TEXT_CURRENT": "現データ",
            "MA_TEXT_CURRENT_UNIT": "現データ {{value}} {{unit}}",
            "MA_TEXT_DATE_IN": "車を送り込んだ日",
            "MA_TEXT_DATE_OUT": "車の返却日",
            "MA_TEXT_GARAGE": "ガレージ",
            "MA_TEXT_INTERVAL": "毎＿＿に通知",
            "MA_TEXT_INTERVAL_UNIT": "毎＿＿に通知 {{value}} {{unit}}",
            "MA_TEXT_LIST": "修理リスト",
            "MA_TEXT_MODE": "「ー測定法ー」ではかる",
            "MA_TEXT_REMAIN": "「ー時間ー」に通知 {{value}} {{unit}}",
            "MENU_CONTROLROOM": "Control Room",
            "MENU_DEVICE": "車のリスト",
            "MENU_DISTANCE": "距離を測る",
            "MENU_HELP": "ヘルプ",
            "MENU_HISTORY": "振り返って",
            "MENU_LOGOUT": "サインアウト",
            "MENU_MA": "メンテナンス",
            "MENU_MAP": "マップ",
            "MENU_MOBILE": "Mobile",
            "MENU_MOBILE_DVR": "DVRカメラ",
            "MENU_MOBILE_INFO": "車の情報",
            "MENU_MOBILE_MAP": "地図",
            "MENU_MOBILE_NOTIFY": "通知",
            "MENU_MODULE": "システム",
            "MENU_MODULE_CHECKPOINT": "Checkpoint",
            "MENU_MODULE_MA": "メンテナンス",
            "MENU_MODULE_REVENUE": "運送収入",
            "MENU_MODULE_TMS": "フリートシステム",
            "MENU_MODULE_TMSROUTE": "TMS Route",
            "MENU_POI": "ピンポイント (POI)",
            "MENU_PRIVACY": "プライバシーポリシー",
            "MENU_REPORT": "報告する",
            "MENU_RESELLER": "Reseller",
            "MENU_SETTING": "設定",
            "MENU_TOOL": "道具",
            "MENU_TOOL_POI": "POIの距離を測定する",
            "MENU_TRACKING": "追跡",
            "MENU_TRACKING_TAXI": "タクシー追跡",
            "NOTE_FUEL_FULL": "給油トランザクションは正しく報告されます。毎回給油する場合は満タンにする必要があります。充填時間だけがわかります",
            "NOTE_FUEL_VOLUME": "オイルリストは正しくレポートされます。燃料タンクのサイズが正方形のみの場合",
            "NOTE_FUEL_VOLUME_REPORT": "ガソリンを追加するときは必ずエンジンを停止する必要があります。",
            "NOTE_PORTCLEAR": "すべてのデータが失われます。クリックする前にバックアップを作成してください。",
            "NOTIFY_AD": "{{device}} {{ad}} {{to}} {{value}} ({{current}})",
            "NOTIFY_AREA_IN": "{{device}} を入力 {{area}} {{address}}",
            "NOTIFY_AREA_OUT": "{{device}} 終了 {{area}} ({{duration}}) {{address}}",
            "NOTIFY_DLT_NOCARD": "{{device}}ドライバーは、10分以上エンジンの背面をスワイプしません。",
            "NOTIFY_DLT_OVERTIME": "{{device}} 4時間以上ドライブします。",
            "NOTIFY_DLT_STOP": "รถทะเบียน {{device}} หยุดการส่งข้อมูลไปยังกรมการขนส่งทางบกเกิน 7 วัน กรุณาตรวจสอบอุปกรณ์จีพีเอสให้ทำงานปกติ ก่อนนำรถไปใช้งาน หรือ ติดต่อ 02-111-7999 เพื่อขอรับการช่วยเหลือ",
            "NOTIFY_DRIVER_CARD": "{{device}} はマシンに固執しますが、カードを長い間スワイプしない {{minute}} 分",
            "NOTIFY_EVENT": "{{device}} {{event}} , {{poi}} {{address}}",
            "NOTIFY_LABEL_EMAIL": "電子メールで通知を送信する",
            "NOTIFY_LABEL_LINE": "Lineで通知を送る",
            "NOTIFY_LABEL_PUSH": "アプリ経由で通知を送信",
            "NOTIFY_LABEL_SMS": "SMS 経由で通知を送信する",
            "NOTIFY_PARK_LIMIT": "{{device}} 以上のためにマシンを駐車しました {{minute}} 分 , {{poi}} {{address}}",
            "NOTIFY_POI_IN": "{{device}} を入力 {{poi}} {{address}}",
            "NOTIFY_POI_OUT": "{{device}} 終了 {{poi}} {{address}}",
            "NOTIFY_POISTOP": "{{device}} poi の外の公園は {{time}} minutes {{address}} を超えています",
            "NOTIFY_SETTING": "Notification Setting",
            "NOTIFY_SPEED_LIMIT": "{{device}}ドライブ速すぎる ({{speed}} > {{speed_limit}}) , {{poi}} {{address}}",
            "NOTIFY_STATE": "{{device}} status {{state}} {{to}} , {{poi}} {{address}}",
            "NOTIFICATION_ENGINE_OFF": "エンジンを切ります",
            "NOTIFICATION_ENGINE_PARK": "エンジンを停めてください",
            "NOTIFICATION_ENGINE_RUN": "走る",
            "OPTION_APPROVE": "承認",
            "OPTION_DECREASE": "未満に減少",
            "OPTION_DENY": "不承認",
            "OPTION_ENABLE_CLUSTER": "Show Cluster marker",
            "OPTION_FILTER": "フィルター条件",
            "OPTION_GT": "より大きい",
            "OPTION_IN": "で",
            "OPTION_INOUT": "インとアウト",
            "OPTION_LT": "未満",
            "OPTION_MAN": "男性",
            "OPTION_OPSEVENT": "反対の立場",
            "OPTION_OUT": "Out",
            "OPTION_OVER_HOUR": "over {{hour}} hour",
            "OPTION_OVER_MINUTE": "over {{minute}} min",
            "OPTION_SHOW_CHECK": "Show Checkbox",
            "OPTION_SHOW_DEVICE": "Show Device In",
            "OPTION_SHOW_LABEL": "Show Label",
            "OPTION_SHOW_ROWNUM": "シリアル番号を表示",
            "OPTION_SHOW_SECOND": "Show Second",
            "OPTION_SHOW_STATUS": "Show Status",
            "OPTION_TOTAL": "全て",
            "OPTION_WAIT": "待つ",
            "OPTION_WOMAN": "女性",
            "OPTION_NARROW": "狭い",
            "OPTION_NORMAL": "普通",
            "OPTION_WIDE": "広い",
            "REPORT": "報告",
            "REPORT_ATTIME": "{{time}}で情報を報告する",
            "REPORT_DISPLAY_PLACE": "ショープレイス",
            "REPORT_DISPLAY_TABLE": "データテーブルを表示",
            "REPORT_DRIVER_SCORE_DAY": "1日あたりのポイントです。",
            "REPORT_DRIVER_SCORE_SETTING": "スコアを設定する",
            "REPORT_DUMP_OFF": "ダンプストップ",
            "REPORT_DUMP_ON": "ワークダンプ",
            "REPORT_FILE_AREA": "エリアの出入り",
            "REPORT_FILE_AREA_LENGTH": "エリア出入データ",
            "REPORT_FILE_CHART_CYCLE": "サイクルグラフ",
            "REPORT_FILE_CHART_FUELUSE": "フュエルグラフ",
            "REPORT_FILE_CHART_GASUSE": "ガソリングラフ",
            "REPORT_FILE_CHART_POWER": "電圧グラフ",
            "REPORT_FILE_CHART_SPEED": "速度グラフ",
            "REPORT_FILE_CHART_STATUS": "利用状況",
            "REPORT_FILE_CHART_TEMP": "温度グラフ",
            "REPORT_FILE_DRIVER_SCORE": "ドライバーの評価",
            "REPORT_FILE_DVR": "DVR",
            "REPORT_FILE_DVR_EVENT": "ADAS DBA Event",
            "REPORT_FILE_DVR_STATUS": "DVR Status",
            "REPORT_FILE_EVENT": "お知らせ",
            "REPORT_FILE_FUEL": "燃料使用量と距離を比較します。",
            "REPORT_FILE_FUELAVG": "平均燃費と走行距離",
            "REPORT_FILE_GROUP": "グループデータ",
            "REPORT_FILE_LENGTH": "距離データ",
            "REPORT_FILE_LENGTH_SUMMARY": "利用期間の概要",
            "REPORT_FILE_MA": "メンテナンス履歴",
            "REPORT_FILE_MA_SERVICE": "メンテナンスセンター入所履歴",
            "REPORT_FILE_PARKING": "駐車データ",
            "REPORT_FILE_PARKING_FREQ": "駐車データ",
            "REPORT_FILE_PHOTO": "デバイスからの画像",
            "REPORT_FILE_POI": "POI出入データ",
            "REPORT_FILE_POIOUT": "POI Out",
            "REPORT_FILE_REVENUE": "輸送による純利益",
            "REPORT_FILE_ROUTE": "ルートデータ",
            "REPORT_FILE_RS232": "card swipe",
            "REPORT_FILE_SPEED_GROUP": "速度周波数範囲",
            "REPORT_FILE_STATUS": "利用状況",
            "REPORT_FILE_SUMMARY": "要約データ",
            "REPORT_FILE_SUMMARY_STATUS": "利用状況の概要",
            "REPORT_FILE_WORKLOAD": "電源オンデータ",
            "REPORT_FILE_WORKLOAD_MONTH": "ワークロード月レポート",
            "REPORT_FILE_DLT_LOGSUM": "運輸省の異常な座標",
            "REPORT_GROUP_CHART": "グラフ報告",
            "REPORT_GROUP_DATA": "報告",
            "REPORT_GROUP_PHOTO": "画像報告",
            "REPORT_OPT_CHART": "サマリーグラフ",
            "REPORT_OPT_CHART_CHK": "概要グラフを表示する",
            "REPORT_OPT_COUNTFREQ": "繰り返した回数",
            "REPORT_OPT_DISPLAY": "結果を表示",
            "REPORT_OPT_DLT4H": "4時間以上",
            "REPORT_OPT_DUMP": "ダンプの動作を確認します。",
            "REPORT_OPT_FUEL": "オイルレベルセンサーからの燃料消費率。",
            "REPORT_OPT_FUELDETECT": "原油価格が異常な動きをする",
            "REPORT_OPT_LOCATION": "指定されたエリア",
            "REPORT_OPT_MONTH": "月の概要",
            "REPORT_OPT_NOCARD": "カードをスワイプしませんでした",
            "REPORT_OPT_OPSDELAY": "時間の経過とともに {{value}} 分",
            "REPORT_OPT_OPSDELAY_NONE": "速度を超えた遅延なし",
            "REPORT_OPT_OVERSPEED": "過速度だけ",
            "REPORT_OPT_PARKTIME": "駐車期間",
            "REPORT_OPT_STATUS": "車の状態",
            "REPORT_OPT_TMS_STATE": "作業状況",
            "REPORT_OPT_USESPEED": "制限速度を指定する",
            "REPORT_SUMMARY_COUNT": "データ送信回数",
            "REPORT_SUMMARY_DURATION": "使用期間",
            "REPORT_SUMMARY_LENGTH": "累計距離",
            "REPORT_SUMMARY_MAXSPEED": "最大速度",
            "REPORT_SUMMARY_MAXTIME": "最大距離",
            "REPORT_SUMMARY_NOGPS": "GPS電波なし",
            "REPORT_SUMMARY_PARK": "エンジン点停車",
            "REPORT_SUMMARY_PARK_MAXTIME": "最大停車時間",
            "REPORT_SUMMARY_ROUTE": "旅路",
            "REPORT_SUMMARY_SPEEDLIMIT": "限定速度以上",
            "REPORT_SUMMARY_STOP": "エンジン切停車",
            "REPORT_TABLE_FUELAVG_ENGINE": "作業時間（リットル/時間）",
            "REPORT_TABLE_FUELAVG_KM": "距離(km/リットル)",
            "REPORT_TITLE_DATERANGE": "ช่วงวันที่ {{date}}",
            "REPORT_TITLE_DEVICE": "ทะเบียน {{device}}",
            "REPORT_TITLE_DEVICE_ALL": "ทะเบียนทั้งหมด",
            "REPORT_TITLE_DEVICE_GROUP": "กลุ่ม {{group}}",
            "REPORT_VIEW_DEVICE": "デバイスの表示",
            "REPORT_VIEW_HEADER": "ショーヘッド",
            "REPORT_VIEW_TOTAL": "合計を表示",
            "STATE_ABSENCE": "ลา",
            "STATE_BAD": "Bad",
            "STATE_DONE": "ดำเนินการแล้ว",
            "STATE_GOOD": "Good",
            "STATE_MODERATE": "Moderate",
            "STATE_PENDING": "รอดำเนินการ",
            "STATE_STRIKE": "หยุุด",
            "STATE_WAIT": "รอ",
            "STATE_WORK": "งาน",
            "TEXT_ACCESSTIME": "最終使用",
            "TEXT_ADD_POINT": "行先",
            "TEXT_ADDRESS": "住所",
            "TEXT_ALEART_MONEY_INPUT": "受け取る金額を指定してください。",
            "TEXT_ALERT_SELECT_DEVICE": "車を選択してください",
            "TEXT_ALERT_SELECT_DRIVER": "運転手さんお願いします",
            "TEXT_ALERT_SELECT_NOTIFY": "通知を選択してください。",
            "TEXT_ALERT_TIME_ERROR": "時間が間違っています",
            "TEXT_AMOUNT": "数",
            "TEXT_AMOUNT_LITER": "数",
            "TEXT_APPLY": "適用する",
            "TEXT_AREA": "エリア",
            "TEXT_ATTACHMENT": "Attachment",
            "TEXT_ATTACHMENT_IMAGE": "Attachment Image",
            "TEXT_AVG": "Avg",
            "TEXT_BIRTHDAY": "生年月日",
            "TEXT_CLEAR": "クリア",
            "TEXT_CLEAR_OLD": "まず元のデータを削除してください。",
            "TEXT_CLEAR_SIGNATURE": "署名の削除",
            "TEXT_COMPANY": "会社",
            "TEXT_COPY": "Copy",
            "TEXT_COPY_FROM": "From",
            "TEXT_COPY_TO": "To",
            "TEXT_COST": "Cost",
            "TEXT_COUNT_DUPLICATE": "ซ้ำ {{count}} รายการ",
            "TEXT_COUNT_FOUND": "พบแล้ว {{count}} รายการ",
            "TEXT_COUNT_NOIMPORT": "นำเข้าไม่ได้ {{count}} รายการ",
            "TEXT_COUNT_SELECT": "เลือกแล้ว {{count}} รายการ",
            "TEXT_COUNT_TOTAL": "累計 {{count}} つ",
            "TEXT_CREATETIME": "作成時間",
            "TEXT_CREDIT": "Credit",
            "TEXT_CUSTOMER": "お客様",
            "TEXT_DATA": "データ",
            "TEXT_DATE": "年月日",
            "TEXT_DATE_END": "終了日",
            "TEXT_DATE_FROM": "「ー年月日ー」から",
            "TEXT_DATE_IN": "エントリー日",
            "TEXT_DATE_INSTALL": "インストール年月日",
            "TEXT_DATE_OUT": "発行日",
            "TEXT_DATERANGE": "＿日から＿日まで",
            "TEXT_DATE_START": "開始日",
            "TEXT_DATE_TO": "「ー年月日ー」まで",
            "TEXT_DEFAULT": "Default",
            "TEXT_DELAY": "遅れ",
            "TEXT_DESC_DISTANCE": "遠いところから順に並べます。",
            "TEXT_DETAIL": "詳細",
            "TEXT_DEVICE": "デバイス",
            "TEXT_DEVICE_24H_DURATION": "24時間ご利用ください。",
            "TEXT_DEVICE_COUNT": "デバイス",
            "TEXT_DEVICE_FROM": "デバイスから",
            "TEXT_DEVICE_GROUP": "グループ",
            "TEXT_DEVICE_PARK_DURATION": "駐車期間",
            "TEXT_DEVICE_RUN_DURATION": "運転時間",
            "TEXT_DISABLE": "無効にする",
            "TEXT_DISTANCE": "距離（Km.)",
            "TEXT_DISTANCE_S": "距離",
            "TEXT_DLT": "DLT",
            "TEXT_DOWNLOAD": "ダウンロード",
            "TEXT_DRIVER": "運転手",
            "TEXT_DRIVER_2": "運転手 2",
            "TEXT_DRIVER_CARD": "カード",
            "TEXT_DRIVER_SCORE": "ドライバーの評価",
            "TEXT_DURATION": "時間 [分]",
            "TEXT_EMAIL": "メール",
            "TEXT_EMPLOYEE": "従業員",
            "TEXT_ENABLE": "活性化",
            "TEXT_ENGINE_OFF": "エンジンオフ",
            "TEXT_ENGINE_ON": "エンジンオン",
            "TEXT_ENGINE_TIME": "起動時間",
            "TEXT_EVENT": "行事",
            "TEXT_EXCEPT": "を除外する",
            "TEXT_EXPIRE": "期限切れ",
            "TEXT_EXPIRE_NEAR": "期限切れ",
            "TEXT_EXPORT": "データのエクスポート",
            "TEXT_FIXPOINT": "Specify coordinates",
            "TEXT_FLASHVIEW_CODE": "コードを表示する",
            "TEXT_FROM": "から",
            "TEXT_FROM_TIME": "from {{date,St}}",
            "TEXT_FUELADD": "オイル添加量（リットル）",
            "TEXT_FUELAVG_ENGINE": "作業時間（リットル/時間）",
            "TEXT_FUELAVG_KM": "距離(km/リットル)",
            "TEXT_FUELMONEY": "ค่าน้ำมันที่ใช้/บาท",
            "TEXT_FUELPRICE": "ราคาน้ำมัน",
            "TEXT_FUEL_REMAIN": "オイル残量",
            "TEXT_FUELUSE": "ปริมาณการใช้น้ำมัน/ลิตร",
            "TEXT_FUELUSE_C": "消費速度（km/リットル）",
            "TEXT_FUELUSE_S": "ใช้น้ำมัน (ลิตร)",
            "TEXT_FUEL_VOLUME": "オイル量",
            "TEXT_FULLNAME": "名前 - 姓",
            "TEXT_GENDER": "性別",
            "TEXT_GPS": "GPS",
            "TEXT_GROUP": "グループ",
            "TEXT_GSM": "GSM",
            "TEXT_HEIGHT": "身長",
            "TEXT_HOWLONG": "長さ",
            "TEXT_IMPORT": "データをインポートする",
            "TEXT_INDEX": "No.",
            "TEXT_IP": "IP",
            "TEXT_LABEL": "Label",
            "TEXT_LATITUDE": "緯度",
            "TEXT_LENGTH": "長さ",
            "TEXT_LINE": "Line",
            "TEXT_LIST": "一覧",
            "TEXT_LOCATION": "道路",
            "TEXT_LOCATION_POINT": "道路",
            "TEXT_LOCATION_SHOW": "ショーエリア",
            "TEXT_LOCATION_START": "原産地",
            "TEXT_LOGO": "ロゴ",
            "TEXT_LONGITUDE": "軽度",
            "TEXT_MAX_SPEED": "最大速度",
            "TEXT_MAX_SPEED_TIME": "Maximum speed time",
            "TEXT_MILEAGE": "マイレージ",
            "TEXT_MOBILE": "อุปกรณ์",
            "TEXT_MONEY": "額",
            "TEXT_MSISDN": "Sim No.",
            "TEXT_NAME": "名前",
            "TEXT_NAMELAST": "名字",
            "TEXT_NATION": "国",
            "TEXT_NOCARD_COUNT": "ไม่ได้รูดบัตร {{count}} ครั้ง",
            "TEXT_NOGPS": "長時間（数時間）GPSを受信していません",
            "TEXT_NOGROUP": "グループにしておりません",
            "TEXT_NONE": "なし",
            "TEXT_NONE_YET": "No data",
            "TEXT_NOSHOW": "二度と見せないでください。",
            "TEXT_NOSHOW_DAY": "今日も見せる必要はない",
            "TEXT_NOTE": "ノート",
            "TEXT_NOTIFY": "＿＿で報告",
            "TEXT_NOTIFY_AND_SPEEDLIMIT": "そしてさらなるスピード",
            "TEXT_NOTIFY_CHANNEL": "Notification channels",
            "TEXT_NOTIFY_CHANNEL_MAIN": "メインチャンネル",
            "TEXT_NOTIFY_LAST": "最終通知",
            "TEXT_NOUPDATE": "デバイスが更新を停止します (時間)",
            "TEXT_NUMBER": "No.",
            "TEXT_ONTIME": "時",
            "TEXT_ORDINAL": "Ordinal",
            "TEXT_PASSWORD": "パスワード",
            "TEXT_PASSWORD_CONFIRM": "現パスワード",
            "TEXT_PAYMENT": "お金を節約する方法",
            "TEXT_PERMIT_DATE": "許可された日付",
            "TEXT_PLACE": "場所",
            "TEXT_PLACE_NEAR": "近くある場所",
            "TEXT_PLAYBACK_COMPARE": "Compare previous data",
            "TEXT_POI": "POI",
            "TEXT_POI_BASE": "POI / กลุ่มรถ",
            "TEXT_POI_GROUP_READ": "表示される POI のグループを定義する",
            "TEXT_POI_NOBASE": "自分のPOI/車グループが表示されない",
            "TEXT_POI_NOENTRY": "立ち入り禁止場所への立ち入り回数",
            "TEXT_POI_NOIN": "POIに駐車した車は表示されません",
            "TEXT_POINT_END": "行先",
            "TEXT_POINT_START": "出発所",
            "TEXT_POI_STATION": "駅停車回数",
            "TEXT_PORT": "インポート/エクスポート",
            "TEXT_PRICE": "価格",
            "TEXT_PROBLEM_NOTIFY": "問題通知",
            "TEXT_RADIUS": "半径",
            "TEXT_RAWDATA": "実際の値",
            "TEXT_REMAIN": "残り",
            "TEXT_RESET": "リセットバリュー",
            "TEXT_RESULT": "結果",
            "TEXT_ROUTE": "ルート",
            "TEXT_ROWNUM": "順番",
            "TEXT_SEARCH": "検索",
            "TEXT_SENDED": "送信しました",
            "TEXT_SHOW_PARKING": "ショーストップ",
            "TEXT_SIGNATURE": "サイン",
            "TEXT_SIGNATURE_CUSTOMER": "顧客の署名",
            "TEXT_SIGNATURE_USER": "従業員の署名",
            "TEXT_SMS": "SMS",
            "TEXT_SORT": "Sort",
            "TEXT_SPEED": "速度",
            "TEXT_SPEED_S": "速度",
            "TEXT_STATE": "現在の状況",
            "TEXT_STATUS_ENGINE": "エンジンの状態",
            "TEXT_STATUS_RUN": "実行ステータス",
            "TEXT_STATUS": "状態",
            "TEXT_STEP": "Step",
            "TEXT_TELEPHONE": "電話番号",
            "TEXT_TIME": "時間",
            "TEXT_TIME_AT": "{{time}} の情報",
            "TEXT_TIME_END": "出発時間",
            "TEXT_TIME_IN": "入場時間",
            "TEXT_TIME_OUT": "出発時間",
            "TEXT_TIME_START": "到着時間",
            "TEXT_TIME_STAY": "走行時間",
            "TEXT_TITLE": "タイトル",
            "TEXT_TO": "まで",
            "TEXT_TOTAL": "合計",
            "TEXT_TOTAL_ALL": "Total All",
            "TEXT_TOTAL_DURATION": "全使用期間",
            "TEXT_TOTAL_PARK": "停車時間",
            "TEXT_TOTAL_PARK10": "１０分以上停車した回数",
            "TEXT_TOTAL_PARK_MAX": "エンジンを長時間停止させてください。",
            "TEXT_TOTAL_PARK_MAX_DATE": "最長アイドル時間",
            "TEXT_TOTAL_SPEED": "限定速度過回数",
            "TEXT_TYPE": "Type",
            "TEXT_UPDATETIME": "最終更新",
            "TEXT_UPLOAD": "Upload",
            "TEXT_USE_DAY": "Use per Day",
            "TEXT_USER": "ユーザー",
            "TEXT_USERAGENT": "Agent",
            "TEXT_USERNAME": "ユーザー名",
            "TEXT_WAYPOINT": "Waypoint",
            "TEXT_WHEN": "時",
            "TEXT_WIDTH": "幅",
            "TEXT_WORK": "仕事",
            "TMS_INFO_DUP_DEVICE": "この時代でも車は使われています。",
            "TMS_INFO_DUP_EMPLOYEE": "この運転手はすでに仕事を持っています。この間",
            "TMS_INFO_DUP_EMPLOYEE2": "従業員の休暇日に合わせて勤務期間が設けられています。",
            "TMS_MENU_CALENDAR": "Calendar",
            "TMS_MENU_LIST": "Schedule",
            "TMS_MENU_SEARCH": "Search",
            "TMS_MONEY_BILL": "Bill",
            "TMS_MONEY_CASH": "Cash",
            "TMS_MONEY_NO": "No.",
            "TMS_MONEY_PENDING": "Pending",
            "TMS_MONEY_TRANSFER": "Transfer",
            "ONLY_AREA_IN": "エリア内のみ",
            "ONLY_AREA_OUT": "エリア外のみ",
            "ONLY_POI_IN": "POI内のみ",
            "ONLY_POI_OUT": "POIの外のみ",
            "TRACKING_DEVICE_CARD": "Driver Card",
            "TRACKING_DEVICE_SUSPEND_BODY": "デバイスの有効期限が近日切れてしまいます,更新するため,ザービスセンターにご連絡ください",
            "TRACKING_DEVICE_SUSPEND_HEAD": "有効期限が近日切れてしまいます",
            "TRACKING_MENU_ALERT": "お知らせ",
            "TRACKING_MENU_AREA": "エリア",
            "TRACKING_MENU_COMMAND": "Command",
            "TRACKING_MENU_DEVICE": "Device Info",
            "TRACKING_MENU_NEAR": "近く",
            "TRACKING_MENU_NOW": "現在",
            "TRACKING_MENU_PLAYBACK": "プレイバック",
            "TRACKING_MENU_POI": "POI",
            "TRACKING_PLAYBACK_VAD_NO": "走行データが見つかりませんでした",
            "TRACKING_POI_INFO_ICON": "アイコン",
            "TRACKING_STATUS_FAST": "高速",
            "TRACKING_STATUS_OFF": "切",
            "TRACKING_STATUS_PARK": "駐車",
            "TRACKING_STATUS_RUN": "走行",
            "TRACKING_STATUS_STOP": "停車",
            "TRACKING_STATUS_TOWER": "アンテナ",
            "UNIT_BATH": "バーツ",
            "UNIT_BPK": "バーツ/Kｍ",
            "UNIT_BPL": "バーツ/Lt",
            "UNIT_COUNT": "回",
            "UNIT_DAY": "日",
            "UNIT_HOUR": "時",
            "UNIT_KM": "KM",
            "UNIT_KMPH": "Km/Hr",
            "UNIT_KMPL": "Km/Lt",
            "UNIT_LITER": "リットル",
            "UNIT_LPH": "Lt/Hr",
            "UNIT_METRE": "M",
            "UNIT_MINUTE": "分",
            "UNIT_MONTH": "月",
            "UNIT_SECOND": "秒",
            "UNITTEXT_NUMBER": "รายการที่ {{number}}",
            "USER_API_ADD": "新しいキーを作成する",
            "USER_API_EMPTY": "API キーはまだ作成されていません。",
            "USER_DEVICE_FIELD_CHART": "燃料グラフ",
            "USER_DEVICE_FIELD_CHART_HELP": "ガソリンの最大と最小を選ぶため,ハイライトいしてください",
            "USER_DEVICE_FIELD_MAX": "最大期間",
            "USER_DEVICE_FIELD_MIN": "最小期間",
            "USER_DEVICE_FIELD_REVERSE": "最小 - 最大 を切り替える",
            "USER_DEVICE_FIELD_TELEPHONE": "運転手携帯番号",
            "USER_DEVICE_FIELD_TRACKER": "追跡機モデル",
            "USER_DEVICE_FORM_EDIT_HEAD": "デバイス変更詳細",
            "USER_DEVICE_FORM_MARKER_HEAD": "マーカー設定",
            "USER_DEVICE_FORM_OIL_HEAD": "燃料グラフ設定",
            "USER_DEVICE_TABLE_ALERT_WHEN": "時",
            "USER_DRIVER_VAD_EMPTY": "運転手名をご入力ください",
            "USER_FORM_ADD_HEAD": "情報を追加する",
            "USER_FORM_SUMMARY_SEND": "日報送信",
            "USER_INFO_CHECKPOINT": "เครดิต CheckPoint",
            "USER_INFO_SMS": "SMSクレジット",
            "USER_MENU_API": "API管理",
            "USER_MENU_AREA": "Area",
            "USER_MENU_CHECKPOINT": "Checkpoint",
            "USER_MENU_COMMAND": "コマンド送信",
            "USER_MENU_COMPANY": "#REF!",
            "USER_MENU_CUSTOMER": "#REF!",
            "USER_MENU_DASHBOARD": "ホームページ",
            "USER_MENU_DEVICE": "デバイス",
            "USER_MENU_EMPLOYEE": "従業員",
            "USER_MENU_EMPLOYEE_DAY": "休暇日",
            "USER_MENU_FLASHVIEW": "一時的なビュー",
            "USER_MENU_FUEL": "Fuel",
            "USER_MENU_LOG": "#REF!",
            "USER_MENU_LOG_CHECKPOINT": "ประวัติการใช้เครดิต CheckPoint",
            "USER_MENU_LOG_SIGN": "ログイン履歴",
            "USER_MENU_LOG_SMS": "SMS記録",
            "USER_MENU_MOBILE": "Mobile",
            "USER_MENU_NOTIFICATION": "通知",
            "USER_MENU_POI": "POI",
            "USER_MENU_PROFILE": "ユーザーデータ",
            "USER_MENU_REPORT": "Report",
            "USER_MENU_SUBUSER": "Sub-user",
            "USER_NOTIFY_AD": "センサーからの値",
            "USER_NOTIFY_AREA": "エリア",
            "USER_NOTIFY_DRIVER_CARD": "カードのスワイプ",
            "USER_NOTIFY_EVENT": "イベント",
            "USER_NOTIFY_PARK_LIMIT": "駐車場",
            "USER_NOTIFY_POI": "POI",
            "USER_NOTIFY_SPEED_LIMIT": "スピード超過",
            "USER_NOTIFY_STATE": "機械の状態",
            "USER_POI_FIELD_EMAIL": "電子メール通知",
            "USER_POI_FIELD_IN": "入場時に通知",
            "USER_POI_FIELD_OUT": "退出時に通知する",
            "USER_POI_FIELD_SM": "小さなアイコン",
            "USER_POI_LOCATION": "位置",
            "USER_PROFILE_FAIL_PASS_CONFIRM": "現パスワード不正です",
            "USER_PROFILE_FAIL_PASS_LENGTH": "新しいパスワードが短すぎます。",
            "USER_PROFILE_FAIL_PASS_RE": "パスワードが一致しません",
            "USER_PROFILE_FAIL_USER_DUP": "ユーザー名が重複しています。",
            "USER_PROFILE_FORM_PASSWORD_HEAD": "パスワード変更",
            "USER_SETTING_TAGS_NOTE": "Enter を使用して新しいラベルを追加します。",
            "USER_SETTING_TMSPAY": "支払いチャネル",
            "USER_TEXT_DOCUMENT_GOV": "政府文書",
            "USER_TEXT_EMAIL_NOTE": "メールアドレスを１つ以上記入する場合はメールアドレスの間「,」を入れてください",
            "USER_TEXT_IDCARD": "国民登録番号",
            "USER_TEXT_LICENCE_GOV": "発行地",
            "USER_TEXT_LICENCE_NUMBER": "運転免許証番号",
            "USER_TEXT_LICENCE_TYPE": "免許の種類",
            "USER_TEXT_PASSWORD_NOTE": "パスワードを変更したくない場合は、入力しないでください。",
            "USER_TEXT_PREFIX": "称号",
            "USER_TEXT_REGISTRAR": "登記官",
            "USER_TEXT_REPASSWORD": "パスワードを認証する"
        }
    },
    "lo": {
        "translation": {
            "TEST_TEXT": "ຕົວຢ່າງຄໍາສັ່ງ",
            "API_NAME_DEVICE_ALL": "ຂໍລາຍຊື່ລົດທັງໝົດໃນບັນຊີ",
            "API_NAME_DEVICE_GET": "ຮ້ອງຂໍສະຖານະຍານພາຫະນະປັດຈຸບັນຈາກ ID",
            "API_NAME_DEVICE_LOG": "ເບິ່ງປະຫວັດການຂັບລົດ",
            "API_NAME_POI_ALL": "ຮ້ອງຂໍບັນຊີລາຍຊື່ຂອງ POIs ທັງຫມົດໃນບັນຊີຂອງທ່ານ",
            "API_NAME_POI_GET": "ຮ້ອງຂໍ POI ຈາກ ID",
            "API_NAME_REPORT_LENGTH": "ລາຍງານໄລຍະຫ່າງ",
            "API_NAME_REPORT_RS232": "ລາຍງານການປັດບັດ",
            "API_NAME_REPORT_WORKLOAD": "ລາຍງານຊົ່ວໂມງເຮັດວຽກ",
            "API_NAME_VERSION": "ໝາຍເລກເວີຊັນ API",
            "API_PARAM_ADDRESS": "ສະແດງທີ່ຢູ່ (ໃສ່ 1 ເພື່ອສະແດງທີ່ຢູ່; ລາຍງານຜົນໄດ້ຮັບປະສິດທິພາບຫນ້ອຍ)",
            "API_PARAM_DEVICE": "ໝາຍເລກອຸປະກອນ",
            "API_PARAM_END": "ເວລາສິ້ນສຸດ",
            "API_PARAM_LIMIT": "ຈໍານວນລາຍການທີ່ຕ້ອງການ",
            "API_PARAM_ORDER": "ການຈັດຮຽງ",
            "API_PARAM_START": "ເວລາເລີ່ມຕົ້ນ",
            "API_RESP_DEVICES": "ບັນຊີລາຍຊື່ລົດ",
            "API_RESP_DEVICES_ID": "ໝາຍເລກປະຈຳຕົວພາຫະນະ",
            "API_RESP_OK": "ສະຖານະການເຮັດວຽກຂອງ API",
            "API_RESP_VERSION": "ໝາຍເລກເວີຊັນ API",
            "APP_FINGERPRINT_SCAN": "ສະແກນລາຍນິ້ວມືຂອງທ່ານເພື່ອກວດສອບສິດອະນຸຍາດ",
            "APP_PIN": "ໃສ່ລະຫັດ PIN",
            "APP_PIN_CONFIRM": "ຢືນຢັນລະຫັດ PIN",
            "APP_PIN_ERROR": "ລະຫັດ PIN ບໍ່ຖືກຕ້ອງ.",
            "APP_PIN_ERROR_NOTMATCH": "ລະຫັດ PIN ບໍ່ກົງກັນ",
            "APP_PIN_ERROR_NOTOLD": "PIN ໃໝ່ຈະຕ້ອງບໍ່ຄືກັບ PIN ເກົ່າ.",
            "APP_PIN_NEW": "ໃສ່ລະຫັດ PIN ໃໝ່.",
            "APP_PIN_OLD": "ໃສ່ລະຫັດ PIN ເກົ່າ.",
            "APP_SETTING_APIMOON": "ເຊີບເວີສໍາຮອງ",
            "APP_SETTING_BETA": "ໃຊ້ເບຕ້າ",
            "APP_SETTING_DARK": "ໂໝດກາງຄືນ",
            "APP_SETTING_FINGERPRINT": "ເຄື່ອງສະແກນລາຍນິ້ວມື",
            "APP_SETTING_PIN": "ຕັ້ງລະຫັດ PIN",
            "APP_SETTING_PIN_CHANGE": "ປ່ຽນລະຫັດ PIN",
            "APP_SETTING_PIN_DELETE": "ລຶບ PIN",
            "APP_SETTING_PIN_ON": "ເປີດໜ້າຈໍ PIN LOCK.",
            "BUTTON_ADD": "ຕື່ມ ໃໝ່",
            "BUTTON_GEN_AREA": "ສ້າງພື້ນທີ່",
            "BUTTON_ADD_EMP_DAY": "ຕື່ມການອອກ",
            "BUTTON_CANCEL": "ຍົກເລີກ",
            "BUTTON_CHECK": "ກວດເບິ່ງຂໍ້ມູນ",
            "BUTTON_CLEAR": "ຈະແຈ້ງ",
            "BUTTON_COMMAND_SEND": "ຢືນຢັນການສົ່ງຄໍາສັ່ງ",
            "BUTTON_CONFIRM": "ຢືນຢັນ",
            "BUTTON_CONFIRM_PRIVACY": "ຍອມຮັບ",
            "BUTTON_DELETE": "ລົບ",
            "BUTTON_DOWNLOAD_CERT": "ໃບຢັ້ງຢືນ",
            "BUTTON_EDIT": "ແກ້ໄຂ",
            "BUTTON_HIDE": "ຊ່ອນ",
            "BUTTON_LOGIN": "ເຂົ້າສູ່ລະບົບ",
            "BUTTON_LOGOUT": "ອອກຈາກລະບົບ",
            "BUTTON_MONEY_RECEIVE": "ໄດ້ຮັບເງິນ",
            "BUTTON_MONEY_UNRECEIVE": "ບໍ່ໄດ້ຮັບເງິນ",
            "BUTTON_NOTIFY": "ແຈ້ງການ",
            "BUTTON_NOTIFY_SETTING": "ຕັ້ງຄ່າການແຈ້ງເຕືອນ",
            "BUTTON_OK": "ຕົກລົງ",
            "BUTTON_PORT": "ນຳເຂົ້າ / ສົ່ງອອກ",
            "BUTTON_PRINT": "ພິມ",
            "BUTTON_RESET": "ປັບ ໃໝ່",
            "BUTTON_RUN": "ປະຕິບັດ",
            "BUTTON_SAVE": "ບັນທຶກຂໍ້ມູນ",
            "BUTTON_SAVE_SORT": "ຈັດຮຽງບັນທຶກ",
            "BUTTON_SEARCH": "ຄົ້ນຫາ",
            "BUTTON_SKIP": "ຂ້າມ",
            "BUTTON_TEMPLATE_LOAD": "ໂທຈາກແມ່ແບບ",
            "BUTTON_TEMPLATE_SAVE": "ບັນທຶກໃສ່ແມ່ແບບ",
            "BUTTON_UNHIDE": "ສະແດງໃຫ້ເຫັນ",
            "BUTTON_UPDATE_APP": "ອັບເດດ",
            "BUTTON_UPLOAD": "ອັບໂຫລດ",
            "CONFIRM_DELETE": "ຢືນຢັນທີ່ຈະລຶບ",
            "CONFIRM_DELETE_SELECT": "ຢືນຢັນການລຶບຂໍ້ມູນທີ່ເລືອກ.",
            "CONFIRM_IMPORT": "ຢືນຢັນການນໍາເຂົ້າຂໍ້ມູນ",
            "CONFIRM_LOGOUT": "Logout ?",
            "CONFIRM_MONEY_RECEIVE": "ຢືນຢັນການຮັບເງິນ!?",
            "CONFIRM_MONEY_UNRECEIVE": "ຍົກເລີກການຮັບເງິນ!?",
            "CONFIRM_RESET": "ຢືນຢັນຈະຣີເຊັດ!?",
            "DALERT_COMMAND_SEND_ERROR": "ບໍ່ສາມາດສົ່ງຂໍ້ມູນໄດ້, ກະລຸນາລອງ ໃໝ່ ອີກຄັ້ງ.",
            "DALERT_DELETE_FAIL": "ບໍ່ສາມາດລຶບຂໍ້ມູນໄດ້",
            "DALERT_EDIT_NOTFOUND": "ບໍ່ພົບຂໍ້ມູນການປັບປຸງ",
            "DALERT_HISTORY_EMPTY": "ບໍ່ພົບປະຫວັດສາດທີ່ຫວນຄືນຫຼັງ :",
            "DALERT_HISTORY_NOMOVE": "ລົດບໍ່ຍ້າຍ",
            "DALERT_MUST_DEVICE": "ຕ້ອງເລືອກລົດ",
            "DALERT_REPORT_EMPTY": "ບໍ່ພົບຂໍ້ມູນລາຍງານ",
            "DALERT_SAVE_FAIL": "ບໍ່ສາມາດບັນທຶກຂໍ້ມູນໄດ້",
            "DALERT_USERNAME_DUP": "ຊື່ຜູ້ໃຊ້ນີ້ມີຢູ່ໃນລະບົບແລ້ວ.",
            "DATE_DAY_ALL": "ຫມົດມື້",
            "DATE_DAY_0": "ວັນອາທິດ",
            "DATE_DAY_1": "ວັນຈັນ",
            "DATE_DAY_2": "ວັນອັງຄານ",
            "DATE_DAY_3": "ວັນພຸດ",
            "DATE_DAY_4": "ວັນພະຫັດ",
            "DATE_DAY_5": "ວັນ​ສຸກ",
            "DATE_DAY_6": "ວັນເສົາ",
            "DATE_DAY_SHORT_0": "ອາ",
            "DATE_DAY_SHORT_1": "ວ",
            "DATE_DAY_SHORT_2": "ອ",
            "DATE_DAY_SHORT_3": "ພ",
            "DATE_DAY_SHORT_4": "ພະ",
            "DATE_DAY_SHORT_5": "​ສກ",
            "DATE_DAY_SHORT_6": "ສ",
            "DATE_MONTH_1": "ມັງກອນ",
            "DATE_MONTH_2": "ກຸມພາ",
            "DATE_MONTH_3": "ມີນາ",
            "DATE_MONTH_4": "ເດືອນເມສາ",
            "DATE_MONTH_5": "ພຶດສະພາ",
            "DATE_MONTH_6": "ມິຖຸນາ",
            "DATE_MONTH_7": "ເດືອນກໍລະກົດ",
            "DATE_MONTH_8": "ສິງຫາ",
            "DATE_MONTH_9": "ກັນຍາ",
            "DATE_MONTH_10": "ເດືອນຕຸລາ",
            "DATE_MONTH_11": "ພະຈິກ",
            "DATE_MONTH_12": "ທັນວາ",
            "DATE_MONTH_LAST": "ເດືອນທີ່ຜ່ານມາ",
            "DATE_MONTH_SHORT_1": "ມັງກອນ",
            "DATE_MONTH_SHORT_2": "ກຸມພາ",
            "DATE_MONTH_SHORT_3": "ມີນາ",
            "DATE_MONTH_SHORT_4": "ເມສາ",
            "DATE_MONTH_SHORT_5": "ພຶດສະພາ",
            "DATE_MONTH_SHORT_6": "ມິຖຸນາ",
            "DATE_MONTH_SHORT_7": "ກໍລະກົດ",
            "DATE_MONTH_SHORT_8": "ສິງຫາ",
            "DATE_MONTH_SHORT_9": "ກັນຍາ",
            "DATE_MONTH_SHORT_10": "ຕຸລາ",
            "DATE_MONTH_SHORT_11": "ພະຈິກ",
            "DATE_MONTH_SHORT_12": "ທັນວາ",
            "DATE_MONTH_THIS": "ເດືອນນີ້",
            "DATE_S": "{{date,S}}",
            "DATE_ST": "{{date,St}}",
            "DATE_TODAY": "ມື້ນີ້",
            "DATE_TRACK": "{{date,d MMM HH:mm}}",
            "DATE_YESTERDAY": "ມື້ວານນີ້",
            "DCONFIRM_CHANGE_LANGUAGE": "ທ່ານຕ້ອງການພາສາພາສາ",
            "DCONFIRM_LOGOUT": "ທ່ານຕ້ອງການອອກຈາກລະບົບ",
            "DCONFIRM_SEND_LOG": "ຢືນຢັນການສົ່ງຂໍ້ມູນ",
            "TEXT_ALL_PLAY": "ຫຼິ້ນຮູບກ້ອງຖ່າຍຮູບທັງໝົດ",
            "TEXT_ALL_CLOSE": "ປິດທັງໝົດ",
            "TEXT_ALL_PAUSE": "ຢຸດການຫຼິ້ນຮູບພາບທັງໝົດ.",
            "DEVICE_COMMAND": "Command",
            "DEVICE_COMMAND_CRONJOB": "ຕັ້ງເວລາເຮັດວຽກ",
            "DEVICE_COMMAND_RESPOND": "ຜົນໄດ້ຮັບ",
            "DEVICE_COMMAND_RESPTIME": "ຕອບສະຫນອງເວລາ",
            "DEVICE_COMMAND_SEND": "ສົ່ງ Command",
            "DEVICE_COMMAND_SENDING": "กำลังจะส่งคำสั่ง",
            "DEVICE_COMMAND_SENDTIME": "ສົ່ງເວລາ",
            "DEVICE_DVR_CHANNEL": "กล้องตัวที่ {{channel}}",
            "DEVICE_MAXVOLUME": "ປະລິມານການໂຫຼດສູງສຸດ (ແມັດກ້ອນ)",
            "DEVICE_MAXWEIGHT": "ນ້ໍາຫນັກສູງສຸດທີ່ສາມາດປະຕິບັດໄດ້",
            "DEVICE_NOTIFY_AD": "On {{ad}} {{to}} {{value}}",
            "DEVICE_NOTIFY_AREA_LOCATION": "ພື້ນທີ່ກໍານົດ",
            "DEVICE_NOTIFY_DLT": "แจ้งเตือนกรมขนส่ง",
            "DEVICE_NOTIFY_NOCARD_DURATION": "ແລ່ນອຸປະກອນໂດຍບໍ່ຕ້ອງປັດບັດເປັນເວລາ {{duration}} ນາທີ.",
            "DEVICE_NOTIFY_PARK_DURATION": "ຈອດເຄື່ອງຈັກເປັນເວລາ {{duration}} ນາທີ.",
            "DEVICE_NOTIFY_POIALL": "All Poi",
            "DEVICE_NOTIFY_POI_IN": "เมื่อเข้า {{poi}}",
            "DEVICE_NOTIFY_POI_OUT": "เมื่อออกจาก {{poi}}",
            "DEVICE_NOTIFY_POISTOP_IN": "ຈອດຢູ່ໃນ POI ຫຼາຍກ່ວາ {{duration}} ນາທີ",
            "DEVICE_NOTIFY_RS232_NO": "ติดเครื่อง แต่ไม่รูดบัตร นานเกิน {{duration}} นาที",
            "DEVICE_NOTIFY_SPEEDLIMIT": "ເມື່ອຄວາມໄວເກີນກໍານົດ",
            "DEVICE_NOTIFY_STATE": "On {{state}} change to {{to}}",
            "DEVICE_TEXT_FUELUSE": "ການ ນຳ ໃຊ້ເຊື້ອເພີງ",
            "DEVICE_TEXT_FUELVOLUME": "ປະລິມານຖັງນໍ້າມັນ",
            "DEVICE_TEXT_MODEL": "ຕົວແບບ",
            "DEVICE_TEXT_NOTIFY": "ແຈ້ງການ",
            "DEVICE_TEXT_SPEEDLIMIT": "ຄວາມໄວ ຈຳ ກັດ",
            "DIALOG_ADD": "ເພີ່ມ",
            "DIALOG_LANGUAGE": "ປ່ຽນພາສາ",
            "EVENT_1": "Engine & Input 1 Active",
            "EVENT_10": "Engine & Input 2 Inactive",
            "EVENT_109": "ເຫດການລົດແທັກຊີ້",
            "EVENT_11": "Engine & Input 3 Inactive",
            "EVENT_1101": "ເຂົ້າໄປໃນ POI",
            "EVENT_1102": "ອອກຈາກ POI",
            "EVENT_12": "Engine & Input 4 Inactive",
            "EVENT_1201": "ຈອດລົດດົນກວ່າ 10 ນາທີ",
            "EVENT_1202": "ເປີດອຸປະກອນແຕ່ຢ່າປັດບັດດົນກວ່າ 10 ນາທີ.",
            "EVENT_129": "Harsh Braking",
            "EVENT_13": "Engine & Input 5 Inactive",
            "EVENT_130": "Harsh Acceleration",
            "EVENT_133": "ເວລາບໍ່ເຮັດວຽກ",
            "EVENT_134": "ການຟື້ນຕົວບໍ່ເຮັດວຽກ",
            "EVENT_135": "ຂັບລົດຄວາມອ້ວນ",
            "EVENT_136": "ພັກຜ່ອນໃຫ້ພຽງພໍຫຼັງຈາກຂັບລົດຄວາມອ້ວນ",
            "EVENT_138": "ການກູ້ຄືນຄວາມໄວ",
            "EVENT_17": "ແບັດເຕີຣີຕ່ ຳ",
            "EVENT_18": "ພະລັງງານພາຍນອກຕ່ ຳ",
            "EVENT_19": "ຄວາມໄວ",
            "EVENT_2": "Engine & Input 2 Active",
            "EVENT_20": "ເຂົ້າ Geo-fence",
            "EVENT_21": "ອອກໄປ Geo-fence",
            "EVENT_22": "ພະລັງງານພາຍນອກ",
            "EVENT_23": "ພະລັງງານພາຍນອກປິດ",
            "EVENT_24": "ບໍ່ມີສັນຍານ GPS",
            "EVENT_25": "ຮັບສັນຍານ GPS",
            "EVENT_26": "ເຂົ້ານອນ",
            "EVENT_27": "ອອກຈາກການນອນຫລັບ",
            "EVENT_28": "GPS Antennal ຕັດ",
            "EVENT_29": "ເປີດອຸປະກອນ ໃໝ່",
            "EVENT_3": "Engine & Input 3 Active",
            "EVENT_30": "ຜົນກະທົບ",
            "EVENT_31": "ບົດລາຍງານຫົວໃຈເຕັ້ນ",
            "EVENT_32": "ບົດລາຍງານການປ່ຽນແປງຫົວຂໍ້",
            "EVENT_33": "ບົດລາຍງານໄລຍະຫ່າງທາງໄກ",
            "EVENT_34": "ບົດລາຍງານສະຖານທີ່ປະຈຸບັນ",
            "EVENT_35": "ບົດລາຍງານໄລຍະຫ່າງຂອງເວລາ",
            "EVENT_36": "ຖືເປັນຕາຕົກໃຈ",
            "EVENT_37": "RFID",
            "EVENT_39": "ຮູບພາບ",
            "EVENT_4": "Engine & Input 4 Active",
            "EVENT_40": "ປຸ່ມປິດພະລັງງານ",
            "EVENT_41": "ຢຸດການເຄື່ອນຍ້າຍ",
            "EVENT_42": "ເລີ່ມຕົ້ນຍ້າຍ",
            "EVENT_44": "GSM Jamming",
            "EVENT_5": "Engine & Input 5 Active",
            "EVENT_50": "ອຸນຫະພູມສູງ",
            "EVENT_51": "ອຸນຫະພູມຕໍ່າ",
            "EVENT_52": "ນໍ້າມັນເຊື້ອໄຟເຕັມ",
            "EVENT_53": "ນໍ້າມັນເຊື້ອໄຟຕໍ່າ",
            "EVENT_54": "ລັກນໍ້າມັນເຊື້ອໄຟ",
            "EVENT_56": "Armed (T36x)",
            "EVENT_57": "Disarmed (T36x)",
            "EVENT_58": "Vehicle Theft (T36x)",
            "EVENT_63": "No GSM Jamming",
            "EVENT_65": "Press Input 1 (SOS) to Call",
            "EVENT_66": "Press input 2 to Call",
            "EVENT_67": "Press input 3 to Call",
            "EVENT_68": "Press input 4 to Call",
            "EVENT_69": "Press input 5 to Call",
            "EVENT_70": "Reject Incoming Call",
            "EVENT_71": "Report Location by Call",
            "EVENT_72": "Auto Answer Incoming Call",
            "EVENT_73": "Listen-in (voice monitoring)",
            "EVENT_78": "ຜົນກະທົບ",
            "EVENT_82": "ນໍ້າມັນເຊື້ອໄຟເຕັມ",
            "EVENT_83": "Ult-Sensor Drop",
            "EVENT_86": "ເລື່ອນບັດເພື່ອອອກໄປ",
            "EVENT_9": "Engine & Input 1 Inactive",
            "EVENT_90": "Sharp Turn to Left",
            "EVENT_90_B": "Sharp Turn",
            "EVENT_91": "Sharp Turn to Right",
            "EVENT_HIK_643": "GPS Data Report",
            "EVENT_HIK_644": "Front Vehicle Collision Warning",
            "EVENT_HIK_645": "Short Vehicle Distance Warning",
            "EVENT_HIK_646": "Lane Deviation Warning",
            "EVENT_HIK_647": "Pedestrian Collision Warning",
            "EVENT_HIK_648": "Overspeed Warning",
            "EVENT_HIK_649": "Blind Spot Detection Warning",
            "EVENT_HIK_650": "Smoking",
            "EVENT_HIK_651": "On the Phone",
            "EVENT_HIK_652": "Fatigue Driving",
            "EVENT_HIK_653": "Distracted Driving",
            "EVENT_HIK_654": "Not Buckled Up",
            "EVENT_HIK_655": "Video Tampering",
            "EVENT_HIK_656": "Panic Button Alarm",
            "EVENT_HIK_657": "Sharp Turn",
            "EVENT_HIK_658": "Sudden Brake",
            "EVENT_HIK_659": "Sudden Brake",
            "EVENT_HIK_660": "Rollover",
            "EVENT_HIK_661": "Overspeed",
            "EVENT_HIK_662": "Collision",
            "EVENT_HIK_663": "Enter Fence",
            "EVENT_HIK_664": "Deviation",
            "EVENT_HIK_665": "Exit Fence",
            "FAB_BOUND": "ຂອບເຂດທັງໝົດ",
            "FAB_GO_LOCATION": "ຕຳແໜ່ງປັດຈຸບັນ",
            "FAB_MAP_SAT": "ແຜນທີ່ດາວທຽມ",
            "FAB_MAP_TRAFFIC": "ການຈະລາຈອນ",
            "FAB_POI_SHOW": "ສະແດງ POI",
            "FAB_REFRESH": "ໂຫຼດຫນ້າຈໍຄືນ",
            "FORM_CHECK_ALL": "ເລືອກທັງ ໝົດ",
            "FORM_CHECK_NONE": "ບໍ່ເລືອກອັນໃດ",
            "FORM_SELECT": "-- ເລືອກ --",
            "FORM_SELECT_ALL": "- ທັງ ໝົດ -",
            "FORM_SELECT_NONE": "- ບໍ່ມີ -",
            "GROUPBY_DAY": "ໃນມື້",
            "GROUPBY_MONTH": "ໂດຍເດືອນ",
            "GROUPBY_WEEK": "ໃນອາທິດ",
            "INFO_CLOSEAPP": "ກົດກັບຄືນອີກຄັ້ງເພື່ອປິດແອັບພລິເຄຊັນ.",
            "INFO_COMMAND_RESPONDED": "ເຄື່ອງແມ່ຂ່າຍຕອບສະຫນອງ",
            "INFO_COMMAND_RESPOND_TEXT": "ສະຖານະພາບການຕອບໂຕ້",
            "INFO_COMMAND_SENDED": "ຂໍ້ມູນຖືກສົ່ງແລ້ວ",
            "INFO_COMMAND_SENDING": "ການສົ່ງຂໍ້ມູນ",
            "INFO_COMMAND_STATUS": "ສະຖານະການສົ່ງ",
            "INFO_COMMAND_WAIT": "ຕອບສະ ໜອງ...",
            "INFO_DATE_REQ": "ກະລຸນາເລືອກວັນທີກ່ອນ.",
            "INFO_DROPED": "ລຶບແລ້ວ",
            "INFO_DROP_FAIL": "ບໍ່ສາມາດລຶບຂໍ້ມູນໄດ້",
            "INFO_DROP_NOTFOUND": "ລຶບຂໍ້ມູນທີ່ບໍ່ພົບ",
            "INFO_DVR_STATUS_FAIL": "DVR ອອບໄລນ໌",
            "INFO_EXPIRE_DETAIL": "ອຸປະກອນຕໍ່ໄປນີ້ຈະ ໝົດ ອາຍຸຫຼືແລ້ວ ໝົດ ອາຍຸແລ້ວ. ກະລຸນາຕິດຕໍ່ຫາພວກເຮົາເພື່ອຂະຫຍາຍການບໍລິການຂອງພວກເຮົາຖ້າບໍ່ດັ່ງນັ້ນ, ລະບົບຈະລຶບອຸປະກອນຕ່າງໆໂດຍອັດຕະໂນມັດ.",
            "INFO_EXPIRE_NOTIFY": "ການແຈ້ງເຕືອນ ໝົດ ອາຍຸຂອງອຸປະກອນ",
            "INFO_LOGOUT": "ອອກຈາກລະບົບ",
            "INFO_MONEY_JOB": "ລາຍໄດ້ຈາກການເຮັດວຽກ",
            "INFO_MONEY_RECEIVE": "ໄດ້ຮັບເງິນ",
            "INFO_MONEY_UNRECEIVE": "ເງິນໄດ້ຖືກຍົກເລີກ.",
            "INFO_NOTFOUND": "ບໍ່ພົບຂໍ້ມູນ",
            "INFO_NOTIFY_DUPLICATE": "ການແຈ້ງເຕືອນນີ້ໄດ້ຮັບແລ້ວ.",
            "INFO_REPORT_WAIT": "ກະລຸນາລໍຖ້າ.",
            "INFO_SAVED": "ບັນທຶກຂໍ້ມູນແລ້ວ",
            "INFO_SAVE_DATA_INCOMPLETE": "ກະລຸນາຕື່ມຂໍ້ມູນໃສ່.",
            "INFO_SAVE_FAIL": "ບໍ່ສາມາດບັນທຶກຂໍ້ມູນໄດ້",
            "INFO_SAVE_NOTFOUND": "ບັນທຶກຂໍ້ມູນບໍ່ພົບ",
            "INPUT_POI_NAME": "ທີ່ຕັ້ງ POI ຂອງທ່ານ",
            "LABEL_PIN_CREATE": "ຕັ້ງລະຫັດ PIN ຂອງແອັບພລິເຄຊັນ",
            "LOGIN_SUCCESS": "Login Success",
            "LOGIN_TITLE": "ເຂົ້າສູ່ລະບົບ",
            "LOGIN_VALIDATE_FAIL": "ຊື່ຜູ້ໃຊ້ຫລືລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
            "MA_MENU_HISTORY": "ประวัติ",
            "MA_MENU_LIST": "ລາຍການແຈ້ງເຕືອນ",
            "MA_MENU_SERVICE": "ການບໍລິການ",
            "MA_MENU_SUMMARY": "ບົດລາຍງານກ່ຽວກັບການ ບຳ ລຸງຮັກສາ",
            "MA_MENU_TEMPLATE": "ແມ່ແບບ",
            "MA_MODE_DURATION": "ວັນທີລົງວັນທີ",
            "MA_MODE_LENGTH": "ຈຸດ ໝາຍ ປາຍທາງຂັບລົດ",
            "MA_MODE_WORKTIME": "ຊົ່ວໂມງເຮັດວຽກ",
            "MA_NOTIFY": "ເພື່ອກໍານົດການບໍາລຸງຮັກສາ {{name}} ຂອງລົດ {{device}}",
            "MA_TEXT_CURRENT": "ບັນທຶກປະຈຸບັນ",
            "MA_TEXT_CURRENT_UNIT": "ບັນທຶກປະຈຸບັນ {{value}} {{unit}}",
            "MA_TEXT_DATE_IN": "ວັນທີສົ່ງລົດເຂົ້າມາ",
            "MA_TEXT_DATE_OUT": "ວັນທີຮັບລົດຄືນ",
            "MA_TEXT_GARAGE": "ອູ່ລົດ",
            "MA_TEXT_INTERVAL": "ແຈ້ງເຕືອນທຸກໆທ່ານ",
            "MA_TEXT_INTERVAL_UNIT": "ແຈ້ງເຕືອນທຸກໆທ່ານ {{value}} {{unit}}",
            "MA_TEXT_LIST": "ບັນຊີລາຍຊື່ການສ້ອມແປງ",
            "MA_TEXT_MODE": "ແມັດໂດຍ",
            "MA_TEXT_REMAIN": "ແຈ້ງເຕືອນເມື່ອ {{value}} {{unit}}",
            "MENU_CONTROLROOM": "Control Room",
            "MENU_DEVICE": "ອຸປະກອນ",
            "MENU_DISTANCE": "ວັດແທກໄລຍະຫ່າງ",
            "MENU_HELP": "ຊ່ວຍເຫຼືອ",
            "MENU_HISTORY": "ການຫຼິ້ນຄືນ",
            "MENU_LOGOUT": "ອອກ ຈາກ ລະ ບົບ",
            "MENU_MA": "ສ້ອມແປງ",
            "MENU_MAP": "ແຜນທີ່",
            "MENU_MOBILE": "Mobile",
            "MENU_MOBILE_DVR": "ກ້ອງ DVR",
            "MENU_MOBILE_INFO": "ຂໍ້ມູນລົດ",
            "MENU_MOBILE_MAP": "ແຜນທີ່",
            "MENU_MOBILE_NOTIFY": "ເຕືອນ",
            "MENU_MODULE": "ລະບົບຕ່າງໆ",
            "MENU_MODULE_CHECKPOINT": "Checkpoint",
            "MENU_MODULE_MA": "ການບຳລຸງຮັກສາ",
            "MENU_MODULE_REVENUE": "ລາຍຮັບການຂົນສົ່ງ",
            "MENU_MODULE_TMS": "ລະບົບເຮືອ",
            "MENU_MODULE_TMSROUTE": "TMS Route",
            "MENU_POI": "ຈຸດໝາຍ (POI)",
            "MENU_PRIVACY": "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
            "MENU_REPORT": "ບົດລາຍງານ",
            "MENU_RESELLER": "Reseller",
            "MENU_SETTING": "ການຕັ້ງຄ່າ",
            "MENU_TOOL": "ເຄື່ອງມື",
            "MENU_TOOL_POI": "ວັດແທກໄລຍະທາງ POI",
            "MENU_TRACKING": "ຕິດຕາມ",
            "MENU_TRACKING_TAXI": "ຕິດຕາມລົດແທັກຊີ",
            "NOTE_FUEL_FULL": "ທຸລະກຳການເຕີມນ້ຳມັນຈະຖືກລາຍງານຢ່າງຖືກຕ້ອງ. ໃນກໍລະນີທີ່ທຸກຄັ້ງທີ່ທ່ານເຕີມເງິນ, tank ຕ້ອງໄດ້ເຕີມລົງໄປ. ແລະພຽງແຕ່ຮູ້ເວລາຂອງການຕື່ມ",
            "NOTE_FUEL_VOLUME": "ບັນຊີລາຍຊື່ນ້ໍາມັນຈະຖືກລາຍງານຢ່າງຖືກຕ້ອງ. ໃນກໍລະນີທີ່ຖັງນໍ້າມັນເຊື້ອໄຟມີພຽງແຕ່ສີ່ຫຼ່ຽມມົນ",
            "NOTE_FUEL_VOLUME_REPORT": "ທຸກໆຄັ້ງທີ່ທ່ານເພີ່ມອາຍແກັສ, ທ່ານຕ້ອງປິດເຄື່ອງຈັກ.",
            "NOTE_PORTCLEAR": "ຂໍ້ມູນທັງໝົດຈະສູນຫາຍໄປ. ກະລຸນາເຮັດການສໍາຮອງຂໍ້ມູນກ່ອນທີ່ຈະຄລິກ.",
            "NOTIFY_AD": "{{device}} {{ad}} {{to}} {{value}} ({{current}})",
            "NOTIFY_AREA_IN": "{{device}} ໃສ່ {{area}} {{address}}",
            "NOTIFY_AREA_OUT": "{{device}} ອອກຈາກ {{area}} ({{duration}}) {{address}}",
            "NOTIFY_DLT_NOCARD": "{{device}} ໄດເວີບໍ່ໄດ້ເລື່ອນດ້ານຫຼັງຂອງເຄື່ອງຈັກໃນເວລາຫຼາຍກວ່າ 10 ນາທີ.",
            "NOTIFY_DLT_OVERTIME": "{{device}} ຂັບເປັນເວລາຫຼາຍກວ່າ 4 ຊົ່ວໂມງ.",
            "NOTIFY_DLT_STOP": "รถทะเบียน {{device}} หยุดการส่งข้อมูลไปยังกรมการขนส่งทางบกเกิน 7 วัน กรุณาตรวจสอบอุปกรณ์จีพีเอสให้ทำงานปกติ ก่อนนำรถไปใช้งาน หรือ ติดต่อ 02-111-7999 เพื่อขอรับการช่วยเหลือ",
            "NOTIFY_DRIVER_CARD": "{{device}} ຍຶດຕິດກັບເຄື່ອງແຕ່ບໍ່ໄດ້ເລື່ອນບັດມາເປັນເວລາດົນ {{minute}} ນາທີ",
            "NOTIFY_EVENT": "{{device}} {{event}} , {{poi}} {{address}}",
            "NOTIFY_LABEL_EMAIL": "ສົ່ງການແຈ້ງເຕືອນຜ່ານທາງອີເມວ",
            "NOTIFY_LABEL_LINE": "ສົ່ງແຈ້ງການຜ່ານ Line",
            "NOTIFY_LABEL_PUSH": "ສົ່ງການແຈ້ງເຕືອນຜ່ານ App",
            "NOTIFY_LABEL_SMS": "ສົ່ງການແຈ້ງເຕືອນຜ່ານ SMS",
            "NOTIFY_PARK_LIMIT": "{{device}} ຈອດລົດໄວ້ເປັນເວລາຫຼາຍກ່ວາ {{minute}} ນາທີ , {{poi}} {{address}}",
            "NOTIFY_POI_IN": "{{device}} ໃສ່ {{poi}} {{address}}",
            "NOTIFY_POI_OUT": "{{device}} ອອກຈາກ {{poi}} {{address}}",
            "NOTIFY_POISTOP": "{{device}} ສວນສາທາລະນະຢູ່ນອກ Poi ເກີນ {{time}} {{address}}",
            "NOTIFY_SETTING": "Notification Setting",
            "NOTIFY_SPEED_LIMIT": "{{device}} ຂັບລົດໄວເກີນໄປ ({{speed}} > {{speed_limit}}) , {{poi}} {{address}}",
            "NOTIFY_STATE": "{{device}} ປ່ຽນສະຖານະ {{state}} {{to}} , {{poi}} {{address}}",
            "NOTIFICATION_ENGINE_OFF": "ປິດເຄື່ອງຈັກ",
            "NOTIFICATION_ENGINE_PARK": "ຈອດເຄື່ອງຈັກ",
            "NOTIFICATION_ENGINE_RUN": "ແລ່ນ",
            "OPTION_APPROVE": "ອະນຸມັດ",
            "OPTION_DECREASE": "ຫຼຸດລົງຫນ້ອຍກວ່າ",
            "OPTION_DENY": "ບໍ່ໄດ້ຮັບການອະນຸມັດ",
            "OPTION_ENABLE_CLUSTER": "Show Cluster marker",
            "OPTION_FILTER": "ກັ່ນຕອງຕາມ",
            "OPTION_GT": "ຍິ່ງໃຫຍ່ກວ່າ",
            "OPTION_IN": "ໃນ",
            "OPTION_INOUT": "ໃນ ແລະ ອອກ",
            "OPTION_LT": "ຫນ້ອຍກວ່າ",
            "OPTION_MAN": "ຜູ້ຊາຍ",
            "OPTION_OPSEVENT": "ສະຖານະພາບກົງກັນຂ້າມ",
            "OPTION_OUT": "Out",
            "OPTION_OVER_HOUR": "over {{hour}} hour",
            "OPTION_OVER_MINUTE": "over {{minute}} min",
            "OPTION_SHOW_CHECK": "Show Checkbox",
            "OPTION_SHOW_DEVICE": "Show Device In",
            "OPTION_SHOW_LABEL": "Show Label",
            "OPTION_SHOW_ROWNUM": "ສະແດງເລກລໍາດັບ",
            "OPTION_SHOW_SECOND": "Show Second",
            "OPTION_SHOW_STATUS": "Show Status",
            "OPTION_TOTAL": "ທັງໝົດ",
            "OPTION_WAIT": "ລໍຖ້າ",
            "OPTION_WOMAN": "ເພດຍິງ",
            "OPTION_NARROW": "ແຄບ",
            "OPTION_NORMAL": "ປົກກະຕິ",
            "OPTION_WIDE": "ກວ້າງ",
            "REPORT": "ບົດລາຍງານ",
            "REPORT_ATTIME": "ລາຍງານຂໍ້ມູນທີ່ {{time}}",
            "REPORT_DISPLAY_PLACE": "ສະແດງສະຖານທີ່",
            "REPORT_DISPLAY_TABLE": "ສະແດງຕາຕະລາງຂໍ້ມູນ",
            "REPORT_DRIVER_SCORE_DAY": "ມັນເປັນຈຸດຕໍ່ມື້.",
            "REPORT_DRIVER_SCORE_SETTING": "ກໍານົດຄະແນນ",
            "REPORT_DUMP_OFF": "ຢຸດການຖິ້ມຂີ້ເຫຍື້ອ",
            "REPORT_DUMP_ON": "ບ່ອນຖິ້ມຂີ້ເຫຍື້ອ",
            "REPORT_FILE_AREA": "ເຂົ້າແລະອອກຈາກພື້ນທີ່",
            "REPORT_FILE_AREA_LENGTH": "ບົດລາຍງານພາຍໃນເຂດ",
            "REPORT_FILE_CHART_CYCLE": "ຕາຕະລາງວົງຈອນ",
            "REPORT_FILE_CHART_FUELUSE": "ກາບຟືນ",
            "REPORT_FILE_CHART_GASUSE": "ກGraphາຊກ.າຊ",
            "REPORT_FILE_CHART_POWER": "ກາຟິກການສະ ໜອງ ພະລັງງານ",
            "REPORT_FILE_CHART_SPEED": "ເສັ້ນສະແດງຄວາມໄວ",
            "REPORT_FILE_CHART_STATUS": "ສະຖານະການນໍາໃຊ້",
            "REPORT_FILE_CHART_TEMP": "ກາຟອຸນຫະພູມ",
            "REPORT_FILE_DRIVER_SCORE": "ການໃຫ້ຄະແນນຄົນຂັບ",
            "REPORT_FILE_DVR": "DVR",
            "REPORT_FILE_DVR_EVENT": "ADAS DBA Event",
            "REPORT_FILE_DVR_STATUS": "DVR Status",
            "REPORT_FILE_EVENT": "ບົດລາຍງານການແຈ້ງເຕືອນ",
            "REPORT_FILE_FUEL": "ປຽບທຽບການໃຊ້ນໍ້າມັນກັບໄລຍະທາງ.",
            "REPORT_FILE_FUELAVG": "ການບໍລິໂພກນໍ້າມັນເຊື້ອໄຟສະເລ່ຍແລະໄລຍະທາງ",
            "REPORT_FILE_GROUP": "ບົດລາຍງານກຸ່ມ",
            "REPORT_FILE_LENGTH": "ບົດລາຍງານທາງໄກ",
            "REPORT_FILE_LENGTH_SUMMARY": "ສະຫຼຸບໄລຍະເວລາການນໍາໃຊ້",
            "REPORT_FILE_MA": "ປະຫວັດການບໍາລຸງຮັກສາ",
            "REPORT_FILE_MA_SERVICE": "ປະຫວັດການເຂົ້າສູນບຳລຸງຮັກສາ",
            "REPORT_FILE_PARKING": "ບົດລາຍງານບ່ອນຈອດລົດ",
            "REPORT_FILE_PARKING_FREQ": "ບົດລາຍງານບ່ອນຈອດລົດ",
            "REPORT_FILE_PHOTO": "ຮູບພາບອຸປະກອນ",
            "REPORT_FILE_POI": "ບົດລາຍງານພາຍໃນ POI",
            "REPORT_FILE_POIOUT": "POI Out",
            "REPORT_FILE_REVENUE": "ລາຍໄດ້ສຸດທິຈາກການຂົນສົ່ງ",
            "REPORT_FILE_ROUTE": "ບົດລາຍງານປະຫວັດສາດ",
            "REPORT_FILE_RS232": "ເລື່ອນບັດ",
            "REPORT_FILE_SPEED_GROUP": "ຊ່ວງຄວາມຖີ່ຄວາມໄວ",
            "REPORT_FILE_STATUS": "ລາຍງານສະຖານະ",
            "REPORT_FILE_SUMMARY": "ບົດລາຍງານສະຫຼຸບສັງລວມ",
            "REPORT_FILE_SUMMARY_STATUS": "ລາຍງານສະຖານະ",
            "REPORT_FILE_WORKLOAD": "ບົດລາຍງານວຽກງານ",
            "REPORT_FILE_WORKLOAD_MONTH": "ບົດລາຍງານປະຈໍາເດືອນ",
            "REPORT_FILE_DLT_LOGSUM": "ການປະສານງານຜິດປົກກະຕິຂອງພະແນກຂົນສົ່ງ",
            "REPORT_GROUP_CHART": "ບົດລາຍງານເສັ້ນສະແດງ",
            "REPORT_GROUP_DATA": "ລາຍງານ",
            "REPORT_GROUP_PHOTO": "ບົດລາຍງານຮູບພາບ",
            "REPORT_OPT_CHART": "ຕາຕະລາງສະຫຼຸບ",
            "REPORT_OPT_CHART_CHK": "ສະແດງເສັ້ນສະແດງສະຫຼຸບ",
            "REPORT_OPT_COUNTFREQ": "ຊ້ຳໆ",
            "REPORT_OPT_DISPLAY": "ສະແດງຜົນໄດ້ຮັບ",
            "REPORT_OPT_DLT4H": "ໃນໄລຍະ 4 ຊົ່ວໂມງ",
            "REPORT_OPT_DUMP": "ກວດສອບການດໍາເນີນງານຂອງ dump ໄດ້.",
            "REPORT_OPT_FUEL": "ອັດຕາການບໍລິໂພກນໍ້າມັນຈາກເຊັນເຊີລະດັບນໍ້າມັນ.",
            "REPORT_OPT_FUELDETECT": "ລາຄານໍ້າມັນເຄື່ອນທີ່ຜິດປົກກະຕິ",
            "REPORT_OPT_LOCATION": "ພື້ນທີ່ກໍານົດ",
            "REPORT_OPT_MONTH": "ສະຫຼຸບເດືອນ",
            "REPORT_OPT_NOCARD": "ບໍ່ມີການຮູດບັດ",
            "REPORT_OPT_OPSDELAY": "ຄວາມໄວຊັກຊ້າ ສຳ ລັບ {{value}} ນາທີ",
            "REPORT_OPT_OPSDELAY_NONE": "ບໍ່ມີເວລາຊັກຊ້າ",
            "REPORT_OPT_OVERSPEED": "ພຽງແຕ່ເກີນຄວາມໄວ",
            "REPORT_OPT_PARKTIME": "ເວລາຈອດລົດ",
            "REPORT_OPT_STATUS": "ສະຖານະພາບລົດ",
            "REPORT_OPT_TMS_STATE": "ສະຖານະການເຮັດວຽກ",
            "REPORT_OPT_USESPEED": "ກໍານົດຂອບເຂດຄວາມໄວ",
            "REPORT_SUMMARY_COUNT": "ເວລາສົ່ງຂໍ້ມູນ",
            "REPORT_SUMMARY_DURATION": "ເວລາ ນຳ ໃຊ້ທັງ ໝົດ",
            "REPORT_SUMMARY_LENGTH": "ໄລຍະທາງທັງ ໝົດ",
            "REPORT_SUMMARY_MAXSPEED": "ຄວາມໄວສູງສຸດ",
            "REPORT_SUMMARY_MAXTIME": "ໄລຍະເວລາທີ່ຍາວທີ່ສຸດ",
            "REPORT_SUMMARY_NOGPS": "ບໍ່ມີສັນຍານ GPS",
            "REPORT_SUMMARY_PARK": "ເຄື່ອງຈັກຈອດລົດ",
            "REPORT_SUMMARY_PARK_MAXTIME": "ໄລຍະເວລາຈອດລົດສູງສຸດ",
            "REPORT_SUMMARY_ROUTE": "ການເດີນທາງ",
            "REPORT_SUMMARY_SPEEDLIMIT": "ຄວາມໄວສູງກວ່າ ຈຳ ກັດ",
            "REPORT_SUMMARY_STOP": "ເຄື່ອງຈັກຈອດລົດ",
            "REPORT_TABLE_FUELAVG_ENGINE": "ຊົ່ວໂມງເຮັດວຽກ (ລິດ/ຊົ່ວໂມງ)",
            "REPORT_TABLE_FUELAVG_KM": "ໄລຍະທາງ (ກມ/ລິດ)",
            "REPORT_TITLE_DATERANGE": "ช่วงวันที่ {{date}}",
            "REPORT_TITLE_DEVICE": "ทะเบียน {{device}}",
            "REPORT_TITLE_DEVICE_ALL": "ทะเบียนทั้งหมด",
            "REPORT_TITLE_DEVICE_GROUP": "กลุ่ม {{group}}",
            "REPORT_VIEW_DEVICE": "ສະແດງອຸປະກອນ",
            "REPORT_VIEW_HEADER": "ສະແດງຫົວ",
            "REPORT_VIEW_TOTAL": "ສະແດງທັງໝົດ",
            "STATE_ABSENCE": "ลา",
            "STATE_BAD": "Bad",
            "STATE_DONE": "ดำเนินการแล้ว",
            "STATE_GOOD": "Good",
            "STATE_MODERATE": "Moderate",
            "STATE_PENDING": "รอดำเนินการ",
            "STATE_STRIKE": "หยุุด",
            "STATE_WAIT": "รอ",
            "STATE_WORK": "งาน",
            "TEXT_ACCESSTIME": "ໃຊ້ຄັ້ງສຸດທ້າຍ",
            "TEXT_ADD_POINT": "ຈຸດ ໝາຍ ປາຍທາງ",
            "TEXT_ADDRESS": "ທີ່ຢູ່",
            "TEXT_ALEART_MONEY_INPUT": "ກະລຸນາລະບຸຈໍານວນທີ່ຈະໄດ້ຮັບ.",
            "TEXT_ALERT_SELECT_DEVICE": "ກະລຸນາເລືອກລົດ",
            "TEXT_ALERT_SELECT_DRIVER": "ກະລຸນາຄົນຂັບລົດ",
            "TEXT_ALERT_SELECT_NOTIFY": "ກະລຸນາເລືອກການແຈ້ງເຕືອນ.",
            "TEXT_ALERT_TIME_ERROR": "ເວລາຜິດ",
            "TEXT_AMOUNT": "ຈໍານວນ",
            "TEXT_AMOUNT_LITER": "ຈໍານວນ",
            "TEXT_APPLY": "ສະໝັກ",
            "TEXT_AREA": "ພື້ນທີ່",
            "TEXT_ATTACHMENT": "Attachment",
            "TEXT_ATTACHMENT_IMAGE": "Attachment Image",
            "TEXT_AVG": "Avg",
            "TEXT_BIRTHDAY": "ວັນເກີດ",
            "TEXT_CLEAR": "ຈະແຈ້ງ",
            "TEXT_CLEAR_OLD": "ລຶບຂໍ້ມູນຕົ້ນສະບັບກ່ອນ.",
            "TEXT_CLEAR_SIGNATURE": "ລຶບລາຍເຊັນ",
            "TEXT_COMPANY": "ບໍລິສັດ",
            "TEXT_COPY": "Copy",
            "TEXT_COPY_FROM": "From",
            "TEXT_COPY_TO": "To",
            "TEXT_COST": "Cost",
            "TEXT_COUNT_DUPLICATE": "ซ้ำ {{count}} รายการ",
            "TEXT_COUNT_FOUND": "พบแล้ว {{count}} รายการ",
            "TEXT_COUNT_NOIMPORT": "นำเข้าไม่ได้ {{count}} รายการ",
            "TEXT_COUNT_SELECT": "เลือกแล้ว {{count}} รายการ",
            "TEXT_COUNT_TOTAL": "ຈຳ ນວນທັງ ໝົດ {{count}}",
            "TEXT_CREATETIME": "ເວລາສ້າງ",
            "TEXT_CREDIT": "Credit",
            "TEXT_CUSTOMER": "ລູກຄ້າ",
            "TEXT_DATA": "ຂໍ້ມູນ",
            "TEXT_DATE": "ວັນທີ",
            "TEXT_DATE_END": "ວັນທີສິ້ນສຸດ",
            "TEXT_DATE_FROM": "ວັນທີຈາກ",
            "TEXT_DATE_IN": "ວັນທີເຂົ້າ",
            "TEXT_DATE_INSTALL": "ວັນທີຕິດຕັ້ງ",
            "TEXT_DATE_OUT": "ວັນທີອອກ",
            "TEXT_DATERANGE": "ໄລຍະເວລາວັນທີ",
            "TEXT_DATE_START": "ວັນທີເລີ່ມຕົ້ນ",
            "TEXT_DATE_TO": "ເຖິງ",
            "TEXT_DEFAULT": "Default",
            "TEXT_DELAY": "ຊັກຊ້າ",
            "TEXT_DESC_DISTANCE": "ຈັດຈາກທີ່ໄກທີ່ສຸດກ່ອນ.",
            "TEXT_DETAIL": "ລາຍລະອຽດ",
            "TEXT_DEVICE": "ອຸປະກອນ",
            "TEXT_DEVICE_24H_DURATION": "ໃຊ້ 24 ຊົ່ວໂມງ.",
            "TEXT_DEVICE_COUNT": "ອຸປະກອນ",
            "TEXT_DEVICE_FROM": "ຈາກອຸປະກອນ",
            "TEXT_DEVICE_GROUP": "ກຸ່ມ",
            "TEXT_DEVICE_PARK_DURATION": "ເວລາຈອດລົດ",
            "TEXT_DEVICE_RUN_DURATION": "ໄລຍະເວລາຂັບຂີ່",
            "TEXT_DISABLE": "ປິດໃຊ້ງານ",
            "TEXT_DISTANCE": "ໄລຍະທາງ (ກິໂລແມັດ)",
            "TEXT_DISTANCE_S": "ໄລຍະ",
            "TEXT_DLT": "DLT",
            "TEXT_DOWNLOAD": "Download",
            "TEXT_DRIVER": "ຄົນຂັບລົດ",
            "TEXT_DRIVER_2": "ຄົນຂັບລົດ 2",
            "TEXT_DRIVER_CARD": "ບັດ",
            "TEXT_DRIVER_SCORE": "ການໃຫ້ຄະແນນຄົນຂັບ",
            "TEXT_DURATION": "ໄລຍະເວລາ",
            "TEXT_EMAIL": "ອີເມລ",
            "TEXT_EMPLOYEE": "ພະນັກງານ",
            "TEXT_ENABLE": "ເປີດໃຊ້",
            "TEXT_ENGINE_OFF": "ເຄື່ອງຈັກປິດ",
            "TEXT_ENGINE_ON": "ເຄື່ອງຈັກເປີດຢູ່",
            "TEXT_ENGINE_TIME": "ເວລາເລີ່ມຕົ້ນ",
            "TEXT_EVENT": "ເຫດການ",
            "TEXT_EXCEPT": "ຍົກເວັ້ນ",
            "TEXT_EXPIRE": "ຫມົດກໍານົດ",
            "TEXT_EXPIRE_NEAR": "ຫມົດກໍານົດ",
            "TEXT_EXPORT": "ສົ່ງອອກຂໍ້ມູນ",
            "TEXT_FIXPOINT": "Specify coordinates",
            "TEXT_FLASHVIEW_CODE": "ເບິ່ງລະຫັດ",
            "TEXT_FROM": "ຈາກ",
            "TEXT_FROM_TIME": "from {{date,St}}",
            "TEXT_FUELADD": "ປະລິມານນໍ້າມັນທີ່ເພີ່ມ (ລິດ)",
            "TEXT_FUELAVG_ENGINE": "ຊົ່ວໂມງເຮັດວຽກ (ລິດ/ຊົ່ວໂມງ)",
            "TEXT_FUELAVG_KM": "ໄລຍະທາງ (ກມ/ລິດ)",
            "TEXT_FUELMONEY": "ค่าน้ำมันที่ใช้/บาท",
            "TEXT_FUELPRICE": "ราคาน้ำมัน",
            "TEXT_FUEL_REMAIN": "ຈໍານວນນ້ໍາມັນທີ່ຍັງເຫຼືອ",
            "TEXT_FUELUSE": "ການ ນຳ ໃຊ້ນໍ້າມັນເຊື້ອໄຟ / ລິດ",
            "TEXT_FUELUSE_C": "ອັດຕາການບໍລິໂພກ (ກມ/ລິດ)",
            "TEXT_FUELUSE_S": "ນໍ້າມັນເຊື້ອໄຟ (ລິດ)",
            "TEXT_FUEL_VOLUME": "ປະລິມານນ້ໍາມັນ",
            "TEXT_FULLNAME": "ຊື່ - ນາມສະກຸນ",
            "TEXT_GENDER": "ເພດ",
            "TEXT_GPS": "GPS",
            "TEXT_GROUP": "ກຸ່ມ",
            "TEXT_GSM": "GSM",
            "TEXT_HEIGHT": "ຄວາມສູງ",
            "TEXT_HOWLONG": "ຍາວ",
            "TEXT_IMPORT": "ຂໍ້ມູນການ ນຳ ເຂົ້າ",
            "TEXT_INDEX": "ບໍ່.",
            "TEXT_IP": "IP",
            "TEXT_LABEL": "Label",
            "TEXT_LATITUDE": "ເສັ້ນຂະໜານ",
            "TEXT_LENGTH": "ຄວາມຍາວ",
            "TEXT_LINE": "Line",
            "TEXT_LIST": "ລາຍຊື່",
            "TEXT_LOCATION": "ສະຖານທີ່",
            "TEXT_LOCATION_POINT": "ພິກັດ",
            "TEXT_LOCATION_SHOW": "ສະແດງສະຖານທີ່ທັງ ໝົດ",
            "TEXT_LOCATION_START": "ສະຖານີເລີ່ມຕົ້ນ",
            "TEXT_LOGO": "ໂລໂກ້",
            "TEXT_LONGITUDE": "ເສັ້ນແວງ",
            "TEXT_MAX_SPEED": "ຄວາມໄວສູງສຸດ",
            "TEXT_MAX_SPEED_TIME": "ຄວາມໄວສູງສຸດ",
            "TEXT_MILEAGE": "ໄລຍະທາງ",
            "TEXT_MOBILE": "อุปกรณ์",
            "TEXT_MONEY": "ຈໍານວນ",
            "TEXT_MSISDN": "ເບີໂທລະສັບ",
            "TEXT_NAME": "ຊື່",
            "TEXT_NAMELAST": "ນາມສະກຸນ",
            "TEXT_NATION": "ປະເທດ",
            "TEXT_NOCARD_COUNT": "ไม่ได้รูดบัตร {{count}} ครั้ง",
            "TEXT_NOGPS": "ບໍ່ມີ gps (ຊົ່ວໂມງ)",
            "TEXT_NOGROUP": "ໂດຍບໍ່ມີກຸ່ມ",
            "TEXT_NONE": "ບໍ່ມີ",
            "TEXT_NONE_YET": "No data",
            "TEXT_NOSHOW": "ຢ່າສະແດງມັນອີກ.",
            "TEXT_NOSHOW_DAY": "ບໍ່ ຈຳ ເປັນຕ້ອງສະແດງອີກເທື່ອ ໜຶ່ງ ໃນມື້ນີ້",
            "TEXT_NOTE": "ຫມາຍເຫດ:",
            "TEXT_NOTIFY": "ບອກໂດຍ",
            "TEXT_NOTIFY_AND_SPEEDLIMIT": "ແລະຄວາມໄວຫຼາຍ",
            "TEXT_NOTIFY_CHANNEL": "Notification channels",
            "TEXT_NOTIFY_CHANNEL_MAIN": "ຊ່ອງທາງຫລັກ",
            "TEXT_NOTIFY_LAST": "ແຈ້ງເຕືອນລ້າສຸດ",
            "TEXT_NOUPDATE": "ຢຸດອຸປະກອນ (ຊົ່ວໂມງ)",
            "TEXT_NUMBER": "ຈຳນວນ",
            "TEXT_ONTIME": "ເມື່ອໃດ",
            "TEXT_ORDINAL": "Ordinal",
            "TEXT_PASSWORD": "ລະຫັດຜ່ານ",
            "TEXT_PASSWORD_CONFIRM": "ລະຫັດຜ່ານມາ",
            "TEXT_PAYMENT": "ວິທີການປະຫຍັດເງິນ",
            "TEXT_PERMIT_DATE": "ວັນທີອະນຸຍາດ",
            "TEXT_PLACE": "ສະຖານທີ່",
            "TEXT_PLACE_NEAR": "ໃກ້",
            "TEXT_PLAYBACK_COMPARE": "Compare previous data",
            "TEXT_POI": "POI",
            "TEXT_POI_BASE": "POI / กลุ่มรถ",
            "TEXT_POI_GROUP_READ": "ກໍານົດກຸ່ມຂອງ POIs ທີ່ຈະສະແດງ",
            "TEXT_POI_NOBASE": "ບໍ່ສະແດງກຸ່ມ POI/ລົດຂອງຕົນເອງ",
            "TEXT_POI_NOENTRY": "ຈຳ ນວນທີ່ເຂົ້າຊົມບໍ່ມີສະຖານີເຂົ້າ",
            "TEXT_POI_NOIN": "ບໍ່ສະແດງລົດທີ່ຈອດຢູ່ໃນ POI",
            "TEXT_POINT_END": "ຈຸດ ໝາຍ ປາຍທາງ",
            "TEXT_POINT_START": "ເລີ່ມຕົ້ນ",
            "TEXT_POI_STATION": "ຈຳ ນວນສະຖານທີ່ຢ້ຽມຢາມ",
            "TEXT_PORT": "ນຳເຂົ້າ / ສົ່ງອອກ",
            "TEXT_PRICE": "ລາຄາ",
            "TEXT_PROBLEM_NOTIFY": "ແຈ້ງການບັນຫາ",
            "TEXT_RADIUS": "ລັດສະໝີ",
            "TEXT_RAWDATA": "ມູນຄ່າຕົວຈິງ",
            "TEXT_REMAIN": "ຍັງເຫຼືອ",
            "TEXT_RESET": "ປັບຄ່າ",
            "TEXT_RESULT": "ຜົນການເຮັດວຽກ",
            "TEXT_ROUTE": "ເສັ້ນທາງ",
            "TEXT_ROWNUM": "ບໍ່.",
            "TEXT_SEARCH": "ຄົ້ນຫາ",
            "TEXT_SENDED": "ສົ່ງແລ້ວ",
            "TEXT_SHOW_PARKING": "ສະແດງການຢຸດ",
            "TEXT_SIGNATURE": "ລາຍເຊັນ",
            "TEXT_SIGNATURE_CUSTOMER": "ລາຍເຊັນຂອງລູກຄ້າ",
            "TEXT_SIGNATURE_USER": "ລາຍເຊັນຂອງພະນັກງານ",
            "TEXT_SMS": "ຂໍ້ຄວາມ",
            "TEXT_SORT": "Sort",
            "TEXT_SPEED": "ຄວາມໄວ",
            "TEXT_SPEED_S": "ໄວ",
            "TEXT_STATE": "ສະຖານະການປະຈຸບັນ",
            "TEXT_STATUS_ENGINE": "ສະຖານະເຄື່ອງຈັກ",
            "TEXT_STATUS_RUN": "ສະຖານະແລ່ນ",
            "TEXT_STATUS": "ສະຖານະການ",
            "TEXT_STEP": "Step",
            "TEXT_TELEPHONE": "ໂທລະສັບ",
            "TEXT_TIME": "ເວລາ",
            "TEXT_TIME_AT": "ຂໍ້ມູນທີ່ {{time}}",
            "TEXT_TIME_END": "ເວລາອອກ",
            "TEXT_TIME_IN": "ເວລາເຂົ້າ",
            "TEXT_TIME_OUT": "ເວລາອອກ",
            "TEXT_TIME_START": "ກະລຸນາໃສ່ເວລາ",
            "TEXT_TIME_STAY": "ເວລາ",
            "TEXT_TITLE": "ວິຊາ",
            "TEXT_TO": "ເຖິງ",
            "TEXT_TOTAL": "ລວມ",
            "TEXT_TOTAL_ALL": "Total All",
            "TEXT_TOTAL_DURATION": "ເວລາ ນຳ ໃຊ້ທັງ ໝົດ",
            "TEXT_TOTAL_PARK": "ເວລາຈອດລົດທາງອາກາດ",
            "TEXT_TOTAL_PARK10": "ບ່ອນຈອດລົດທາງອາກາດເກີນ 10 ນາທີ.",
            "TEXT_TOTAL_PARK_MAX": "ເວລາເລີ່ມຕົ້ນທີ່ຍາວທີ່ສຸດ",
            "TEXT_TOTAL_PARK_MAX_DATE": "ເວລາຫວ່າງທີ່ດົນທີ່ສຸດ",
            "TEXT_TOTAL_SPEED": "ໃນໄລຍະເວລາຄວາມໄວ",
            "TEXT_TYPE": "Type",
            "TEXT_UPDATETIME": "ການປັບປຸງຫຼ້າສຸດ",
            "TEXT_UPLOAD": "Upload",
            "TEXT_USE_DAY": "Use per Day",
            "TEXT_USER": "ຜູ້ໃຊ້",
            "TEXT_USERAGENT": "ຕົວແທນ",
            "TEXT_USERNAME": "ຊື່ຜູ້ໃຊ້",
            "TEXT_WAYPOINT": "Waypoint",
            "TEXT_WHEN": "ເມື່ອໃດ",
            "TEXT_WIDTH": "ກວ້າງ",
            "TEXT_WORK": "ເຮັດວຽກ",
            "TMS_INFO_DUP_DEVICE": "ລົດຍັງໃຊ້ໃນຍຸກນີ້.",
            "TMS_INFO_DUP_EMPLOYEE": "ຄົນຂັບລົດຄົນນີ້ມີວຽກເຮັດຢູ່ແລ້ວ. ໃນລະຫວ່າງເວລານີ້",
            "TMS_INFO_DUP_EMPLOYEE2": "ໄລຍະເວລາເຮັດວຽກແມ່ນກຳນົດໃຫ້ກົງກັບມື້ພັກຂອງພະນັກງານ.",
            "TMS_MENU_CALENDAR": "Calendar",
            "TMS_MENU_LIST": "Schedule",
            "TMS_MENU_SEARCH": "Search",
            "TMS_MONEY_BILL": "Bill",
            "TMS_MONEY_CASH": "Cash",
            "TMS_MONEY_NO": "No.",
            "TMS_MONEY_PENDING": "Pending",
            "TMS_MONEY_TRANSFER": "Transfer",
            "ONLY_AREA_IN": "ພຽງແຕ່ຢູ່ໃນພື້ນທີ່",
            "ONLY_AREA_OUT": "ຢູ່ນອກພື້ນທີ່ເທົ່ານັ້ນ",
            "ONLY_POI_IN": "ພຽງແຕ່ຢູ່ໃນ POI",
            "ONLY_POI_OUT": "ຢູ່ນອກ POI ເທົ່ານັ້ນ",
            "TRACKING_DEVICE_CARD": "Driver Card",
            "TRACKING_DEVICE_SUSPEND_BODY": "ອຸປະກອນຕໍ່ໄປນີ້ຈະ ໝົດ ອາຍຸຫຼືແລ້ວ ໝົດ ອາຍຸແລ້ວ. ກະລຸນາຕິດຕໍ່ຫາພວກເຮົາເພື່ອຂະຫຍາຍການບໍລິການຂອງພວກເຮົາຖ້າບໍ່ດັ່ງນັ້ນ, ລະບົບຈະລຶບອຸປະກອນຕ່າງໆໂດຍອັດຕະໂນມັດ.",
            "TRACKING_DEVICE_SUSPEND_HEAD": "ການແຈ້ງເຕືອນ ໝົດ ອາຍຸຂອງອຸປະກອນ",
            "TRACKING_MENU_ALERT": "ແຈ້ງເຕືອນ",
            "TRACKING_MENU_AREA": "ພື້ນທີ່",
            "TRACKING_MENU_COMMAND": "Command",
            "TRACKING_MENU_DEVICE": "Device Info",
            "TRACKING_MENU_NEAR": "ໃກ້",
            "TRACKING_MENU_NOW": "ເວລາຈິງ",
            "TRACKING_MENU_PLAYBACK": "ການຫຼິ້ນຄືນ",
            "TRACKING_MENU_POI": "POI",
            "TRACKING_PLAYBACK_VAD_NO": "ບໍ່ພົບບັນທຶກການຂັບຂີ່",
            "TRACKING_POI_INFO_ICON": "ໄອຄອນ",
            "TRACKING_STATUS_FAST": "ໄວ",
            "TRACKING_STATUS_OFF": "ປິດ",
            "TRACKING_STATUS_PARK": "ສວນສາທາລະນະ",
            "TRACKING_STATUS_RUN": "ແລ່ນ",
            "TRACKING_STATUS_STOP": "ຢຸດ",
            "TRACKING_STATUS_TOWER": "ເສົາອາກາດ",
            "UNIT_BATH": "ບາດ",
            "UNIT_BPK": "ບາດ / ກິໂລແມັດ",
            "UNIT_BPL": "ບາດ / ລິດ",
            "UNIT_COUNT": "ເທື່ອ",
            "UNIT_DAY": "ມື້",
            "UNIT_HOUR": "ຊົ່ວໂມງ",
            "UNIT_KM": "ກິໂລແມັດ",
            "UNIT_KMPH": "ກິໂລແມັດ / ຊົ່ວໂມງ",
            "UNIT_KMPL": "ກິໂລແມັດ / ລິດ",
            "UNIT_LITER": "ລິດ",
            "UNIT_LPH": "ລິດ / ຊົ່ວໂມງ",
            "UNIT_METRE": "ແມັດ",
            "UNIT_MINUTE": "ນາທີ",
            "UNIT_MONTH": "ເດືອນ",
            "UNIT_SECOND": "ຄັ້ງທີສອງ",
            "UNITTEXT_NUMBER": "รายการที่ {{number}}",
            "USER_API_ADD": "ສ້າງລະຫັດໃຫມ່",
            "USER_API_EMPTY": "ລະຫັດ API ຍັງບໍ່ທັນໄດ້ຖືກສ້າງຂຶ້ນເທື່ອ.",
            "USER_DEVICE_FIELD_CHART": "ເສັ້ນສະແດງນໍ້າມັນເຊື້ອໄຟ",
            "USER_DEVICE_FIELD_CHART_HELP": "ຈຸດເດັ່ນເພື່ອໃຫ້ກວມເອົາຕໍາ່ສຸດແລະສູງສຸດຂອງກາຟ",
            "USER_DEVICE_FIELD_MAX": "ໄລຍະເວລາສູງສຸດ",
            "USER_DEVICE_FIELD_MIN": "ໄລຍະເວລາຕ່ ຳ ສຸດ",
            "USER_DEVICE_FIELD_REVERSE": "ຕໍາ່ສຸດທີ່ຈັບສະຫຼັບແລະສູງສຸດ",
            "USER_DEVICE_FIELD_TELEPHONE": "ມືຖື",
            "USER_DEVICE_FIELD_TRACKER": "ຮູບແບບການຕິດຕາມ",
            "USER_DEVICE_FORM_EDIT_HEAD": "ປ່ຽນລາຍລະອຽດຂອງອຸປະກອນ",
            "USER_DEVICE_FORM_MARKER_HEAD": "ການຕັ້ງຄ່າເຄື່ອງ ໝາຍ",
            "USER_DEVICE_FORM_OIL_HEAD": "ກຳ ນົດກາຟຟືນ",
            "USER_DEVICE_TABLE_ALERT_WHEN": "ເມື່ອໃດ",
            "USER_DRIVER_VAD_EMPTY": "ກະລຸນາພິມຊື່ຜູ້ຂັບຂີ່",
            "USER_FORM_ADD_HEAD": "ເພີ່ມຂໍ້ມູນ",
            "USER_FORM_SUMMARY_SEND": "ສົ່ງລາຍງານທຸກໆວັນ",
            "USER_INFO_CHECKPOINT": "เครดิต CheckPoint",
            "USER_INFO_SMS": "ເຄຣດິດ SMS",
            "USER_MENU_API": "ຈັດການ API",
            "USER_MENU_AREA": "Area",
            "USER_MENU_CHECKPOINT": "Checkpoint",
            "USER_MENU_COMMAND": "ສົ່ງ Command",
            "USER_MENU_COMPANY": "ບໍລິສັດ",
            "USER_MENU_CUSTOMER": "#REF!",
            "USER_MENU_DASHBOARD": "ຫນ້າທໍາອິດ",
            "USER_MENU_DEVICE": "ອຸປະກອນຕ່າງໆ",
            "USER_MENU_EMPLOYEE": "ພະນັກງານ",
            "USER_MENU_EMPLOYEE_DAY": "ມື້ອອກ",
            "USER_MENU_FLASHVIEW": "ບັນຊີຊົ່ວຄາວ",
            "USER_MENU_FUEL": "ເຕີມນ້ຳມັນ",
            "USER_MENU_LOG": "#REF!",
            "USER_MENU_LOG_CHECKPOINT": "ประวัติการใช้เครดิต CheckPoint",
            "USER_MENU_LOG_SIGN": "ປະຫວັດການເຂົ້າສູ່ລະບົບ",
            "USER_MENU_LOG_SMS": "ປະຫວັດການສົ່ງ SMS",
            "USER_MENU_MOBILE": "Mobile",
            "USER_MENU_NOTIFICATION": "ແຈ້ງການ",
            "USER_MENU_POI": "POI",
            "USER_MENU_PROFILE": "ຂໍ້ມູນຜູ້ໃຊ້",
            "USER_MENU_REPORT": "Report",
            "USER_MENU_SUBUSER": "Sub-user",
            "USER_NOTIFY_AD": "ຄ່າຈາກເຊັນເຊີ",
            "USER_NOTIFY_AREA": "ພື້ນທີ່",
            "USER_NOTIFY_DRIVER_CARD": "ຮູດບັດ",
            "USER_NOTIFY_EVENT": "ເຫດການ",
            "USER_NOTIFY_PARK_LIMIT": "ບ່ອນຈອດລົດ",
            "USER_NOTIFY_POI": "POI",
            "USER_NOTIFY_SPEED_LIMIT": "ເກີນຄວາມໄວ",
            "USER_NOTIFY_STATE": "ສະຖານະເຄື່ອງ",
            "USER_POI_FIELD_EMAIL": "ແຈ້ງເຕືອນອີເມວ",
            "USER_POI_FIELD_IN": "ເຂົ້າສູ່ການເຕືອນ",
            "USER_POI_FIELD_OUT": "ອອກຈາກການແຈ້ງເຕືອນ",
            "USER_POI_FIELD_SM": "ຮູບສັນຍາລັກຂະຫນາດນ້ອຍ",
            "USER_POI_LOCATION": "ສະຖານທີ່",
            "USER_PROFILE_FAIL_PASS_CONFIRM": "ລະຫັດຜ່ານມາບໍ່ຖືກຕ້ອງ",
            "USER_PROFILE_FAIL_PASS_LENGTH": "ລະຫັດຜ່ານໃໝ່ສັ້ນເກີນໄປ.",
            "USER_PROFILE_FAIL_PASS_RE": "ການພິມລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
            "USER_PROFILE_FAIL_USER_DUP": "ຊື່ຜູ້ໃຊ້ຊໍ້າກັນ.",
            "USER_PROFILE_FORM_PASSWORD_HEAD": "ການປ່ຽນລະຫັດຜ່ານ",
            "USER_SETTING_TAGS_NOTE": "ໃຊ້ Enter ເພື່ອເພີ່ມປ້າຍກຳກັບໃໝ່.",
            "USER_SETTING_TMSPAY": "ຊ່ອງທາງການຈ່າຍເງິນ",
            "USER_TEXT_DOCUMENT_GOV": "ເອກະສານຂອງລັດຖະບານ",
            "USER_TEXT_EMAIL_NOTE": "ໃສ່ຫຼາຍກວ່າ 1 ອີເມວ, ກະລຸນາແຍກອອກໂດຍ,",
            "USER_TEXT_IDCARD": "ເລກບັດປະຈຳຕົວ",
            "USER_TEXT_LICENCE_GOV": "ອອກທີ່",
            "USER_TEXT_LICENCE_NUMBER": "ໝາຍເລກໃບຂັບຂີ່",
            "USER_TEXT_LICENCE_TYPE": "ປະເພດໃບອະນຸຍາດ",
            "USER_TEXT_PASSWORD_NOTE": "ຖ້າເຈົ້າບໍ່ຕ້ອງການປ່ຽນລະຫັດຜ່ານຂອງເຈົ້າ, ຢ່າໃສ່ມັນ.",
            "USER_TEXT_PREFIX": "ຫົວຂໍ້",
            "USER_TEXT_REGISTRAR": "ທະບຽນ",
            "USER_TEXT_REPASSWORD": "ຢືນຢັນລະຫັດຜ່ານ"
        }
    },
    "zh": {
        "translation": {
            "TEST_TEXT": "命令示例",
            "API_NAME_DEVICE_ALL": "请求帐户中所有汽车的列表",
            "API_NAME_DEVICE_GET": "从 ID 请求当前车辆状态",
            "API_NAME_DEVICE_LOG": "查看驾驶记录",
            "API_NAME_POI_ALL": "请求您帐户中所有 POI 的列表",
            "API_NAME_POI_GET": "从 ID 请求 POI",
            "API_NAME_REPORT_LENGTH": "跑步距离报告",
            "API_NAME_REPORT_RS232": "刷卡报告",
            "API_NAME_REPORT_WORKLOAD": "报告工作时间",
            "API_NAME_VERSION": "API版本号",
            "API_PARAM_ADDRESS": "显示地址（输入1显示地址，报表输出效率会降低）",
            "API_PARAM_DEVICE": "设备编号",
            "API_PARAM_END": "结束时间",
            "API_PARAM_LIMIT": "所需物品数量",
            "API_PARAM_ORDER": "排序",
            "API_PARAM_START": "开始时间",
            "API_RESP_DEVICES": "汽车清单",
            "API_RESP_DEVICES_ID": "车辆ID号",
            "API_RESP_OK": "API工作状态",
            "API_RESP_VERSION": "API版本号",
            "APP_FINGERPRINT_SCAN": "扫描指纹以检查权限",
            "APP_PIN": "输入 PIN 码",
            "APP_PIN_CONFIRM": "确认 PIN 码",
            "APP_PIN_ERROR": "Pin 码不正确。",
            "APP_PIN_ERROR_NOTMATCH": "PIN 码不匹配",
            "APP_PIN_ERROR_NOTOLD": "新 PIN 码不得与旧 PIN 码相同。",
            "APP_PIN_NEW": "输入新的 PIN 码。",
            "APP_PIN_OLD": "输入旧的 PIN 码。",
            "APP_SETTING_APIMOON": "备份服务器",
            "APP_SETTING_BETA": "使用测试版",
            "APP_SETTING_DARK": "夜间模式",
            "APP_SETTING_FINGERPRINT": "指纹扫描仪",
            "APP_SETTING_PIN": "设置 PIN 码",
            "APP_SETTING_PIN_CHANGE": "更改 PIN 码",
            "APP_SETTING_PIN_DELETE": "删除 PIN 码",
            "APP_SETTING_PIN_ON": "打开 PIN 锁屏幕。",
            "BUTTON_ADD": "添加新内容",
            "BUTTON_GEN_AREA": "创建区域",
            "BUTTON_ADD_EMP_DAY": "添加休假",
            "BUTTON_CANCEL": "取消",
            "BUTTON_CHECK": "查看信息",
            "BUTTON_CLEAR": "清除",
            "BUTTON_COMMAND_SEND": "确认发送订单",
            "BUTTON_CONFIRM": "确认",
            "BUTTON_CONFIRM_PRIVACY": "接受",
            "BUTTON_DELETE": "删除",
            "BUTTON_DOWNLOAD_CERT": "证书",
            "BUTTON_EDIT": "编辑",
            "BUTTON_HIDE": "隐藏",
            "BUTTON_LOGIN": "登录",
            "BUTTON_LOGOUT": "退出",
            "BUTTON_MONEY_RECEIVE": "收到钱",
            "BUTTON_MONEY_UNRECEIVE": "未收到钱",
            "BUTTON_NOTIFY": "通知",
            "BUTTON_NOTIFY_SETTING": "设置通知",
            "BUTTON_OK": "好的",
            "BUTTON_PORT": "导入/导出",
            "BUTTON_PRINT": "打印",
            "BUTTON_RESET": "重置",
            "BUTTON_RUN": "执行",
            "BUTTON_SAVE": "保存数据",
            "BUTTON_SAVE_SORT": "排序记录",
            "BUTTON_SEARCH": "搜索",
            "BUTTON_SKIP": "跳过",
            "BUTTON_TEMPLATE_LOAD": "从模板调用",
            "BUTTON_TEMPLATE_SAVE": "保存到模板",
            "BUTTON_UNHIDE": "取消隐藏",
            "BUTTON_UPDATE_APP": "更新",
            "BUTTON_UPLOAD": "上传",
            "CONFIRM_DELETE": "确认删除",
            "CONFIRM_DELETE_SELECT": "确认删除所选数据。",
            "CONFIRM_IMPORT": "ยืนยันการนำเข้าข้อมูล",
            "CONFIRM_LOGOUT": "退出？",
            "CONFIRM_MONEY_RECEIVE": "确认收到钱！？",
            "CONFIRM_MONEY_UNRECEIVE": "取消收款！？",
            "CONFIRM_RESET": "确认重置！？",
            "DALERT_COMMAND_SEND_ERROR": "无法发送数据，请重试。",
            "DALERT_DELETE_FAIL": "无法删除数据",
            "DALERT_EDIT_NOTFOUND": "未找到更新信息",
            "DALERT_HISTORY_EMPTY": "未发现追溯历史：",
            "DALERT_HISTORY_NOMOVE": "车不动",
            "DALERT_MUST_DEVICE": "一定要选车",
            "DALERT_REPORT_EMPTY": "未找到报告数据",
            "DALERT_SAVE_FAIL": "无法记录数据",
            "DALERT_USERNAME_DUP": "该用户名已在系统中。",
            "DATE_DAY_ALL": "全天",
            "DATE_DAY_0": "星期日",
            "DATE_DAY_1": "星期一",
            "DATE_DAY_2": "星期二",
            "DATE_DAY_3": "星期三",
            "DATE_DAY_4": "星期四",
            "DATE_DAY_5": "星期五",
            "DATE_DAY_6": "星期六",
            "DATE_DAY_SHORT_0": "S",
            "DATE_DAY_SHORT_1": "M",
            "DATE_DAY_SHORT_2": "Tu",
            "DATE_DAY_SHORT_3": "W",
            "DATE_DAY_SHORT_4": "Th",
            "DATE_DAY_SHORT_5": "F",
            "DATE_DAY_SHORT_6": "Sat",
            "DATE_MONTH_1": "一月",
            "DATE_MONTH_2": "二月",
            "DATE_MONTH_3": "三月",
            "DATE_MONTH_4": "四月",
            "DATE_MONTH_5": "五月",
            "DATE_MONTH_6": "六月",
            "DATE_MONTH_7": "七月",
            "DATE_MONTH_8": "八月",
            "DATE_MONTH_9": "九月",
            "DATE_MONTH_10": "十月",
            "DATE_MONTH_11": "十一月",
            "DATE_MONTH_12": "十二月",
            "DATE_MONTH_LAST": "上个月",
            "DATE_MONTH_SHORT_1": "Jan",
            "DATE_MONTH_SHORT_2": "Feb",
            "DATE_MONTH_SHORT_3": "Mar",
            "DATE_MONTH_SHORT_4": "Apr",
            "DATE_MONTH_SHORT_5": "May",
            "DATE_MONTH_SHORT_6": "Jun",
            "DATE_MONTH_SHORT_7": "Jul",
            "DATE_MONTH_SHORT_8": "Aug",
            "DATE_MONTH_SHORT_9": "Sep",
            "DATE_MONTH_SHORT_10": "Oct",
            "DATE_MONTH_SHORT_11": "Nov",
            "DATE_MONTH_SHORT_12": "Dec",
            "DATE_MONTH_THIS": "本月",
            "DATE_S": "{{日期，S}}",
            "DATE_ST": "{{日期，圣}}",
            "DATE_TODAY": "今天",
            "DATE_TRACK": "{{日期,d MMM HH:mm}}",
            "DATE_YESTERDAY": "昨天",
            "DCONFIRM_CHANGE_LANGUAGE": "您想要更改语言",
            "DCONFIRM_LOGOUT": "您想要退出",
            "DCONFIRM_SEND_LOG": "确认数据提交",
            "TEXT_ALL_PLAY": "播放所有相机图像",
            "TEXT_ALL_CLOSE": "全部关闭",
            "TEXT_ALL_PAUSE": "停止播放所有图像。",
            "DEVICE_COMMAND": "命令",
            "DEVICE_COMMAND_CRONJOB": "设定工作时间",
            "DEVICE_COMMAND_RESPOND": "结果",
            "DEVICE_COMMAND_RESPTIME": "响应时间",
            "DEVICE_COMMAND_SEND": "发送命令",
            "DEVICE_COMMAND_SENDING": "กำลังจะส่งคำสั่ง",
            "DEVICE_COMMAND_SENDTIME": "发送时间",
            "DEVICE_DVR_CHANNEL": "กล้องตัวที่ {{频道}}",
            "DEVICE_MAXVOLUME": "最大装载体积（立方米）",
            "DEVICE_MAXWEIGHT": "最大可承载重量",
            "DEVICE_NOTIFY_AD": "在 {{ad}} {{to}} {{value}}",
            "DEVICE_NOTIFY_AREA_LOCATION": "指定区域",
            "DEVICE_NOTIFY_DLT": "แจ้งเตือนกรมขนส่ง",
            "DEVICE_NOTIFY_NOCARD_DURATION": "在不刷卡的情况下运行设备 {{duration}} 分钟。",
            "DEVICE_NOTIFY_PARK_DURATION": "将发动机停放 {{duration}} 分钟。",
            "DEVICE_NOTIFY_POIALL": "所有波伊",
            "DEVICE_NOTIFY_POI_IN": "เมื่อเข้า {{poi}}",
            "DEVICE_NOTIFY_POI_OUT": "เมื่อออกจาก {{poi}}",
            "DEVICE_NOTIFY_POISTOP_IN": "在 POI 停车超过 {{duration}} 分钟",
            "DEVICE_NOTIFY_RS232_NO": "ติดเครื่องแต่ไม่รูดบัตรนานเกิน {{duration}}นาที",
            "DEVICE_NOTIFY_SPEEDLIMIT": "当速度超过限制时",
            "DEVICE_NOTIFY_STATE": "在 {{state}} 更改为 {{to}}",
            "DEVICE_TEXT_FUELUSE": "燃料使用",
            "DEVICE_TEXT_FUELVOLUME": "油箱容积",
            "DEVICE_TEXT_MODEL": "模型",
            "DEVICE_TEXT_NOTIFY": "通知",
            "DEVICE_TEXT_SPEEDLIMIT": "限速",
            "DIALOG_ADD": "添加",
            "DIALOG_LANGUAGE": "更改语言",
            "EVENT_1": "引擎和输入 1 激活",
            "EVENT_10": "引擎和输入 2 未激活",
            "EVENT_109": "出租车计价器活动",
            "EVENT_11": "引擎和输入 3 未激活",
            "EVENT_1101": "进入兴趣点",
            "EVENT_1102": "离开兴趣点",
            "EVENT_12": "引擎和输入 4 未激活",
            "EVENT_1201": "停车超过10分钟",
            "EVENT_1202": "开启设备但刷卡时间不要超过10分钟。",
            "EVENT_129": "急刹车",
            "EVENT_13": "引擎和输入 5 未激活",
            "EVENT_130": "剧烈加速",
            "EVENT_133": "空闲超时",
            "EVENT_134": "空闲恢复",
            "EVENT_135": "疲劳驾驶",
            "EVENT_136": "疲劳驾驶后充分休息",
            "EVENT_138": "速度恢复",
            "EVENT_17": "电池电量低",
            "EVENT_18": "低外部功率",
            "EVENT_19": "超速行驶",
            "EVENT_2": "引擎和输入 2 激活",
            "EVENT_20": "输入地理围栏",
            "EVENT_21": "退出地理围栏",
            "EVENT_22": "外部电源开启",
            "EVENT_23": "外部电源关闭",
            "EVENT_24": "无 GPS 信号",
            "EVENT_25": "获取 GPS 信号",
            "EVENT_26": "进入睡眠状态",
            "EVENT_27": "退出睡眠",
            "EVENT_28": "GPS 天线切断",
            "EVENT_29": "设备重启",
            "EVENT_3": "引擎和输入 3 激活",
            "EVENT_30": "影响",
            "EVENT_31": "心跳报告",
            "EVENT_32": "标题变更报告",
            "EVENT_33": "距离间隔报告",
            "EVENT_34": "当前位置报告",
            "EVENT_35": "时间间隔报告",
            "EVENT_36": "拖车警报",
            "EVENT_37": "射频识别",
            "EVENT_39": "照片",
            "EVENT_4": "引擎和输入 4 激活",
            "EVENT_40": "关闭电源按钮",
            "EVENT_41": "停止移动",
            "EVENT_42": "开始移动",
            "EVENT_44": "GSM 干扰",
            "EVENT_5": "引擎和输入 5 激活",
            "EVENT_50": "温度高",
            "EVENT_51": "温度低",
            "EVENT_52": "满油",
            "EVENT_53": "低燃油",
            "EVENT_54": "燃油盗窃",
            "EVENT_56": "武装 (T36x)",
            "EVENT_57": "解除武装 (T36x)",
            "EVENT_58": "车辆盗窃 (T36x)",
            "EVENT_63": "无 GSM 干扰",
            "EVENT_65": "按输入 1 (SOS) 拨打电话",
            "EVENT_66": "按输入2拨打电话",
            "EVENT_67": "按输入3拨打电话",
            "EVENT_68": "按输入4拨打电话",
            "EVENT_69": "按输入5拨打电话",
            "EVENT_70": "拒绝来电",
            "EVENT_71": "通过电话报告位置",
            "EVENT_72": "自动接听来电",
            "EVENT_73": "监听（语音监听）",
            "EVENT_78": "影响",
            "EVENT_82": "满油",
            "EVENT_83": "超传感器跌落",
            "EVENT_86": "刷卡离开",
            "EVENT_9": "引擎和输入 1 未激活",
            "EVENT_90": "向左急转",
            "EVENT_90_B": "急转弯",
            "EVENT_91": "向右急转",
            "EVENT_HIK_643": "GPS数据报告",
            "EVENT_HIK_644": "前车碰撞警告",
            "EVENT_HIK_645": "短车距警告",
            "EVENT_HIK_646": "车道偏离警告",
            "EVENT_HIK_647": "行人碰撞警告",
            "EVENT_HIK_648": "超速警告",
            "EVENT_HIK_649": "盲点检测警告",
            "EVENT_HIK_650": "吸烟",
            "EVENT_HIK_651": "在电话中",
            "EVENT_HIK_652": "疲劳驾驶",
            "EVENT_HIK_653": "分心驾驶",
            "EVENT_HIK_654": "没有系好安全带",
            "EVENT_HIK_655": "视频篡改",
            "EVENT_HIK_656": "紧急按钮警报",
            "EVENT_HIK_657": "急转弯",
            "EVENT_HIK_658": "急刹车",
            "EVENT_HIK_659": "急刹车",
            "EVENT_HIK_660": "滚下",
            "EVENT_HIK_661": "超速",
            "EVENT_HIK_662": "碰撞",
            "EVENT_HIK_663": "进入栅栏",
            "EVENT_HIK_664": "偏差",
            "EVENT_HIK_665": "出口围栏",
            "FAB_BOUND": "边界",
            "FAB_GO_LOCATION": "当前位置",
            "FAB_MAP_SAT": "卫星地图",
            "FAB_MAP_TRAFFIC": "交通",
            "FAB_POI_SHOW": "显示兴趣点",
            "FAB_REFRESH": "刷新",
            "FORM_CHECK_ALL": "全选",
            "FORM_CHECK_NONE": "不选择",
            "FORM_SELECT": "-  选择  -",
            "FORM_SELECT_ALL": "-  全部  -",
            "FORM_SELECT_NONE": "-  没有任何  -",
            "GROUPBY_DAY": "白天",
            "GROUPBY_MONTH": "按月",
            "GROUPBY_WEEK": "按周",
            "INFO_CLOSEAPP": "再次按返回关闭应用程序。",
            "INFO_COMMAND_RESPONDED": "服务器响应了",
            "INFO_COMMAND_RESPOND_TEXT": "响应状态",
            "INFO_COMMAND_SENDED": "数据发送",
            "INFO_COMMAND_SENDING": "发送数据",
            "INFO_COMMAND_STATUS": "发送状态",
            "INFO_COMMAND_WAIT": "正在回复...",
            "INFO_DATE_REQ": "请先选择日期。",
            "INFO_DROPED": "删除完成",
            "INFO_DROP_FAIL": "无法删除数据",
            "INFO_DROP_NOTFOUND": "删除未找到的信息",
            "INFO_DVR_STATUS_FAIL": "硬盘录像机离线",
            "INFO_EXPIRE_DETAIL": "以下设备即将过期或已达到过期日期。请联系我们延长服务，否则系统将自动删除该设备。",
            "INFO_EXPIRE_NOTIFY": "设备过期提醒",
            "INFO_LOGOUT": "退出",
            "INFO_MONEY_JOB": "工作收入",
            "INFO_MONEY_RECEIVE": "收到钱",
            "INFO_MONEY_UNRECEIVE": "钱已经取消了。",
            "INFO_NOTFOUND": "未找到数据",
            "INFO_NOTIFY_DUPLICATE": "已收到此通知。",
            "INFO_REPORT_WAIT": "请稍等。生成报告。",
            "INFO_SAVED": "数据已保存",
            "INFO_SAVE_DATA_INCOMPLETE": "请填写信息",
            "INFO_SAVE_FAIL": "无法保存",
            "INFO_SAVE_NOTFOUND": "未找到保存信息",
            "INPUT_POI_NAME": "您的兴趣点位置",
            "LABEL_PIN_CREATE": "设置应用程序 PIN 码",
            "LOGIN_SUCCESS": "登录成功",
            "LOGIN_TITLE": "登录",
            "LOGIN_VALIDATE_FAIL": "用户名或密码不正确",
            "MA_MENU_HISTORY": "ประวัติ",
            "MA_MENU_LIST": "警报列表",
            "MA_MENU_SERVICE": "服务",
            "MA_MENU_SUMMARY": "维修报告",
            "MA_MENU_TEMPLATE": "模板",
            "MA_MODE_DURATION": "指定持续时间",
            "MA_MODE_LENGTH": "行驶距离",
            "MA_MODE_WORKTIME": "工作时间",
            "MA_NOTIFY": "确定汽车 {{device}} 的维护 {{name}}",
            "MA_TEXT_CURRENT": "当前的",
            "MA_TEXT_CURRENT_UNIT": "当前{{值}} {{单位}}",
            "MA_TEXT_DATE_IN": "送车日期",
            "MA_TEXT_DATE_OUT": "取回车辆日期",
            "MA_TEXT_GARAGE": "车库",
            "MA_TEXT_INTERVAL": "提醒每个",
            "MA_TEXT_INTERVAL_UNIT": "每 {{value}} {{unit}} 发出警报",
            "MA_TEXT_LIST": "维修清单",
            "MA_TEXT_MODE": "米按",
            "MA_TEXT_REMAIN": "当 {{value}} {{unit}} 时发出警报",
            "MENU_CONTROLROOM": "控制室",
            "MENU_DEVICE": "设备",
            "MENU_DISTANCE": "措施",
            "MENU_HELP": "帮助",
            "MENU_HISTORY": "回放",
            "MENU_LOGOUT": "退出",
            "MENU_MA": "维护",
            "MENU_MAP": "地图",
            "MENU_MOBILE": "移动的",
            "MENU_MOBILE_DVR": "DVR 硬盘录像机",
            "MENU_MOBILE_INFO": "信息",
            "MENU_MOBILE_MAP": "地图",
            "MENU_MOBILE_NOTIFY": "通知",
            "MENU_MODULE": "系统",
            "MENU_MODULE_CHECKPOINT": "检查站",
            "MENU_MODULE_MA": "维护",
            "MENU_MODULE_REVENUE": "运输收入",
            "MENU_MODULE_TMS": "车队系统",
            "MENU_MODULE_TMSROUTE": "经颅磁刺激路线",
            "MENU_POI": "波伊",
            "MENU_PRIVACY": "隐私政策",
            "MENU_REPORT": "报告",
            "MENU_RESELLER": "经销商",
            "MENU_SETTING": "环境",
            "MENU_TOOL": "工具",
            "MENU_TOOL_POI": "测量 POI 距离",
            "MENU_TRACKING": "追踪",
            "MENU_TRACKING_TAXI": "轨道出租车",
            "NOTE_FUEL_FULL": "加油交易将被正确报告。在每次加油的情况下，必须将油箱加满。并且只知道填充的时间",
            "NOTE_FUEL_VOLUME": "油品清单将被正确报告。油箱尺寸仅为正方形的情况",
            "NOTE_FUEL_VOLUME_REPORT": "每次添加汽油时，必须关闭发动机。",
            "NOTE_PORTCLEAR": "所有数据都将丢失。请在点击前做好备份。",
            "NOTIFY_AD": "{{设备}} {{广告}} {{到}} {{值}}（{{当前}}）",
            "NOTIFY_AREA_IN": "{{device}}进入{{area}} {{address}}",
            "NOTIFY_AREA_OUT": "{{device}} 离开 {{area}}（{{duration}}）{{address}}",
            "NOTIFY_DLT_NOCARD": "{{device}} 驾驶员滑动发动机后部的时间不超过 10 分钟。",
            "NOTIFY_DLT_OVERTIME": "{{device}} 行驶超过 4 小时。",
            "NOTIFY_DLT_STOP": "{{{device}}หยุดหยุดหยุดข้อมูลไปกรมกรมการการขนส่งทางบกบกทางบกเกินบกวันวันวันวันจีพีเอสกรุณาตรวจสอบอุปกรณ์จีพีเอสจีพีเอสจีพีเอสให้ให้ให้ให้ให้ให้ทำทำทำงานงานงานงานงานปกติปกติปกติก่อนก่อนนำก่อนก่อนนำนำก่อนนำนำนำรถรถนำรถรถนำรถนำนำนำรถนำรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถรถ",
            "NOTIFY_DRIVER_CARD": "{{device}}长时间贴在机器上但不刷卡{{month}}分钟",
            "NOTIFY_EVENT": "{{设备}} {{事件}}，{{poi}} {{地址}}",
            "NOTIFY_LABEL_EMAIL": "通过电子邮件发送通知",
            "NOTIFY_LABEL_LINE": "通过 Line 发送通知",
            "NOTIFY_LABEL_PUSH": "通过App发送通知",
            "NOTIFY_LABEL_SMS": "通过短信发送通知",
            "NOTIFY_PARK_LIMIT": "{{device}} 停车超过 {{month}} 分钟，{{poi}} {{address}}",
            "NOTIFY_POI_IN": "{{device}} 输入 {{poi}} {{address}}",
            "NOTIFY_POI_OUT": "{{device}} 离开 {{poi}} {{address}}",
            "NOTIFY_POISTOP": "{{device}} 在 POI 外停车超过 {{time}} 分钟 {{address}}",
            "NOTIFY_SETTING": "通知设置",
            "NOTIFY_SPEED_LIMIT": "{{device}} 行驶速度太快 ({{speed}} > {{speed_limit}})，{{poi}} {{address}}",
            "NOTIFY_STATE": "{{device}} 将 {{state}} 更改为 {{to}}、{{poi}} {{address}}",
            "NOTIFICATION_ENGINE_OFF": "关闭发动机",
            "NOTIFICATION_ENGINE_PARK": "停放发动机",
            "NOTIFICATION_ENGINE_RUN": "跑步",
            "OPTION_APPROVE": "批准",
            "OPTION_DECREASE": "减少幅度小于",
            "OPTION_DENY": "不赞成",
            "OPTION_ENABLE_CLUSTER": "显示聚类标记",
            "OPTION_FILTER": "过滤依据",
            "OPTION_GT": "大于",
            "OPTION_IN": "在",
            "OPTION_INOUT": "进出",
            "OPTION_LT": "少于",
            "OPTION_MAN": "男性",
            "OPTION_OPSEVENT": "相反的状态",
            "OPTION_OUT": "出去",
            "OPTION_OVER_HOUR": "超过 {{hour}} 小时",
            "OPTION_OVER_MINUTE": "超过{{分钟}}分钟",
            "OPTION_SHOW_CHECK": "显示复选框",
            "OPTION_SHOW_DEVICE": "显示设备",
            "OPTION_SHOW_LABEL": "显示标签",
            "OPTION_SHOW_ROWNUM": "显示序列号",
            "OPTION_SHOW_SECOND": "显示第二",
            "OPTION_SHOW_STATUS": "显示状态",
            "OPTION_TOTAL": "全部",
            "OPTION_WAIT": "等待",
            "OPTION_WOMAN": "女性",
            "OPTION_NARROW": "狭窄的",
            "OPTION_NORMAL": "普通的",
            "OPTION_WIDE": "宽的",
            "REPORT": "报告",
            "REPORT_ATTIME": "在 {{time}} 报告信息",
            "REPORT_DISPLAY_PLACE": "演出地点",
            "REPORT_DISPLAY_TABLE": "显示数据表",
            "REPORT_DRIVER_SCORE_DAY": "每天都是积分。",
            "REPORT_DRIVER_SCORE_SETTING": "设定分数",
            "REPORT_DUMP_OFF": "转储站",
            "REPORT_DUMP_ON": "工作转储",
            "REPORT_FILE_AREA": "进入和离开该区域",
            "REPORT_FILE_AREA_LENGTH": "面积进出报告",
            "REPORT_FILE_CHART_CYCLE": "循环图",
            "REPORT_FILE_CHART_FUELUSE": "燃油图",
            "REPORT_FILE_CHART_GASUSE": "气体图",
            "REPORT_FILE_CHART_POWER": "电源图",
            "REPORT_FILE_CHART_SPEED": "速度图",
            "REPORT_FILE_CHART_STATUS": "使用状况",
            "REPORT_FILE_CHART_TEMP": "温度图",
            "REPORT_FILE_DRIVER_SCORE": "驾驶员评级",
            "REPORT_FILE_DVR": "硬盘录像机",
            "REPORT_FILE_DVR_EVENT": "ADAS DBA 活动",
            "REPORT_FILE_DVR_STATUS": "硬盘录像机状态",
            "REPORT_FILE_EVENT": "事件报告",
            "REPORT_FILE_FUEL": "将燃料使用量与距离进行比较。",
            "REPORT_FILE_FUELAVG": "平均油耗及行驶距离",
            "REPORT_FILE_GROUP": "小组报告",
            "REPORT_FILE_LENGTH": "距离报告",
            "REPORT_FILE_LENGTH_SUMMARY": "使用期限概要",
            "REPORT_FILE_MA": "维修历史",
            "REPORT_FILE_MA_SERVICE": "进入维修中心的历史",
            "REPORT_FILE_PARKING": "停车报告",
            "REPORT_FILE_PARKING_FREQ": "停车报告",
            "REPORT_FILE_PHOTO": "设备图片",
            "REPORT_FILE_POI": "POI 输入输出",
            "REPORT_FILE_POIOUT": "兴趣点输出",
            "REPORT_FILE_REVENUE": "运输净收入",
            "REPORT_FILE_ROUTE": "历史报告",
            "REPORT_FILE_RS232": "刷卡",
            "REPORT_FILE_SPEED_GROUP": "速度频率范围",
            "REPORT_FILE_STATUS": "状态报告",
            "REPORT_FILE_SUMMARY": "总结报告",
            "REPORT_FILE_SUMMARY_STATUS": "状态报告",
            "REPORT_FILE_WORKLOAD": "工作量报告",
            "REPORT_FILE_WORKLOAD_MONTH": "工作量月报告",
            "REPORT_FILE_DLT_LOGSUM": "交通部不寻常的坐标",
            "REPORT_GROUP_CHART": "图表",
            "REPORT_GROUP_DATA": "报告",
            "REPORT_GROUP_PHOTO": "看法",
            "REPORT_OPT_CHART": "摘要图",
            "REPORT_OPT_CHART_CHK": "显示汇总图",
            "REPORT_OPT_COUNTFREQ": "重复次数",
            "REPORT_OPT_DISPLAY": "显示结果",
            "REPORT_OPT_DLT4H": "4小时以上",
            "REPORT_OPT_DUMP": "验证转储的操作。",
            "REPORT_OPT_FUEL": "来自油位传感器的燃油消耗率。",
            "REPORT_OPT_FUELDETECT": "石油价格异常波动",
            "REPORT_OPT_LOCATION": "指定区域",
            "REPORT_OPT_MONTH": "月度总结",
            "REPORT_OPT_NOCARD": "无需刷卡",
            "REPORT_OPT_OPSDELAY": "速度延迟 {{value}} 分钟",
            "REPORT_OPT_OPSDELAY_NONE": "没有时间延迟",
            "REPORT_OPT_OVERSPEED": "仅超速",
            "REPORT_OPT_PARKTIME": "停车时间",
            "REPORT_OPT_STATUS": "汽车状态",
            "REPORT_OPT_TMS_STATE": "工作状态",
            "REPORT_OPT_USESPEED": "指定速度限制",
            "REPORT_SUMMARY_COUNT": "数据发送次数",
            "REPORT_SUMMARY_DURATION": "总使用次数",
            "REPORT_SUMMARY_LENGTH": "总距离",
            "REPORT_SUMMARY_MAXSPEED": "最大速度",
            "REPORT_SUMMARY_MAXTIME": "最长持续时间",
            "REPORT_SUMMARY_NOGPS": "无 GPS 信号",
            "REPORT_SUMMARY_PARK": "发动机启动停车",
            "REPORT_SUMMARY_PARK_MAXTIME": "最长停车时间",
            "REPORT_SUMMARY_ROUTE": "旅行",
            "REPORT_SUMMARY_SPEEDLIMIT": "超过限速",
            "REPORT_SUMMARY_STOP": "发动机关闭停车",
            "REPORT_TABLE_FUELAVG_ENGINE": "工作时间（升/小时）",
            "REPORT_TABLE_FUELAVG_KM": "距离（公里/升）",
            "REPORT_TITLE_DATERANGE": "ช่วงวันที่ {{date}}",
            "REPORT_TITLE_DEVICE": "ทะเบียน {{device}}",
            "REPORT_TITLE_DEVICE_ALL": "ทะเบียนทั้งหมด",
            "REPORT_TITLE_DEVICE_GROUP": "กลุ่ม {{group}}",
            "REPORT_VIEW_DEVICE": "显示设备",
            "REPORT_VIEW_HEADER": "展示头",
            "REPORT_VIEW_TOTAL": "显示总计",
            "STATE_ABSENCE": "ลา",
            "STATE_BAD": "坏的",
            "STATE_DONE": "ดำเนินการแล้ว",
            "STATE_GOOD": "好的",
            "STATE_MODERATE": "缓和",
            "STATE_PENDING": "รอดำเนินการ",
            "STATE_STRIKE": "หยุุด",
            "STATE_WAIT": "รอ",
            "STATE_WORK": "งาน",
            "TEXT_ACCESSTIME": "上次使用",
            "TEXT_ADD_POINT": "目的地",
            "TEXT_ADDRESS": "地址",
            "TEXT_ALEART_MONEY_INPUT": "请指定接收金额。",
            "TEXT_ALERT_SELECT_DEVICE": "请选择汽车",
            "TEXT_ALERT_SELECT_DRIVER": "请司机",
            "TEXT_ALERT_SELECT_NOTIFY": "请选择通知。",
            "TEXT_ALERT_TIME_ERROR": "时间不对",
            "TEXT_AMOUNT": "数量",
            "TEXT_AMOUNT_LITER": "数量",
            "TEXT_APPLY": "申请",
            "TEXT_AREA": "区域",
            "TEXT_ATTACHMENT": "依恋",
            "TEXT_ATTACHMENT_IMAGE": "附件图片",
            "TEXT_AVG": "平均",
            "TEXT_BIRTHDAY": "生日",
            "TEXT_CLEAR": "清除",
            "TEXT_CLEAR_OLD": "先删除原来的数据。",
            "TEXT_CLEAR_SIGNATURE": "删除签名",
            "TEXT_COMPANY": "公司",
            "TEXT_COPY": "复制",
            "TEXT_COPY_FROM": "从",
            "TEXT_COPY_TO": "到",
            "TEXT_COST": "成本",
            "TEXT_COUNT_DUPLICATE": "ซ้ำ {{count}} รายการ",
            "TEXT_COUNT_FOUND": "พบแล้ว {{count}} รายการ",
            "TEXT_COUNT_NOIMPORT": "นำเข้าไม่ได้ {{count}} รายการ",
            "TEXT_COUNT_SELECT": "เลือกแล้ว {{count}} รายการ",
            "TEXT_COUNT_TOTAL": "总计 {{count}} 个",
            "TEXT_CREATETIME": "创造时间",
            "TEXT_CREDIT": "信用",
            "TEXT_CUSTOMER": "顾客",
            "TEXT_DATA": "数据",
            "TEXT_DATE": "日期",
            "TEXT_DATE_END": "结束日期",
            "TEXT_DATE_FROM": "日期自",
            "TEXT_DATE_IN": "入学日期",
            "TEXT_DATE_INSTALL": "安装日期",
            "TEXT_DATE_OUT": "签发日期",
            "TEXT_DATERANGE": "日期持续时间",
            "TEXT_DATE_START": "开始日期",
            "TEXT_DATE_TO": "到",
            "TEXT_DEFAULT": "默认",
            "TEXT_DELAY": "延迟",
            "TEXT_DESC_DISTANCE": "从最远的开始排列。",
            "TEXT_DETAIL": "细节",
            "TEXT_DEVICE": "设备",
            "TEXT_DEVICE_24H_DURATION": "使用24小时。",
            "TEXT_DEVICE_COUNT": "设备",
            "TEXT_DEVICE_FROM": "从设备",
            "TEXT_DEVICE_GROUP": "团体",
            "TEXT_DEVICE_PARK_DURATION": "停车时间",
            "TEXT_DEVICE_RUN_DURATION": "驾驶期",
            "TEXT_DISABLE": "禁用",
            "TEXT_DISTANCE": "距离（公里）",
            "TEXT_DISTANCE_S": "距离",
            "TEXT_DLT": "分布式账本技术",
            "TEXT_DOWNLOAD": "下载",
            "TEXT_DRIVER": "司机",
            "TEXT_DRIVER_2": "司机2",
            "TEXT_DRIVER_CARD": "卡片",
            "TEXT_DRIVER_SCORE": "驾驶员评级",
            "TEXT_DURATION": "期间",
            "TEXT_EMAIL": "电子邮件",
            "TEXT_EMPLOYEE": "员工",
            "TEXT_ENABLE": "激活",
            "TEXT_ENGINE_OFF": "发动机关闭",
            "TEXT_ENGINE_ON": "发动机启动",
            "TEXT_ENGINE_TIME": "发动机时间",
            "TEXT_EVENT": "事件",
            "TEXT_EXCEPT": "除了",
            "TEXT_EXPIRE": "到期",
            "TEXT_EXPIRE_NEAR": "到期",
            "TEXT_EXPORT": "导出数据",
            "TEXT_FIXPOINT": "指定坐标",
            "TEXT_FLASHVIEW_CODE": "查看代码",
            "TEXT_FROM": "从",
            "TEXT_FROM_TIME": "从 {{date,St}} 开始",
            "TEXT_FUELADD": "添加油量（升）",
            "TEXT_FUELAVG_ENGINE": "工作时间（升/小时）",
            "TEXT_FUELAVG_KM": "距离（公里/升）",
            "TEXT_FUELMONEY": "ค่าน้ำมันที่ใช้/บาท",
            "TEXT_FUELPRICE": "ราคาน้ำมัน",
            "TEXT_FUEL_REMAIN": "剩余油量",
            "TEXT_FUELUSE": "燃料使用量/升",
            "TEXT_FUELUSE_C": "消耗率（公里/升）",
            "TEXT_FUELUSE_S": "燃油（升）",
            "TEXT_FUEL_VOLUME": "油量",
            "TEXT_FULLNAME": "名 - 姓",
            "TEXT_GENDER": "性别",
            "TEXT_GPS": "全球定位系统",
            "TEXT_GROUP": "团体",
            "TEXT_GSM": "全球移动通信系统",
            "TEXT_HEIGHT": "高度",
            "TEXT_HOWLONG": "长的",
            "TEXT_IMPORT": "导入数据",
            "TEXT_INDEX": "不。",
            "TEXT_IP": "知识产权",
            "TEXT_LABEL": "标签",
            "TEXT_LATITUDE": "纬度",
            "TEXT_LENGTH": "长度",
            "TEXT_LINE": "线",
            "TEXT_LIST": "列表",
            "TEXT_LOCATION": "地点",
            "TEXT_LOCATION_POINT": "地点",
            "TEXT_LOCATION_SHOW": "显示所有位置",
            "TEXT_LOCATION_START": "起始站",
            "TEXT_LOGO": "标识",
            "TEXT_LONGITUDE": "经度",
            "TEXT_MAX_SPEED": "最大速度",
            "TEXT_MAX_SPEED_TIME": "最大速度时间",
            "TEXT_MILEAGE": "里程",
            "TEXT_MOBILE": "อุปกรณ์",
            "TEXT_MONEY": "数量",
            "TEXT_MSISDN": "模拟号码",
            "TEXT_NAME": "姓名",
            "TEXT_NAMELAST": "姓",
            "TEXT_NATION": "国家",
            "TEXT_NOCARD_COUNT": "ไม่ได้รูดบัตร {{count}} ครั้ง",
            "TEXT_NOGPS": "无 GPS（小时）",
            "TEXT_NOGROUP": "无组",
            "TEXT_NONE": "没有任何",
            "TEXT_NONE_YET": "无数据",
            "TEXT_NOSHOW": "不要再显示了。",
            "TEXT_NOSHOW_DAY": "今天无需再显示",
            "TEXT_NOTE": "笔记：",
            "TEXT_NOTIFY": "告诉通过",
            "TEXT_NOTIFY_AND_SPEEDLIMIT": "和更快的速度",
            "TEXT_NOTIFY_CHANNEL": "通知渠道",
            "TEXT_NOTIFY_CHANNEL_MAIN": "主要渠道",
            "TEXT_NOTIFY_LAST": "最新警报",
            "TEXT_NOUPDATE": "设备停止（小时）",
            "TEXT_NUMBER": "不。",
            "TEXT_ONTIME": "什么时候",
            "TEXT_ORDINAL": "序数",
            "TEXT_PASSWORD": "密码",
            "TEXT_PASSWORD_CONFIRM": "最近的密码",
            "TEXT_PAYMENT": "如何省钱",
            "TEXT_PERMIT_DATE": "允许的日期",
            "TEXT_PLACE": "地方",
            "TEXT_PLACE_NEAR": "靠近",
            "TEXT_PLAYBACK_COMPARE": "比较之前的数据",
            "TEXT_POI": "兴趣点",
            "TEXT_POI_BASE": "POI / กลุ่มรถ",
            "TEXT_POI_GROUP_READ": "定义可见 POI 组",
            "TEXT_POI_NOBASE": "未显示自己的 POI/汽车组",
            "TEXT_POI_NOENTRY": "不访问任何入口站的人数",
            "TEXT_POI_NOIN": "不显示 POI 中停放的车辆",
            "TEXT_POINT_END": "目的地",
            "TEXT_POINT_START": "开始",
            "TEXT_POI_STATION": "到站人数",
            "TEXT_PORT": "导入/导出",
            "TEXT_PRICE": "价格",
            "TEXT_PROBLEM_NOTIFY": "问题通知",
            "TEXT_RADIUS": "半径",
            "TEXT_RAWDATA": "实际值",
            "TEXT_REMAIN": "其余的",
            "TEXT_RESET": "重置值",
            "TEXT_RESULT": "工作成果",
            "TEXT_ROUTE": "路线",
            "TEXT_ROWNUM": "不。",
            "TEXT_SEARCH": "搜索",
            "TEXT_SENDED": "发送",
            "TEXT_SHOW_PARKING": "演出站点",
            "TEXT_SIGNATURE": "签名",
            "TEXT_SIGNATURE_CUSTOMER": "客户签名",
            "TEXT_SIGNATURE_USER": "员工签名",
            "TEXT_SMS": "短信",
            "TEXT_SORT": "种类",
            "TEXT_SPEED": "速度",
            "TEXT_SPEED_S": "速度",
            "TEXT_STATE": "目前状态",
            "TEXT_STATUS_ENGINE": "发动机状态",
            "TEXT_STATUS_RUN": "运行状态",
            "TEXT_STATUS": "地位",
            "TEXT_STEP": "步",
            "TEXT_TELEPHONE": "电话",
            "TEXT_TIME": "时间",
            "TEXT_TIME_AT": "{{time}} 的信息",
            "TEXT_TIME_END": "退出时间",
            "TEXT_TIME_IN": "入场时间",
            "TEXT_TIME_OUT": "退出时间",
            "TEXT_TIME_START": "输入时间",
            "TEXT_TIME_STAY": "时间",
            "TEXT_TITLE": "标题",
            "TEXT_TO": "到",
            "TEXT_TOTAL": "全部的",
            "TEXT_TOTAL_ALL": "总计 全部",
            "TEXT_TOTAL_DURATION": "总使用时间",
            "TEXT_TOTAL_PARK": "空中停车时间",
            "TEXT_TOTAL_PARK10": "空中停车10分钟以上",
            "TEXT_TOTAL_PARK_MAX": "最长启动时间",
            "TEXT_TOTAL_PARK_MAX_DATE": "最长闲置时间",
            "TEXT_TOTAL_SPEED": "超速时间",
            "TEXT_TYPE": "类型",
            "TEXT_UPDATETIME": "最后更新",
            "TEXT_UPLOAD": "上传",
            "TEXT_USE_DAY": "每天使用次数",
            "TEXT_USER": "用户",
            "TEXT_USERAGENT": "代理人",
            "TEXT_USERNAME": "用户名",
            "TEXT_WAYPOINT": "航路点",
            "TEXT_WHEN": "什么时候",
            "TEXT_WIDTH": "宽度",
            "TEXT_WORK": "工作",
            "TMS_INFO_DUP_DEVICE": "在此期间，汽车仍在使用。",
            "TMS_INFO_DUP_EMPLOYEE": "这位司机已经有工作了。这段时间",
            "TMS_INFO_DUP_EMPLOYEE2": "有一段工作时间与员工的休假日一致。",
            "TMS_MENU_CALENDAR": "日历",
            "TMS_MENU_LIST": "日程",
            "TMS_MENU_SEARCH": "搜索",
            "TMS_MONEY_BILL": "账单",
            "TMS_MONEY_CASH": "现金",
            "TMS_MONEY_NO": "不。",
            "TMS_MONEY_PENDING": "待办的",
            "TMS_MONEY_TRANSFER": "转移",
            "ONLY_AREA_IN": "仅限该地区",
            "ONLY_AREA_OUT": "仅限区域外",
            "ONLY_POI_IN": "仅限兴趣点",
            "ONLY_POI_OUT": "仅限 POI 之外",
            "TRACKING_DEVICE_CARD": "司机卡",
            "TRACKING_DEVICE_SUSPEND_BODY": "以下设备即将过期或已达到过期日期。请联系我们延长服务，否则系统将自动删除该设备。",
            "TRACKING_DEVICE_SUSPEND_HEAD": "设备过期提醒",
            "TRACKING_MENU_ALERT": "警报",
            "TRACKING_MENU_AREA": "区域",
            "TRACKING_MENU_COMMAND": "命令",
            "TRACKING_MENU_DEVICE": "设备信息",
            "TRACKING_MENU_NEAR": "靠近",
            "TRACKING_MENU_NOW": "即时的",
            "TRACKING_MENU_PLAYBACK": "回放",
            "TRACKING_MENU_POI": "兴趣点",
            "TRACKING_PLAYBACK_VAD_NO": "未找到驾驶记录",
            "TRACKING_POI_INFO_ICON": "图标",
            "TRACKING_STATUS_FAST": "快速地",
            "TRACKING_STATUS_OFF": "离开",
            "TRACKING_STATUS_PARK": "公园",
            "TRACKING_STATUS_RUN": "跑步",
            "TRACKING_STATUS_STOP": "停止",
            "TRACKING_STATUS_TOWER": "天线",
            "UNIT_BATH": "铢",
            "UNIT_BPK": "泰铢/公里",
            "UNIT_BPL": "泰铢/立特",
            "UNIT_COUNT": "时代",
            "UNIT_DAY": "天",
            "UNIT_HOUR": "小时",
            "UNIT_KM": "知识管理",
            "UNIT_KMPH": "公里/小时",
            "UNIT_KMPL": "公里/升",
            "UNIT_LITER": "升",
            "UNIT_LPH": "长/小时",
            "UNIT_METRE": "中号",
            "UNIT_MINUTE": "分钟",
            "UNIT_MONTH": "月",
            "UNIT_SECOND": "秒。",
            "UNITTEXT_NUMBER": "รายการที่ {{number}}",
            "USER_API_ADD": "创建一个新密钥",
            "USER_API_EMPTY": "尚未创建 API 密钥。",
            "USER_DEVICE_FIELD_CHART": "燃油图",
            "USER_DEVICE_FIELD_CHART_HELP": "突出显示以覆盖图形的最小值和最大值",
            "USER_DEVICE_FIELD_MAX": "最大时间段",
            "USER_DEVICE_FIELD_MIN": "最短时间段",
            "USER_DEVICE_FIELD_REVERSE": "交替最小值和最大值",
            "USER_DEVICE_FIELD_TELEPHONE": "移动的",
            "USER_DEVICE_FIELD_TRACKER": "追踪器型号",
            "USER_DEVICE_FORM_EDIT_HEAD": "更改设备详细信息",
            "USER_DEVICE_FORM_MARKER_HEAD": "标记设置",
            "USER_DEVICE_FORM_OIL_HEAD": "设置燃油图",
            "USER_DEVICE_TABLE_ALERT_WHEN": "什么时候",
            "USER_DRIVER_VAD_EMPTY": "请输入司机姓名",
            "USER_FORM_ADD_HEAD": "添加",
            "USER_FORM_SUMMARY_SEND": "每天发送报告",
            "USER_INFO_CHECKPOINT": "⹀ครดิต检查点",
            "USER_INFO_SMS": "短信保留",
            "USER_MENU_API": "管理API",
            "USER_MENU_AREA": "区域",
            "USER_MENU_CHECKPOINT": "检查站",
            "USER_MENU_COMMAND": "发送命令",
            "USER_MENU_COMPANY": "公司",
            "USER_MENU_CUSTOMER": "顾客",
            "USER_MENU_DASHBOARD": "仪表板",
            "USER_MENU_DEVICE": "设备",
            "USER_MENU_EMPLOYEE": "员工",
            "USER_MENU_EMPLOYEE_DAY": "休假日",
            "USER_MENU_FLASHVIEW": "临时账户",
            "USER_MENU_FUEL": "燃料",
            "USER_MENU_LOG": "使用历史",
            "USER_MENU_LOG_CHECKPOINT": "检查点",
            "USER_MENU_LOG_SIGN": "登录历史记录",
            "USER_MENU_LOG_SMS": "短信日志",
            "USER_MENU_MOBILE": "移动的",
            "USER_MENU_NOTIFICATION": "通知",
            "USER_MENU_POI": "兴趣点",
            "USER_MENU_PROFILE": "用户数据",
            "USER_MENU_REPORT": "报告",
            "USER_MENU_SUBUSER": "子用户",
            "USER_NOTIFY_AD": "传感器的值",
            "USER_NOTIFY_AREA": "区域",
            "USER_NOTIFY_DRIVER_CARD": "刷卡",
            "USER_NOTIFY_EVENT": "事件",
            "USER_NOTIFY_PARK_LIMIT": "停車處",
            "USER_NOTIFY_POI": "兴趣点",
            "USER_NOTIFY_SPEED_LIMIT": "超速",
            "USER_NOTIFY_STATE": "机器状态",
            "USER_POI_FIELD_EMAIL": "电子邮件提醒",
            "USER_POI_FIELD_IN": "进入警报状态",
            "USER_POI_FIELD_OUT": "退出警报",
            "USER_POI_FIELD_SM": "小图标",
            "USER_POI_LOCATION": "地点",
            "USER_PROFILE_FAIL_PASS_CONFIRM": "最近的密码不正确",
            "USER_PROFILE_FAIL_PASS_LENGTH": "新密码太短。",
            "USER_PROFILE_FAIL_PASS_RE": "重新输入密码错误",
            "USER_PROFILE_FAIL_USER_DUP": "用户名重复。",
            "USER_PROFILE_FORM_PASSWORD_HEAD": "更改密码",
            "USER_SETTING_TAGS_NOTE": "使用 Enter 添加新标签。",
            "USER_SETTING_TMSPAY": "支付渠道",
            "USER_TEXT_DOCUMENT_GOV": "官方文件",
            "USER_TEXT_EMAIL_NOTE": "插入多于1封电子邮件，请用 , 分隔",
            "USER_TEXT_IDCARD": "身份证号码",
            "USER_TEXT_LICENCE_GOV": "问题于",
            "USER_TEXT_LICENCE_NUMBER": "驾驶执照号码",
            "USER_TEXT_LICENCE_TYPE": "许可证类型",
            "USER_TEXT_PASSWORD_NOTE": "如果您不想更改密码，请不要输入。",
            "USER_TEXT_PREFIX": "标题",
            "USER_TEXT_REGISTRAR": "登记员",
            "USER_TEXT_REPASSWORD": "确认密码"
        }
    }
}