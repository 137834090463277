import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { sendEvent$ } from './service/state'

import { useHistory, useLocation } from 'react-router'

import { AppContext, clsNames, date, dbdate, dConfirm, fromNow, get, post, tSuccess, useUser } from 'unno-comutils'
import { logout } from 'unno-comutils/connect'
import { Button, ButtonSave, Icon, List, ListContainer, Modal } from 'unno-comutils/ui'
import { FormContainer, FormItem, Input, InputDate, Select, Upload } from 'unno-comutils/form'
import { User } from 'unno-comutils/utils'
import Dropdown, { DropdownItem } from 'unno-comutils/Dropdown'

import { DLT_REPORT_STOPs } from './var.meta'
import { getMenu, LangMenu, MainMenu, ThemeMenu } from './menu'
import Smenu from './components/smenu'
import { role, setDateLocale } from './utils'
import { ChatModal, NoticeModal } from './pages/chat'
import { AlertPing, Markdown } from './components/common'

const FULLPAGEs = ['tracking', 'controlroom', 'view', 'tools', 'reseller', 'report', 'report_builder', 'test']

export default function Layout (props: any) {
    const location = useLocation()

    const [dmenu, setDMenu] = useState(false)

    // ----- EVENT

    // ----- MEMO

    const cPath = useMemo(() => location.pathname + '/', [location])

    // ----- RENDER

    const renderSideMenu = () => {
        const menus = getMenu(cPath)
        if (menus) return <Smenu menus={menus} onChangeUrl={() => { if (dmenu) setDMenu(false) }}/>
        return null
    }

    const renderTopLayout = () => {
        if (cPath.startsWith('/flashview/') || cPath.startsWith('/print/')) return null
        return <LayoutTop/>
    }

    const bodyClass = () => {
        if (cPath.startsWith('/flashview/'))
            return 'layout-body -white_paper'
        if (cPath.startsWith('/print/'))
            return 'layout-body -print'
        if (cPath.startsWith('/') && FULLPAGEs.indexOf(cPath.split('/')[1]) >= 0)
            return 'layout-body'
        return 'layout-body -container'
    }

    return <>
        {renderTopLayout()}
        <div className={bodyClass()}>
            {renderSideMenu()}
            {props.children}
        </div>
    </>
}

function LayoutTop () {
    const { t } = useTranslation()

    const history = useHistory()
    const user = useUser()

    const [smenu, setSMenu] = useState('')

    const [chat, setChat] = useState(false)
    const [chatNew, setChatNew] = useState(false)
    const [notice, setNotice] = useState(false)

    // ----- ACTION

    const onChangeLang = (setUser: any, lang: string) => {
        get('app/lang/' + lang).then((d: any) => {
            if (d.ok) {
                sendEvent$('app_lang_change', d.lang)

                i18n.changeLanguage(d.lang).then(() => {
                    setDateLocale(d.lang)
                    setUser((user: any) => new User({ ...user, lang: d.lang }))
                    setSMenu('')
                })
            }
        })
    }

    function onChangeTheme (theme: any) {
        sendEvent$('app_theme_change', theme)
    }

    // ----- EVENT

    const onLogout = (setUser: any) => {
        dConfirm(t('CONFIRM_LOGOUT')).then(() => {
            logout().then(() => {
                tSuccess(t('INFO_LOGOUT'))
                setUser(null)
            })
        })
    }

    // ----- RENDER

    return <>
        <div className={'layout-top'}>
            <AppContext.Consumer>
                {({ setUser }) => <div className={'layout-top-container'}>
                    <MenuMain/>
                    {user.id < 1003 && <div className={'layout-menu-icon'} onClick={() => setChat(prev => !prev)}>
                        {chatNew && <AlertPing/>}
                        <Icon name={'comment-alt'}/>
                    </div>}
                    {user.id < 1003 && <div className={'layout-menu-icon'} onClick={() => setNotice(true)}>
                        {user.notifications && user.notifications.indexOf('news') >= 0 && <AlertPing/>}
                        <Icon name={'bell'}/>
                    </div>}
                    <Dropdown show={smenu === 'theme'} onToggle={() => setSMenu(smenu === 'theme' ? '' : 'theme')} right
                              button={<Icon button name={'paint-brush'} className="layout-menu-theme-button"/>}>
                        {ThemeMenu.map(th => <DropdownItem key={'th_' + th.icon} icon={th.icon} label={th.name} onClick={() => onChangeTheme(th.key)}/>)}
                    </Dropdown>

                    <Dropdown show={smenu === 'lang'} onToggle={() => setSMenu(smenu === 'lang' ? '' : 'lang')} right className="layout-menu-lang-dropdown"
                              button={<div className="layout-menu-lang">
                                  <img src={`${process.env.PUBLIC_URL}/static/images/flag/${user?.lang}.svg`} alt={'flag'} className={'_flag'}/>
                              </div>}>
                        {LangMenu.map(l => <div key={`menu_lang-${l.key}`} className="_list" onClick={() => onChangeLang(setUser, l.key)}>
                            <img src={`${process.env.PUBLIC_URL}/static/images/flag/${l.key}.svg`} alt={'flag'} className={'_flag'}/>
                            <span className="_text">{l.text}</span>
                        </div>)}
                    </Dropdown>

                    <div className="layout-menu-user" onClick={() => history.push('/user/profile')}>
                        <div className="_name">{user?.name || 'null'}</div>
                        <Icon className="_icon" name={'smile'}/>
                    </div>
                    <div className={'layout-menu-icon ml-4'} onClick={() => onLogout(setUser)}><Icon name={'sign-out-alt'}/></div>
                </div>}
            </AppContext.Consumer>
        </div>

        <ChatModal open={chat} onClose={() => setChat(false)} onUpdate={setChatNew}/>
        <NoticeModal open={notice} onClose={() => setNotice(false)}/>

        <WelcomeModal/>
    </>
}

function WelcomeModal () {
    const { t } = useTranslation()
    const user = useUser()

    const [openNotice, setOpenNotice] = useState(false)
    const [openExpire, setOpenExpire] = useState(false)
    const [openDltstop, setOpenDltstop] = useState(true)

    const [notice, setNotice] = useState<any>(null)
    const [expires, setExpires] = useState<any>(null)
    const [dltstops, setDltstops] = useState<any>(null)

    // ----- ACTION

    async function loadData () {
        get('app/welcome').then((d: any) => {
            if (d.ok) {
                setNotice(d.notice)
                if (d.notice) {
                    if ((localStorage.getItem('noshow_notice_' + user.id) || '') !== d.notice.key)
                        setOpenNotice(true)
                }

                setExpires(d.deviceExpires)
                setDltstops(d.dltStops)
            }
        })
    }

    function reloadDataStop () {
        get('app/welcome', { reload_stop: 1 }).then((d: any) => {
            if (d.ok) {
                setDltstops(d.dltStops)
            }
        })
    }

    const saveDltStop = (c: any, data: any) => {
        const saveData = {
            dltReport_stop: data.dltReport_stop?.id || 0,
            dltCall_note: data.dltCall_note,
            dltMasterFiles_stop: data.dltMasterFiles_stop,
            fixPlace: {
                place: data.fixPlace,
                date_start: data.fixDateStart ? dbdate(data.fixDateStart) : null,
                date_end: data.fixDateStart ? dbdate(data.fixDateEnd) : null,
            },
        }
        post('dlt/save_stop/' + data.id, saveData).then((d: any) => {
            if (d.ok) {
                tSuccess(t('INFO_SAVED'))
                reloadDataStop()
            }
        }).finally(c)
    }

    // ----- EVENT

    function noShowExpire () {
        localStorage.setItem('noshow_expire_' + user.id, dbdate(new Date(), false))
        setOpenExpire(false)
    }

    function noShowNotice (key: string) {
        localStorage.setItem('noshow_notice_' + user.id, key)
        setOpenNotice(false)
    }

    const onChange = (id: number, update: any) => setDltstops((prev: any) => prev.map((d: any) => d.id === id ? ({ ...d, ...update }) : d))

    // ----- MEMO

    useEffect(() => {
        if (user) {
            if ((localStorage.getItem('noshow_expire_' + user.id) || '') !== dbdate(new Date(), false))
                setOpenExpire(true)
        }
    }, [user])

    useEffect(() => {
        loadData()
    }, [])

    // ----- RENDER

    return <>
        {notice && <Modal icon={'bell orange'} title={notice.title} open={openNotice} onClose={() => setOpenNotice(false)}
                          footer={<Button primary className="w-fill" onClick={() => noShowNotice(notice.key)}>{t('TEXT_NOSHOW')}</Button>}>
            <Markdown>{notice.content}</Markdown>
        </Modal>}

        {expires?.length > 0 && <Modal icon={'bell orange'} title={t('TRACKING_DEVICE_SUSPEND_HEAD')} sm open={openExpire} onClose={() => setOpenExpire(false)}
                                       footer={<Button primary className="w-fill" onClick={noShowExpire}>{t('TEXT_NOSHOW_DAY')}</Button>}>
            <div className="c grey-blue">{t('TRACKING_DEVICE_SUSPEND_BODY')}</div>
            <ListContainer className={'mt-3'}>
                {expires && expires.map((d: any) => <List key={'item_' + d.id}>
                    <div>{d.name}</div>
                    <div className="ml-auto red tcut">{t('TEXT_EXPIRE')} {date(d.expire, 'S')}</div>
                </List>)}
            </ListContainer>
        </Modal>}

        {dltstops?.length > 0 && <Modal icon={'exclamation-triangle red'} title={'แจ้งเตือนรถกรมขนส่ง'}
                                        open={openDltstop} onClose={() => setOpenDltstop(false)}
                                        footer={<div className="popupstop-popup-footer">
                                            <strong>หมายเหตุ :</strong> กรณีอุปกรณ์ GPS หยุดการทำงานเกิน 7 วัน แต่รถมีการใช้งาน
                                            กรมการขนส่งทางบก
                                            จะออกเอกสารเรียกผู้ประกอบการเข้าทำการชี้แจงเหตุผล
                                        </div>}>
            <div className="popupstop-popup-header">
                รถรายการด้านล่างต่อไปนี้ หยุดการส่งข้อมูลไปยังกรมการขนส่งทางบกเกิน 7 วัน กรุณาตรวจสอบอุปกรณ์จีพีเอสให้ทำงานปกติ ก่อนนำรถไปใช้งาน
                หรือ ติดต่อ 02-111-7999 เพื่อขอรับการช่วยเหลือ
            </div>
            <div className="popupstop-popup-items">
                {dltstops && dltstops.map((d: any) => <FormContainer collapse vertical
                                                                     label={d.name}
                                                                     header={'ส่งล่าสุด ' + date(d.update, 'St') + ' (' + fromNow(d.update) + ')'}
                                                                     headerCollapse
                                                                     key={'item_' + d.id}>
                    {!d.checkWait && <div className="popupstop-popup-ok">บันทึกข้อมูลแล้ว</div>}
                    {d.dltCall_date && <FormItem label="รายงานล่าสุด" children={date(d.dltCall_date, 'M')}/>}
                    <Select form label={'สาเหตุการหยุดส่งข้อมูล'} options={DLT_REPORT_STOPs} value={d.dltReport_stop} onChange={(_, v) => onChange(d.id, { dltReport_stop: v })}/>
                    <Input form label={'รายละเอียดเพิ่มเติม'} multiline value={d.dltCall_note} onChange={(dltCall_note) => onChange(d.id, { dltCall_note })}/>

                    <Input form label={'สถานที่ซ่อม'} value={d.fixPlace} onChange={fixPlace => onChange(d.id, { fixPlace })}/>

                    <FormItem label="วันที่ซ่อม" flex labelInput>
                        <InputDate fixWidth icon value={d.fixDateStart} onChange={fixDateStart => onChange(d.id, { fixDateStart })}/>
                        <span className="mx-3">วันที่เสร็จ</span>
                        <InputDate fixWidth icon value={d.fixDateEnd} onChange={fixDateEnd => onChange(d.id, { fixDateEnd })}/>
                    </FormItem>

                    <Upload form label={'ภาพจอดเสีย/จอดซ่อม'} multiple value={d.dltMasterFiles_stop} onChange={dltMasterFiles_stop => onChange(d.id, { dltMasterFiles_stop })}/>

                    <FormItem>
                        <ButtonSave onSave={(c) => saveDltStop(c, d)}>บันทึก</ButtonSave>
                    </FormItem>
                </FormContainer>)}
            </div>
        </Modal>}


    </>
}

export function MenuMain () {
    const user = useUser()
    const { t } = useTranslation()

    const history = useHistory()
    const location = useLocation()

    const [dmenu, setDMenu] = useState(false)
    const [favMenu, setFavMenu] = useState([])

    const cPath = location.pathname + '/'
    const itemUrl = (m: any) => m.url

    const onClickItem = (m: any, e: any) => {
        setDMenu(false)
        if (!e.ctrlKey) {
            e.preventDefault()
            history.push(m.url)
        }
    }

    const onClickFav = (m: any, e: any) => {
        e.stopPropagation()
        toggleFavMenu(m.name)
    }

    const getFavMenu = () => (localStorage.getItem('menu_fav') || 'tracking,report,support').split(',').filter(Boolean)

    const toggleFavMenu = (name: string) => {
        const saveMenu = getFavMenu()
        if (saveMenu.indexOf(name) >= 0) {
            localStorage.setItem('menu_fav', saveMenu.filter((m: any) => m !== name).join(','))
        }
        else {
            saveMenu.push(name)
            localStorage.setItem('menu_fav', saveMenu.join(','))
        }
        readFavMenu()
    }

    const readFavMenu = () => {
        const saveMenu = getFavMenu()
        if (saveMenu.length > 0) {
            const outputs: any = []
            MainMenu.forEach((m: any) => {
                m.items.forEach((n: any) => {
                    if (saveMenu.indexOf(n.name) >= 0 && role(n.role, user))
                        outputs.push(n)
                })
            })
            setFavMenu(outputs)
        }
    }

    const matchMenu = (menu: any, cPath: string) => (menu.urlMatch && cPath.startsWith(menu.urlMatch + '/')) || cPath.startsWith(menu.url + '/')

    const findCurrentMenu = (cPath: string) => {
        for (const m of MainMenu) {
            const menu = m.items.find((n: any) => matchMenu(n, cPath))
            if (menu) return menu
        }
        return null
    }

    const renderMainMenu = (m: any) => {
        const avtive = matchMenu(m, cPath)
        const fav = favMenu?.length && favMenu.some((n: any) => n.name === m.name)

        return <div key={'menu_' + m.name} className={clsNames('menumain-item', avtive && '-active')}>
            <a href={itemUrl(m)} className="_link" onClick={e => onClickItem(m, e)}>
                <Icon name={m.icon} className="_icon"/>
                <span className="_text">{t(m.text)}</span>
            </a>
            <Icon name={'star'} className={clsNames('_icon-fav', fav && '-active')} onClick={e => onClickFav(m, e)}/>
        </div>
    }

    const renderFavMainMenu = (m: any) => {
        return <a key={'menu_' + m.name} className={clsNames('menufav-item', matchMenu(m, cPath) && '-active')} href={itemUrl(m)} onClick={e => onClickItem(m, e)}>
            <Icon name={m.icon} className="_icon"/>
            <span className="_text">{t(m.text)}</span>
        </a>
    }

    useEffect(() => {
        readFavMenu()
    }, [])

    const backHost = useMemo(() => document.location.host.includes('ntech') ? 'https://www.ntechgps.net/?back_version=1' : 'https://www.gpsiam.net/?back_version=1', [])

    const menuCurrent = useMemo(() => findCurrentMenu(cPath), [cPath])

    const menus = useMemo(() => {
        const _menus: any = []
        for (const m of MainMenu) {
            const items = m.items.filter((m: any) => role(m.role, user))
            if (items && items.length > 0) _menus.push({ ...m, items })
        }
        return _menus
    }, [user])

    return <div className="menumain">
        <Dropdown show={dmenu} onToggle={(d: any) => setDMenu(d)} className="menumain-wrap un-scroll"
                  button={<div className="menumain-header">
                      <Icon name={'th-large'} solid/>
                      {menuCurrent && <span className="_title">{t(menuCurrent.text)}</span>}
                  </div>}>
            {menus.map((m: any, x: number) => <div key={'x_' + x} className="menumain-group">
                <div className="menumain-group_header"><span>{m.name}</span></div>
                <div className="menumain-items">{m.items.map(renderMainMenu)}</div>
            </div>)}
            <div className="menumain-footer"><a href={backHost}>Old version</a></div>
        </Dropdown>
        {favMenu?.length > 0 && favMenu.map(renderFavMainMenu)}
    </div>
}

// {m.items.filter((sm: any) => role(sm.role, user)).map((s: any) => renderMainMenuItem(s, true))}