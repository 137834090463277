import React from 'react'
import L from '@loadable/component'

import { Redirect, Route, Switch } from 'react-router-dom'

import { RoutePrivate } from 'unno-comutils/Route'

import Wait from 'unno-comutils/Wait'
import DocumentView from './pages/documentView'

const fallback = <Wait icon={'fan'} className={'blue'}/>

const Login = L(() => import(/* webpackChunkName: "login" */'./login'), { fallback })
const LoginSpy = L(() => import(/* webpackChunkName: "spy" */'./spy'), { fallback })

const Tracking = L(() => import(/* webpackChunkName: "tracking" */'./pages/tracking/page'), { fallback })
const TrackingMobile = L(() => import(/* webpackChunkName: "tracking_mobile" */'./pages/tracking/mobile'), { fallback })
const TrackingFlashView = L(() => import(/* webpackChunkName: "flashview" */'./pages/tracking/flashview'), { fallback })
const ControlRoom = L(() => import(/* webpackChunkName: "tracking_controlroom" */'./pages/tracking/controlroom'), { fallback })

const Checkpoint = L(() => import(/* webpackChunkName: "checkpoint" */'./pages/checkpoint/main'), { fallback })

const Dashboard = L(() => import(/* webpackChunkName: "user" */'./pages/dashboard'), { fallback })
const RouterUser = L(() => import(/* webpackChunkName: "user" */'./pages/user'), { fallback })

const Test = L(() => import(/* webpackChunkName: "test" */'./test/_index'), { fallback })
const Support = L(() => import(/* webpackChunkName: "support" */'./pages/support'), { fallback })

const ReportBuilder = L(() => import(/* webpackChunkName: "report_builder" */'./pages/reportBuilder'), { fallback })
const ReportView = L(() => import(/* webpackChunkName: "report_view" */'./pages/reportView'), { fallback })
const PrintView = L(() => import(/* webpackChunkName: "print_view" */'./print/index'), { fallback })

// --------------

const ModuleMa = L(() => import(/* webpackChunkName: "module_ma" */'./pages/modules/ma'), { fallback })
const ModuleMaService = L(() => import(/* webpackChunkName: "module_ma_service" */'./pages/modules/maService'), { fallback })

const ModuleTms = L(() => import(/* webpackChunkName: "module_tms_gantt" */'./pages/tms/gantt'), { fallback })
const ModuleTmsList = L(() => import(/* webpackChunkName: "module_tms_list" */'./pages/tms/list'), { fallback })
const ModuleTmsSearch = L(() => import(/* webpackChunkName: "module_tms_search" */'./pages/tms/search'), { fallback })
const ModuleTmsSetting = L(() => import(/* webpackChunkName: "module_tms_setting" */'./pages/tms/setting'), { fallback })

const ModuleTmsRoute = L(() => import(/* webpackChunkName: "module_tmsroute" */'./pages/modules/tmsroute'), { fallback })
const ModuleRevenue = L(() => import(/* webpackChunkName: "module_revenue" */'./pages/modules/revenue'), { fallback })
const ModuleDistance = L(() => import(/* webpackChunkName: "module_distance" */'./pages/modules/distance'), { fallback })

// --------------

const UserCompany = L(() => import(/* webpackChunkName: "user_company" */'./pages/user/company'), { fallback })
const UserCustomer = L(() => import(/* webpackChunkName: "user_customer" */'./pages/user/customer'), { fallback })

// --------------

export default function Router () {
    return <Switch>
        <Route exact path="/"><Redirect to="/user"/></Route>
        <Route exact path="/login" children={<Login/>}/>
        <Route exact path="/spy" children={<LoginSpy/>}/>
        <Route exact path="/access" children={<LoginSpy access/>}/>

        <Route path="/test" children={<Test/>}/>

        <Route exact path="/privacy-policy" children={<DocumentView name={'privacy'}/>}/>

        <Route exact path="/flashview/:key" children={<TrackingFlashView/>}/>

        <RoutePrivate exact path="/tracking" children={<Tracking/>}/>
        <RoutePrivate path="/m/" children={<TrackingMobile/>}/>
        <RoutePrivate exact path="/controlroom" children={<ControlRoom/>}/>

        <RoutePrivate needRole={'api'} path="/checkpoint" children={<Checkpoint/>}/>

        <RoutePrivate path="/dashboard" children={<Dashboard/>}/>
        <RoutePrivate path="/user" children={<RouterUser/>}/>

        <RoutePrivate exact needRole={'report'} path="/report_builder" children={<ReportBuilder/>}/>
        <RoutePrivate exact needRole={'report'} path="/report/:file" children={<ReportView/>}/>
        <RoutePrivate exact path="/print/:file" children={<PrintView/>}/>

        <RoutePrivate needRole={'support'} exact path="/support" children={<Support/>}/>
        <RoutePrivate needRole={'support'} exact path="/support/:id" children={<Support/>}/>

        <RoutePrivate needRole={'ma'} exact path={['/ma', '/ma/summary', '/ma/device/:id/', '/ma/device/:id/:tab', '/ma/template']} children={<ModuleMa/>}/>
        <RoutePrivate needRole={'ma'} path="/ma_service" children={<ModuleMaService/>}/>

        <RoutePrivate exact needRole={'tms'} path="/tms" children={<Redirect to="/tms/calendar"/>}/>
        <RoutePrivate exact needRole={'tms'} path="/tms/calendar" children={<ModuleTms/>}/>
        <RoutePrivate exact needRole={'tms'} path="/tms/list" children={<ModuleTmsList/>}/>
        <RoutePrivate exact needRole={'tms'} path="/tms/search" children={<ModuleTmsSearch/>}/>
        <RoutePrivate exact needRole={'tms'} path="/tms/setting" children={<ModuleTmsSetting/>}/>

        <RoutePrivate exact needRole={'tms'} path="/tms/company" children={<UserCompany/>}/>
        <RoutePrivate exact needRole={'tms'} path="/tms/customer" children={<UserCustomer/>}/>

        <RoutePrivate needRole={'tmsroute'} path="/tmsroute" children={<ModuleTmsRoute/>}/>
        <RoutePrivate needRole={'ma'} path="/revenue" children={<ModuleRevenue/>}/>
        <RoutePrivate exact path="/tools/distance" children={<ModuleDistance/>}/>

        <RoutePrivate exact path="*"><Redirect to="/user/report"/></RoutePrivate>
    </Switch>
}

//const RouterReseller = L(() => import(/* webpackChunkName: "reseller" */'./~reseller/main'), { fallback })
//<RoutePrivate needRole={'__reseller'} path="/reseller" children={<RouterReseller/>}/>
