import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import { Viewer } from '@toast-ui/react-editor'

import { clsNames, date, get, isEmpty, post, sameDate, subscribe, useUser } from 'unno-comutils'
import { Icon, List, ListButton, Modal, PageScroll } from 'unno-comutils/ui'
import { Input } from 'unno-comutils/form'

import Dropdown from 'unno-comutils/Dropdown'

import { CHAT_EMOJIs } from '../service/var'
import { Markdown } from '../components/common'
import { useTranslation } from 'react-i18next'
import { tdate } from '../utils'

export default function Chat () {
    return <div>Chat</div>
}

export function NoticeModal (props: { open: boolean, onClose: () => void }) {
    const user = useUser()

    const { t } = useTranslation()
    const [wait, setWait] = useState<any>(true)

    const [documents, setDocuments] = useState<any>(null)
    const [document, setDocument] = useState<any>(null)

    const viewRef = createRef<any>()

    // ----- ACTION

    const loadList = () => {
        setWait(true)
        get('support/notifys', { lang: user.lang || 'th' }).then((d: any) => {
            if (d.ok) setDocuments(d.datas)
            else setDocuments(null)
        }).finally(() => setWait(false))
    }

    const loadData = (id: number) => {
        get('support/document/' + id, { lang: user.lang || 'th' }).then((d: any) => {
            if (d.ok) setDocument(d.document)
            else setDocument(null)
        })
    }

    // ----- EVENT

    // ----- MEMO

    // ----- RENDER

    return <Modal icon={'stars'} title={'NEWS & Update'} sm open={props.open} draggable noBackdrop startPosition={{ right: 20, top: 55 }}
                  className={'news-popup'} onOpenEnd={loadList} onClose={props.onClose} onCloseEnd={() => setDocuments(null)}>
        <PageScroll wait={wait} className="notice-list">
            {documents && documents.map((d: any) => {
                return <List key={'list_' + d.id}>
                    <ListButton fill onClick={() => loadData(d.id)}>
                        <div className={'w-fill sm'}>{d.title}</div>
                        <div className={'w-28 blue sm r'}>{tdate(t, d.updateTime, 'ST')} </div>
                    </ListButton>
                </List>
            })}
        </PageScroll>

        {document && <Modal title={document.title || ''} md open={document} onClose={() => setDocument(null)}>
            <Viewer customHTMLRenderer={{
                htmlBlock: {
                    // @ts-ignore
                    iframe (node) {
                        return [
                            { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
                            { type: 'html', content: node.childrenHTML },
                            { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
                        ]
                    },
                },
                htmlInline: {
                    big (node, { entering }) {
                        return entering
                            ? { type: 'openTag', tagName: 'big', attributes: node.attrs }
                            : { type: 'closeTag', tagName: 'big' }
                    },
                }
            }} ref={viewRef} key={`${document.id}_${document.title}`} initialValue={document.content}/>
        </Modal>}

    </Modal>
}

export function ChatModal (props: { open: boolean, onClose: () => void, onUpdate: (data: boolean) => void }) {
    const user = useUser()

    const [messages, setMessages] = useState<any>(null)
    const [message, setMessage] = useState('')

    const [popEmoji, setPopEmoji] = useState(false)

    const openNow = useRef(false)

    // ----- ACTION

    const loadChat = () => {
        get('chat/history').then(d => {
            if (d.ok) setMessages(d.datas.sort(_sortMessage))
        })
    }

    const sendMessage = () => {
        setMessage('')
        if (user.id > 0 && message) {
            post('chat/send/' + user.id, { message }).then(d => {
                if (d.ok) {

                }
            })
        }
    }

    // ----- EVENT

    const _sortMessage = (a: any, b: any) => b.timestamp - a.timestamp

    const onEnter = (e: any) => {
        if (!e.shiftKey && e.key === 'Enter') {
            sendMessage()
            e.preventDefault()
        }
    }

    const onClose = () => {
        setMessage('')
        openNow.current = false
    }

    // ----- MEMO

    useEffect(() => {
        /*
        const ws = subscribe('gpsiam_chat_' + user.id, (d: any) => {
            console.log(d)
            if (d.action === 'chat' && d.room == user.id) {
                if (openNow.current) {
                    const data = {
                        ...d.msg,
                        time: d.time,
                        timestamp: new Date(d.time).getTime(),
                        sender: { from: d.from, id: user.id, name: user.name }
                    }
                    setMessages((prev: any) => [data, ...prev])
                }
                else {
                    if (d.from === 'hq')
                        props.onUpdate(true)
                }
            }
        })

        return () => {
            //onOutChat()
            ws.close()
        }
        */
    }, [])

    const msgItems = useMemo(() => {
        if (!!messages) {
            const ms = messages.filter((d: any) => (!isEmpty(d.text) || !isEmpty(d.sticker) || !isEmpty(d.files)))
            if (ms.length > 0) {
                const x = ms.findIndex((m: any) => m.sender?.from !== 'gpsiam')
                //if (x >= 0 && ms[x].me && todate(ms[x].time) < todate(room.messageReaded)) ms[x].readed = true
                return ms.reverse()
                    .reduce((s: any[], o: any) => {
                        if (s.length === 0 || (s.length > 0 && s[s.length - 1].time && !sameDate(s[s.length - 1].time, o.time, 'd')))
                            s.push({ type: 'spliter', text: date(o.time, 'S') })
                        if (o.sender?.from === 'gpsiam' && o.sender && o.sender.id === user.id) o.me = true
                        s.push(o)
                        return s
                    }, []).reverse()
            }
        }
        return []
    }, [messages])

    // ----- RENDER

    return <Modal icon={'comments-alt'} title={'Chat'} sm open={props.open} draggable noBackdrop startPosition={{ right: 20, top: 55 }}
                  className={'chat-popup'} onOpenEnd={() => {
        props.onUpdate(false)
        openNow.current = true
        loadChat()
    }} onClose={props.onClose} onCloseEnd={onClose}>
        <PageScroll className="chat-messages">
            {msgItems && msgItems.map((d: any) => <Message key={'chat_' + d._id} data={d}/>)}
        </PageScroll>
        <div className="chat-form">
            <Input m0 multiline value={message} onChange={setMessage} onPressEnter={onEnter}/>
            <Dropdown show={popEmoji} onToggle={() => setPopEmoji(prev => !prev)} className="chat-message-snippet -emoji" up right
                      button={<Icon button name={'smile-wink'} className={'un-icon-dropdown'}/>}>
                {CHAT_EMOJIs.map((s: any, x: number) => <div key={'s_' + x} className="_item" onClick={() => setMessage(p => p.concat(' ' + s + ' '))}>{s}</div>)}
            </Dropdown>
        </div>
    </Modal>
}

export function Message (props: any) {
    const { t } = useTranslation()
    const d = props.data
    if (!d) return null

    if (d.type && d.type === 'spliter') return <div key={'spt_' + d.text} className={'chat-message-spliter'}>{d.text}</div>

    return <div key={'room_' + d._id} id={d._id} className={clsNames('chat-message-item', d.me && '-me', d.mode && '-' + d.mode)}>
        <div className={'_on'}>
            <div className={'_date'}>{tdate(t, d.time, 'S')}</div>
            <div className={'_time'}>{date(d.time, 't')}</div>
            {!!d.sender && !!d.sender.name && <div className={'_user'}>{d.sender.name}</div>}
        </div>
        <div className="_content">
            {!!d.text && <Markdown>{d.text}</Markdown>}
            {d.files?.length > 0 && <div className="_files">{d.files.map((file: any, x: number) => <MessageFile key={'file_' + x} file={file}/>)}</div>}
        </div>
    </div>
}

export function MessageFile (props: any) {
    const { file } = props
    if (file.type === 'image')
        return <a href={file.url} target="_blank" className={file.type === 'image' ? '_image' : '_file'}
                  style={file.type === 'image' ? { backgroundImage: 'url("' + file.url + '")' } : {}}/>
    if (file.type === 'video')
        return <a href={file.url} target="_blank" className={'_file'}><Icon name={'video red'}/> Clip Video</a>
    return <a href={file.url} target="_blank" className={'_file'}><Icon name={'file-alt blue'}/> File</a>
}

function Sticker (props: { data: any }) {
    const { data } = props
    return <div className={'_sticker'}><img alt={'sticker'} src={'https://stickershop.line-scdn.net/stickershop/v1/sticker/' + data.sticker + '/android/sticker.png'}/></div>
}