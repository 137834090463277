import Icon from 'unno-comutils/Icon'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { role, roleDev } from '../utils'
import { useTranslation } from 'react-i18next'
import { getSideExtension } from '../menu'
import { useUser } from 'unno-comutils'

export default function Smenu (props: any) {
    const [sideMenuMini, setSideMenuMini] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const user = useUser()

    const { t } = useTranslation()

    const cPath = location.pathname.replace('/next', '') + '/'

    const itemUrl = (m: any) => m.url

    const item_onClick = (m: any, e: any) => {
        if (!e.ctrlKey) {
            e.preventDefault()
            history.push(m.url)
            if (props.onChangeUrl) props.onChangeUrl()
        }
    }

    if (props.menus && props.menus.length > 0) {
        return <div className={'layout-menu-side' + (sideMenuMini ? ' -mini' : '')}>
            {props.menus
                .filter((m: any) => role(m.role, user) && roleDev(m, user))
                .map((g: any) => {
                    const menus = g.items.filter((m: any) => role(m.role, user) && roleDev(m, user))
                    if (menus.length > 0) {
                        return <div key={`smenu-${g.name}`} className="_menu-side">{
                            menus.map((m: any) => {
                                const isUrl = m.url !== undefined
                                const avtive = m.urlMatch ? cPath.startsWith(m.urlMatch + '/') : cPath.startsWith(m.url + '/')

                                return <div key={`smenu-${m.name}`} className={'_item-container -single' + (avtive ? ' -active' : '')}>
                                    {isUrl
                                        ? <a href={itemUrl(m)} className="_item" onClick={e => item_onClick(m, e)}>
                                            <Icon name={m.icon} solid={avtive}/>
                                            <span className="_text">{t(m.text)}</span>
                                        </a>
                                        : <div className="_item"><Icon name={m.icon} solid={avtive}/><span className="_text">{t(m.text)}</span></div>}
                                </div>
                            })
                        }</div>
                    }
                    return null
                })}
            {getSideExtension(cPath)}
            <div className="btn-size mt-2 c">
                <Icon button name={sideMenuMini ? 'chevron-right' : 'chevron-left'} onClick={() => setSideMenuMini(!sideMenuMini)}/>
            </div>
        </div>
    }
    return null
}
