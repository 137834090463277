export const TIMEs = (() => {
    const outputs = []
    for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 6; m++) {
            const time = (h > 9 ? h : '0' + h) + ':' + m + '0'
            outputs.push(time)
        }
    }
    outputs.push('23:59')
    return outputs
})()

export const COLORs = [
    { name: 'red', code: '#f44336' },
    { name: 'pink', code: '#e91e63' },
    { name: 'purple', code: '#9c27b0' },
    { name: 'purple-deep', code: '#673ab7' },
    { name: 'indigo', code: '#3f51b5' },
    { name: 'blue', code: '#2196f3' },
    { name: 'blue-light', code: '#03a9f4' },
    { name: 'cyan', code: '#00bcd4' },
    { name: 'teal', code: '#009688' },
    { name: 'green', code: '#4caf50' },
    { name: 'green-light', code: '#8bc34a' },
    { name: 'lime', code: '#cddc39' },
    { name: 'yellow', code: '#ffeb3b' },
    { name: 'amber', code: '#ffc107' },
    { name: 'orange', code: '#ff9800' },
    { name: 'orange-deep', code: '#ff5722' },
    { name: 'brown', code: '#795548' },
    { name: 'grey', code: '#9e9e9e' },
    { name: 'grey-blue', code: '#607d8b' },
]

const now = new Date()
export const DEFAULT_DATE = () => [new Date(), new Date()]
export const DEFAULT_TIME = [new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0), new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0)]

export const REPORT_DATE_GROUPs = [{ id: 'd', name: 'ตามวัน' }, { id: 'w', name: 'ตามสัปดาห์' }, { id: 'm', name: 'ตามเดือน' }]
export const REPORT_ENGINE_OPTIONs = [{ id: 0, name: 'ทั้งหมด' }, { id: 1, name: 'ติดเครื่อง' }, { id: 2, name: 'ดับ' }]

export const LONGDO_TURNs = [
    { id: 0, text: 'เลี้ยวซ้ายสู่' },
    { id: 1, text: 'เลี้ยวขวาสู่' },
    { id: 2, text: 'เบี่ยงซ้ายสู่' },
    { id: 3, text: 'เบี่ยงขวาสู่' },
    { id: 4, text: 'ตรงไป' },
    { id: 5, text: 'ตรงไปตาม' },
    { id: 6, text: 'เข้าสู่' },
    { id: 7, text: '' },
    { id: 8, text: '' },
    { id: 9, text: 'ถึง' },
    { id: 10, text: '' }
]

export const MA_ITEM_NAMEs = [
    'เแบตเตอรี่',
    'น้ำมันเครื่อง',
    'เช็คระยะ',
    'ยางรถยนต์',
    'ระบบหล่อเย็น',
    'น้ำมันเบรก',
    'น้ำมันเกียร์',
    'ที่ปัดน้ำฝน',
    'ประกันรถยนต์',
    'พรบ',
    'ทะเบียนรถ',
    'ผ้าเบรค',
    'ไส้กรองน้ำมันเครื่อง',
    'ไส้กรองแอร์',
    'ไส้กรองอากาศ',
    'หัวเทียน',
    'น้ำหล่อเย็น',
    'กรองน้ำมันเครื่อง',
    'กรองน้ำมันเกียร์',
    'กรองน้ำมันเชื้อเพลิง',
]

export const TMS_DOC_YESNO = [{ id: 1, name: 'YES' }, { id: 0, name: 'NO' }]

export const LOCAL_SETTINGs = {
    THEME: 'APP_THEME'
}

