import React from 'react'
import { clsNames } from 'unno-comutils'
// @ts-ignore
import * as MdIt from 'markdown-it'
// @ts-ignore
import MdIt_dl from 'markdown-it-deflist'
import { Icon } from 'unno-comutils/ui'
import { useTranslation } from 'react-i18next'

export function AlertPing (props: { className?: string }) {
    return <div className={clsNames('un-alert-dot', props.className)}>
        <div className="_ping"/>
        <div className="_dot"/>
    </div>
}

export function Markdown (props: any) {
    const html = MdIt({ html: true, linkify: true }).use(MdIt_dl).render(props.children || '')
    return <div className={clsNames('text-content', props.className)} dangerouslySetInnerHTML={{ __html: html }}/>
}

export function IconNew (props: { index: number, ignore?: boolean, classWidth?: string, className?: string }) {
    return <div className={clsNames('c', props.classWidth || 'w-rn', props.className)}>{props.ignore ? <Icon name={'star orange'} solid/> : props.index + 1}</div>
}

export function DetailItem (props: { label?: string, children?: any, className?: string, col?: number }) {
    return <div className={clsNames('detail-item', props.col && 'col w-' + props.col + '/12', props.className)}>
        <div className={'_label'}>{props.label}</div>
        <div className={'_value'}>{props.children || '-'}</div>
    </div>
}

export function Infobar (props: { children: string, sm?: boolean, className?: string }) {
    return <div className={clsNames('infobar-warning', props.sm && '-sm', props.className)}>
        <Icon name={'exclamation-circle'}/>
        <div className="_text">{props.children}</div>
    </div>
}

export function InfoActive (props: { className?: string }) {
    const { t } = useTranslation()
    return <div className={clsNames('info-active', props.className)}>
        <Icon name={'circle green'} solid/>
        <div className="_name">{t('TEXT_ENABLE')}</div>
        <Icon name={'circle grey'}/>
        <div className="_name">{t('TEXT_DISABLE')}</div>
    </div>
}

export function MockEmpty (props: { text?: string }) {
    const { t } = useTranslation()
    return <div className="mock -sm">{props.text || t('TEXT_NONE_YET')}</div>
}

export function PrintContainer (props: { children: any, className?: string }) {
    return <table className={clsNames('print-container', props.className)}>
        <thead>
        <tr>
            <td/>
        </tr>
        </thead>
        <tfoot>
        <tr>
            <td/>
        </tr>
        </tfoot>
        <tbody>
        <tr>
            <td children={props.children}/>
        </tr>
        </tbody>
    </table>
}