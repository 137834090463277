import { BehaviorSubject } from 'rxjs'

export const appEvent$ = new BehaviorSubject({ actions: [] as string[], data: null })

export function sendEvent$ (actions: string[] | string, data?: any) {
    console.log('sendEvent$')
    appEvent$.next({ actions: typeof actions === 'string' ? [actions] : actions, data })
}

export function handleEvent$ (action: string | string[], callback: (data: any, eventName: string[]) => void) {
    const checkActions = typeof action == 'string' ? [action] : action
    return appEvent$.subscribe((event) => {
        if (event.actions.some(e => checkActions.includes(e))) {
            callback(event.data, event.actions)
        }
    })
}