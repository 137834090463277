export const DAYWEEKs = [
    { id: 1, name: 'DATE_DAY_0', short: 'DATE_DAY_SHORT_0' }, 
    { id: 2, name: 'DATE_DAY_1', short: 'DATE_DAY_SHORT_1' }, 
    { id: 4, name: 'DATE_DAY_2', short: 'DATE_DAY_SHORT_2' }, 
    { id: 8, name: 'DATE_DAY_3', short: 'DATE_DAY_SHORT_3' }, 
    { id: 16, name: 'DATE_DAY_4', short: 'DATE_DAY_SHORT_4' }, 
    { id: 32, name: 'DATE_DAY_5', short: 'DATE_DAY_SHORT_5' }, 
    { id: 64, name: 'DATE_DAY_6', short: 'DATE_DAY_SHORT_6' }
]

export const GENDERs = [
    { id: 1, name: 'OPTION_MAN' }, 
    { id: 2, name: 'OPTION_WOMAN' }
]

export const DEVICE_ICONs = [
    { name: 'Car', icon: 'car' }, 
    { name: 'Pickup', icon: 'pickup' }, 
    { name: 'Truck hook', icon: 'truck-hook' }, 
    { name: 'unicycle', icon: 'unicycle' }, 
    { name: 'bicycle', icon: 'bicycle' }, 
    { name: 'Scooter', icon: 'scooter' }, 
    { name: 'Truck', icon: 'truck' }, 
    { name: 'Truck 2', icon: 'truck2' }, 
    { name: 'Truck 3', icon: 'truck3' }, 
    { name: 'Truck logistics', icon: 'truck-logistics' }, 
    { name: 'Truck trailer', icon: 'truck-trailer' }, 
    { name: 'Tanker', icon: 'tanker' }, 
    { name: 'Van', icon: 'van' }, 
    { name: 'Ambulance', icon: 'ambulance' }, 
    { name: 'Schoolbus', icon: 'schoolbus' }, 
    { name: 'Train wagon', icon: 'train-wagon' }, 
    { name: 'Bicycle front', icon: 'bicycle-front' }, 
    { name: 'Car front', icon: 'car-front' }, 
    { name: 'Truck front', icon: 'truck-front' }, 
    { name: 'Taxi front', icon: 'taxi-front' }, 
    { name: 'Bus front', icon: 'bus-front' }, 
    { name: 'Train front', icon: 'train-front' }, 
    { name: 'Cablecar', icon: 'cablecar' }, 
    { name: 'Helicopter', icon: 'helicopter' }, 
    { name: 'Balloon', icon: 'balloon' }, 
    { name: 'Tank', icon: 'tank' }, 
    { name: 'Tractor', icon: 'tractor' }, 
    { name: 'Tractor 2', icon: 'tractor2' }, 
    { name: 'Tractor 3', icon: 'tractor3' }, 
    { name: 'Excavator', icon: 'excavator' }, 
    { name: 'Excavator 2', icon: 'excavator2' }, 
    { name: 'Excavator 3', icon: 'excavator3' }, 
    { name: 'Excavator 4', icon: 'excavator4' }, 
    { name: 'Excavator arm', icon: 'excavator-arm' }, 
    { name: 'Forklift', icon: 'forklift' }, 
    { name: 'Roller', icon: 'roller' }, 
    { name: 'Truck Concrete', icon: 'truck-concrete' }, 
    { name: 'Crane', icon: 'crane' }, 
    { name: 'Crane 2', icon: 'crane2' }, 
    { name: 'Crane 3', icon: 'crane3' }, 
    { name: 'boxtrolley', icon: 'boxtrolley' }, 
    { name: 'sailboat', icon: 'sailboat' }, 
    { name: 'Canoe', icon: 'canoe' }, 
    { name: 'Jetsky', icon: 'jetsky' }, 
    { name: 'Speedboat', icon: 'speedboat' }, 
    { name: 'Man', icon: 'man' }, 
    { name: 'Woman', icon: 'woman' }, 
    { name: 'Baby car', icon: 'baby' }, 
    { name: 'Plane', icon: 'plane' }, 
    { name: 'UFO', icon: 'ufo' }, 
    { name: '@', icon: 'email' }, 
    { name: 'Down', icon: 'arrow' }
]

export const DEVICE_NOTIFICATION_TYPEs = [
    { id: 'event', name: 'USER_NOTIFY_EVENT' }, 
    { id: 'park', name: 'USER_NOTIFY_PARK_LIMIT' }, 
    { id: 'speed_limit', name: 'USER_NOTIFY_SPEED_LIMIT' }, 
    { id: 'poi', name: 'USER_NOTIFY_POI' }, 
    { id: 'area', name: 'USER_NOTIFY_AREA' }, 
    { id: 'ad', name: 'USER_NOTIFY_AD' }, 
    { id: 'state', name: 'USER_NOTIFY_STATE' }, 
    { id: 'rs232_no', name: 'USER_NOTIFY_DRIVER_CARD' }
]

export const MARKER_COLORs = [
    { name: 'red', code: '#f44336' }, 
    { name: 'pink', code: '#e91e63' }, 
    { name: 'purple', code: '#9c27b0' }, 
    { name: 'purple-deep', code: '#673ab7' }, 
    { name: 'indigo', code: '#3f51b5' }, 
    { name: 'blue', code: '#2196f3' }, 
    { name: 'blue-light', code: '#03a9f4' }, 
    { name: 'cyan', code: '#00bcd4' }, 
    { name: 'teal', code: '#009688' }, 
    { name: 'green', code: '#4caf50' }, 
    { name: 'green-light', code: '#8bc34a' }, 
    { name: 'lime', code: '#cddc39' }, 
    { name: 'yellow', code: '#ffeb3b' }, 
    { name: 'amber', code: '#ffc107' }, 
    { name: 'orange', code: '#ff9800' }, 
    { name: 'orange-deep', code: '#ff5722' }, 
    { name: 'brown', code: '#795548' }, 
    { name: 'grey', code: '#9e9e9e' }, 
    { name: 'grey-blue', code: '#607d8b' }
]

export const POI_ICONs = [
    { name: 'Star', icon: 'star' }, 
    { name: 'Home', icon: 'home' }, 
    { name: 'Home 2', icon: 'home2' }, 
    { name: 'Shop', icon: 'shop' }, 
    { name: 'Factory 1', icon: 'factory' }, 
    { name: 'Factory 2', icon: 'factory2' }, 
    { name: 'Condo 1', icon: 'condo' }, 
    { name: 'Condo 2', icon: 'condo2' }, 
    { name: 'Fuel', icon: 'fuel' }, 
    { name: 'No entry', icon: 'noentry' }, 
    { name: 'Flag', icon: 'flag' }, 
    { name: 'Hospital', icon: 'hospital' }, 
    { name: 'Police', icon: 'police' }, 
    { name: 'Tower', icon: 'tower' }, 
    { name: 'Down', icon: 'arrow' }, 
    { name: 'Circle', icon: 'circle' }, 
    { name: 'Triangle', icon: 'triangle' }, 
    { name: 'Cross', icon: 'cross' }
]

export const APPROVE_STATUSs = [
    { id: 0, name: 'OPTION_WAIT' }, 
    { id: 1, name: 'OPTION_APPROVE' }, 
    { id: 2, name: 'OPTION_DENY' }
]

export const DLT_REPORT_STOPs = [
    { id: 0, name: '-' }, 
    { id: 1, name: 'รถจอดซ่อม' }, 
    { id: 2, name: 'รถจอดไม่ได้ใช้งาน' }, 
    { id: 3, name: 'ติดต่อผู้ประกอบการไม่ได้' }, 
    { id: 4, name: 'ผู้ประกอบการแจ้งมีการขายรถแล้ว' }, 
    { id: 5, name: 'อุปกรณ์ GPS เชื่อมต่อข้อมูลกรมขนส่งแล้ว' }, 
    { id: 6, name: 'รถใช้งานปกติ แต่กล่อง GPS หยุดการทำงาน จึงนัดเข้าทำการตรวจสอบ' }
]

export const EMPLOYEE_ROLEs = [
    { id: 'logistic_delivery', name: 'ใบปิดบรรทุก', group: 'tms', need: 'tms_premium' }, 
    { id: 'logistic_invoice', name: 'รายการใบนำส่ง', group: 'tms', need: 'tms_premium' }, 
    { id: 'logistic_poi', name: 'POI+เส้นทาง', group: 'tms', need: 'tms_premium' }, 
    { id: 'device', name: 'รายการรถ', group: 'tms', need: 'tms_premium' }, 
    { id: 'employee', name: 'พนักงานขับรถ', group: 'tms', need: 'tms_premium' }, 
    { id: 'monitor_tracking', name: 'ติดตามรถ', group: 'tms', need: 'tms_premium' }, 
    { id: 'report', name: 'รายงาน', group: 'tms', need: 'tms_premium' }, 
    { id: 'tms_work', name: 'ตารางงาน', group: 'tms_free' }, 
    { id: 'employee_day', name: 'วันลาพนักงาน', group: 'tms_free' }
]

export const MA_MODEs = [
    { id: 10, name: 'MA_MODE_DURATION', unit: 'UNIT_DAY', field: '' }, 
    { id: 20, name: 'MA_MODE_LENGTH', unit: 'UNIT_KM', field: 'length' }, 
    { id: 30, name: 'MA_MODE_WORKTIME', unit: 'UNIT_HOUR', field: 'length' }
]

export const USER_FLASHVIEW_FLAGs = [
    { id: 1, key: 'fullscreen', name: 'FullScreen', icon: 'expand-alt blue' }, 
    { id: 2, key: 'qrcode', name: 'QR Code', icon: 'qrcode green' }, 
    { id: 4, key: 'no_history', name: 'No History', icon: 'history red' }, 
    { id: 8, key: 'dvr', name: 'DVR', icon: 'camcorder grey-blue' }
]

export const HIK_EVENTs = [
    { id: 644, name: 'EVENT_HIK_644' }, 
    { id: 643, name: 'EVENT_HIK_643' }, 
    { id: 645, name: 'EVENT_HIK_645' }, 
    { id: 646, name: 'EVENT_HIK_646' }, 
    { id: 647, name: 'EVENT_HIK_647' }, 
    { id: 648, name: 'EVENT_HIK_648' }, 
    { id: 649, name: 'EVENT_HIK_649' }, 
    { id: 650, name: 'EVENT_HIK_650' }, 
    { id: 651, name: 'EVENT_HIK_651' }, 
    { id: 652, name: 'EVENT_HIK_652' }, 
    { id: 653, name: 'EVENT_HIK_653' }, 
    { id: 654, name: 'EVENT_HIK_654' }, 
    { id: 655, name: 'EVENT_HIK_655' }, 
    { id: 656, name: 'EVENT_HIK_656' }, 
    { id: 657, name: 'EVENT_HIK_657' }, 
    { id: 658, name: 'EVENT_HIK_658' }, 
    { id: 659, name: 'EVENT_HIK_659' }, 
    { id: 660, name: 'EVENT_HIK_660' }, 
    { id: 661, name: 'EVENT_HIK_661' }, 
    { id: 662, name: 'EVENT_HIK_662' }, 
    { id: 663, name: 'EVENT_HIK_663' }, 
    { id: 664, name: 'EVENT_HIK_664' }, 
    { id: 665, name: 'EVENT_HIK_665' }
]

export const REPORT_DRIVER_SCOREs = [
    { id: 90, name: 'หักเลี้ยวกระทันหัน', e: [90, 91] }, 
    { id: 129, name: 'เบรคกระทันหัน', e: [129] }, 
    { id: 130, name: 'ออกตัวกระชาก', e: [130] }, 
    { id: 19, name: 'เกินความเร็ว' }
]